import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    packages: Yup.array().of(
      Yup.object().shape({
        weight: Yup.string().required('Weight is required'),
        width: Yup.string().required('Width is required'),
        length: Yup.string().required('Length is required'),
        height: Yup.string().required('Height is required'),
        shiping_company_id: Yup.number().required('Shipping company is required').test('non-zero-shiping-company-id', 'Shipping company is required', (value) => value !== 0),
      })
    ),
  });