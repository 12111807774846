import {useContext, useState} from 'react'; 
import CommonContext from '../../context/CommonContext';
import { addProcessingLoader, removeProcessingLoader } from '../../helpers/commonHelpers';
export const useOrderSearch = () => {
    const {orderSearch,orderSearchData,orderSearchArray,merchants,getSeller,isLoading,updateLocation,updateOrderProductLocation, constants, preAndBackOrderSearch} = useContext(CommonContext);
    
    const initialFilters = {
        page: 1,
        keyword: '',
        order_is_paid: '',
        date_from: '',
        date_to: '',
        price_from: '',
        price_to: '',
        status: [],
        channels:[],
        locations:[],
        sellers:[],
        international_order:[],
        sortOrder:[],
        cancel_shipment:'',
        received_via:''
    };

    const [filters, setFilters] = useState(initialFilters);


    const goToSearchPage = async (type='page',value = 1,resetPage = false,getSellerReq = false, resetByChannel = false) => {
        
        let updatedFilters = {
            ...filters,
            [type]: value,
            page: resetPage ? 1 : value
        };
        if(type === 'status'){
            updatedFilters = {
                ...updatedFilters,
                'cancel_shipment': '',
            };
        }
        if(type === 'cancel_shipment'){
            updatedFilters = {
                ...updatedFilters,
                'status': [],
            };
        }
        if(type === 'channels' && resetByChannel === true){
            updatedFilters = {
                ...updatedFilters,
                international_order:[],
                received_via:[],
            };
        }

        if(getSellerReq === true){
            getSeller(updatedFilters);
        }
        setFilters(updatedFilters)
        loadData(updatedFilters)
    }

    const goToSearchPageByDate = async (start,end) => {
        const updatedFilter = {
            ...filters,
            'date_from': start,
            'date_to':  end
        };

        setFilters(updatedFilter)
        loadData(updatedFilter)
    }

    const loadData = async (initialValues = []) => {
        if(initialValues.status.length > 0 && initialValues.status.some(value => [constants.ORDER_BACK_ORDER, constants.ORDER_PRE_ORDER].includes(value))){
            preAndBackOrderSearch(initialValues);
            return;
        }
        orderSearch(initialValues);
    } 

    const updateOrderLocation = async(locationId,opIds=[],orderId) => {
        const data = {
            "order_id":orderId,
            "order_op_ids":opIds,
            "order_location":locationId
        }
        addProcessingLoader();
        try {
            const res = await updateLocation(data);
            if(res === true){
                loadData(filters)
            }
        } catch (error) {
            console.log(error)
        }
        removeProcessingLoader();
    } 
    const updateProductLocation = async(data) => {
        addProcessingLoader();
        try {
            const res = await updateOrderProductLocation(data);
            if(res === true){
                loadData(filters)
            }
        } catch (error) {
           console.log(error)
        }
        removeProcessingLoader();
    } 

  return {orderSearchData,orderSearchArray,loadData,goToSearchPage,merchants,goToSearchPageByDate,getSeller,isLoading,updateOrderLocation,filters,updateProductLocation,setFilters}
};


