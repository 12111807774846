import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../../context/CommonContext'
export default function Confirmation(props) {
    const { show, setShow, handleShipPackage, printShipLabel } = props
    const { getLabel } = useContext(CommonContext);
    const handleClose = () => {
        setShow(false)
    };
    const btnTitle = (printShipLabel === true ? getLabel('LBL_Create_+_Print_label') : getLabel('LBL_Create_shipping_label'));
    return (
        <>
            <Modal show={show} onHide={handleClose} centered className={'location custom-modal confirmation-modal'}>
                <Modal.Header >
                    <Modal.Title className='top-title'>{getLabel('LBL_Ship_packages')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>{getLabel('LBL_There_are_unselected_packages_in_your_shipment._Would_you_like_to_proceed_with_shipping_the_selected_packages?')}</div>
                    <div className='row mt-5'>
                        <div className="col-lg-6 col-sm-6">
                            <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>{getLabel('LBL_Reject')}</button>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <button type="button" className="btn btn-primary ship" onClick={() => handleShipPackage(printShipLabel)}>
                                {btnTitle}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
