import React, { useContext, useState } from 'react';
import LabelLoader from '../../Skeleton/Loader';
import Pagination from '../../Common/views/Pagination';
import CommonContext from '../../../context/CommonContext';
import NoRecordsFound from '../../Common/views/NoRecord';
import { dateConverter } from '../../../helpers/commonHelpers';
import SortIcon from '../../../assets/images/sort-icon.svg';
import SortAscIcon from '../../../assets/images/sort-asc.svg';
import SortDescIcon from '../../../assets/images/sort-desc.svg';
export default function Listing(props) {
  const { goToSearchPage, downloadPdf } = props
  const { importedLabelData, isLoading, getLabel } = useContext(CommonContext);
  const condition = importedLabelData.data && importedLabelData.data.rows && importedLabelData.data.rows.length > 0;
  const [sortCriteria, setSortCriteria] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const sortItems = (criteria) => {
    const newSortDirection = sortCriteria === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortCriteria(criteria);
    setSortDirection(newSortDirection);
    const sortOrder = [criteria, newSortDirection]
    goToSearchPage('sortOrder', sortOrder, true)
  };
  return (
    <>
      <form id="shopListing_form">

        <table width="100%" className="table table-responsive table--hovered labelListingTbl">
          <thead>
            <tr>
              <th width="1%"><input type="checkbox" className='selectAllLabel-js' id="selectAllLabel-js"/><label htmlFor="selectAllLabel-js"></label></th>
              <th width="20%" onClick={() => sortItems('ilabel_added_on')}>{getLabel('LBL_Import_date')} {sortCriteria === 'ilabel_added_on' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
              <th width="25%" onClick={() => sortItems('ilabel_file_name')}>{getLabel('LBL_File')} {sortCriteria === 'ilabel_file_name' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
              <th width="20%">{getLabel('LBL_Merchant')}</th>
              <th width="34%">{getLabel('LBL_Sent_to')}</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ?
              <LabelLoader columns={5} />
              :
              condition ? importedLabelData.data.rows.map((data, index) => (
                <tr key={index}>
                  <td><input type="checkbox" name="ilabel_id[]" className='selectLabelItem--js' id={"ilabel_"+index} value={data.ilabel_id} /><label htmlFor={"ilabel_"+index}></label></td>
                  <td>{dateConverter(data.ilabel_added_on,'dateYMD')}<br /><span className='time'>{dateConverter(data.ilabel_added_on,'time')}</span></td>
                  <td className={`${data.ilabel_is_printed === 1 ? 'link-color' : ''} downloadBtnCss` } onClick={() => downloadPdf(data.ilabel_id)}>{data.ilabel_file_name}</td>
                  <td>{data.shop_name && data.shop_name}</td>
                  <td>{data.location_name && data.location_name}</td>
                </tr>
              )) : <NoRecordsFound column={5} />
            }
          </tbody>
        </table>

      </form>
      {condition ? <Pagination goToSearchPage={goToSearchPage} orderSearchData={importedLabelData} /> : null}
    </>
  )
}
