import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery'
import filterImg from '../../../assets/images/filter.svg'
import crossIcon from '../../../assets/images/crossIcon.svg'
import downArrow from '../../../assets/images/downArrow.svg'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import { dateConverter } from '../../../helpers/commonHelpers';
export default function SearchForm(props) {
    const {goToSearchPage,merchants,Style,goToSearchPageByDate,locations,getLabel,constants,filters} = props
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState([]);  
    const [isOpen, setIsOpen] = useState(false);
    const [startDate,setstartDate] = useState(new Date());
    const [endDate,setendDate] = useState(new Date());
    const [startNewDate,setStartNewDate] = useState(new Date());
    const [endNewDate,setEndNewDate] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [validInput, setValidInput] = useState(false);
    const isInitialRender = useRef(true);
    useEffect(()=>{
        $(document).on('click',function(e){
            if ($(e.target).closest(".datepickert").length === 0) {
                setIsOpen(false);
            }
        })
    },[])

    useEffect(()=>{
        if(filters.keyword){
            setSearchTerm(filters.keyword)
        }
    },[filters])
    
    const handleLocationCheckboxChange = (option) => {
        const updatedCheckbox = selectedLocation.includes(option)
            ? selectedLocation.filter(item => item !== option)
            : [...selectedLocation, option];
        setSelectedLocation(updatedCheckbox);
        handleGoToSearchPage('locations',updatedCheckbox)
    };

    const handleMerchantCheckboxChange = (option) => {
        const updatedCheckbox = selectedMerchant.includes(option)
            ? selectedMerchant.filter(item => item !== option)
            : [...selectedMerchant, option];
        setSelectedMerchant(updatedCheckbox);
        handleGoToSearchPage('sellers',updatedCheckbox)
    };

     

    const handleClear = (state,type,getSeller = false) => {
        handleGoToSearchPage(type,[],getSeller)
    }

    const handleGoToSearchPage = (type,value,getSeller = false) => {
        goToSearchPage(type,value,true,getSeller)
    }

    const handleOpenPicker = () => {setIsOpen(true);};
    
    const handleClosePicker = () => {setIsOpen(false);};

    const handleSelect = (date) => {
        setstartDate(date.selection.startDate)
        setendDate(date.selection.endDate)
       const start = (date.selection.startDate.toLocaleDateString('en-US', {year: 'numeric',month: '2-digit',day: '2-digit'}))
       const end = (date.selection.endDate.toLocaleDateString('en-US', {year: 'numeric',month: '2-digit',day: '2-digit'}))
       setStartNewDate(start)
       setEndNewDate(end)
    };

    const handleApplyDatePicker = () => {
        goToSearchPageByDate(startNewDate,endNewDate)
        setIsOpen(false)
    }

    const handleClearDate = () => {
        goToSearchPageByDate('','')
    }


    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const selectionRange = {startDate:  startDate,endDate:  endDate,key: 'selection'}

    const handleInputChange = (event) => {
        setValidInput(true)
        setSearchTerm(event.target.value.trimStart());
    };

    useEffect(() => {
    if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
    }

    const delaySearch = setTimeout(() => {
        if(validInput === true){
            handleGoToSearchPage('keyword', searchTerm);
        }
    }, 500); 

    return () => clearTimeout(delaySearch);
    // eslint-disable-next-line
    }, [searchTerm]);
  return (
        <>
            <section className="section searchform_filter">
                <div className="sectionhead">
                    <div className="tabs">
                        <ul className="arrowTabs">
                            <li className={`tabs_handle ${(filters.status).length === 0 ? 'is-active' : ''}`}>
                                <Link  data-type="All" onClick={() => handleGoToSearchPage('status', [])}>
                                  {getLabel('LBL_All')}
                                </Link>
                            </li>
                            <li className={`tabs_handle ${filters.status.includes(constants.ORDER_PICKUP_PENDING) && filters.status.includes(constants.ORDER_FLAGGED) ? 'is-active' : ''}`}>
                                <Link  data-type="Unshipped" onClick={() => handleGoToSearchPage('status', [constants.ORDER_PICKUP_PENDING,constants.ORDER_FLAGGED])} className='d-flex'>
                                  {getLabel('LBL_PENDING')}
                                </Link>
                            </li>
                            <li className={`tabs_handle ${filters.status.includes(constants.ORDER_PICKUP_READY) ? 'is-active' : ''}`}>
                                <Link  data-type="Flagged" onClick={() => handleGoToSearchPage('status', [constants.ORDER_PICKUP_READY])} className='d-flex'>
                                    {getLabel('LBL_Ready')}
                                </Link>
                            </li>
                            <li className={`tabs_handle ${filters.status.includes(constants.ORDER_PICKED_UP) ? 'is-active' : ''}`}>
                                <Link  data-type="Shipped" onClick={() => handleGoToSearchPage('status', [constants.ORDER_PICKED_UP])}>
                                {getLabel('LBL_PICKED_UP')}
                                </Link>
                            </li> 
                        </ul>
                    </div>
                </div>
                <div className="sectionbody space">
                    <form name="frmOrderSearch" id="frmOrderSearch" className="web_form" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label" />
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <input className={Style.search_btn} type="submit" name="btn_submit" value="Search" />
                                            <input id="keyword" 
                                                className={Style.KeywordInputCss} 
                                                autoComplete="off" 
                                                type="text" 
                                                name="keyword" 
                                                onChange={handleInputChange}
                                                value={searchTerm}
                                                placeholder={getLabel('LBL_Search_by_order_ID,_customer_name,_SKU')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label" />
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <Link className={"btn filterBtnJs "+Style.filterBtnCss}><img className={Style.filter_img_css} src={filterImg} alt='filter'/> {getLabel('LBL_Filter')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter_dropdown">
                            <ul className={Style.filterTab}>
                                <li className={"datepickert "+Style.filterTab_handle}>
                                    <Link className={`btn ${Style.filterTab_css} ${filters.date_from && filters.date_to ? 'active_filter':''}`}  onClick={isOpen ? handleClosePicker:handleOpenPicker}>
                                        {filters.date_from && filters.date_to ? `${dateConverter(filters.date_from,'dateYMDPicker')} to ${dateConverter(filters.date_to ,'dateYMDPicker')}` : getLabel('LBL_Date')} 
                                        <span className={Style.ClearBtn} onClick={filters.date_from && filters.date_to ? handleClearDate : null}>
                                            <img src={filters.date_from && filters.date_to ? crossIcon:downArrow} alt='arrow'/>
                                        </span>
                                    </Link>
                                    {
                                        isOpen 
                                        && 
                                        <>
                                            <DateRangePicker maxDate={new Date()} preventSnapRefocus={true}  calendarFocus="backwards" ranges={[selectionRange]}  months={2} direction="horizontal" onChange={handleSelect} showDateDisplay={false} inputRanges={[]} rangeColors={['#D31334']} weekdayDisplayFormat={'EEEEE'} />
                                            <div className='cancelApplyButton'>
                                                <span >{`${dateConverter(startNewDate,'datePicker')} - ${dateConverter(endNewDate,'datePicker')}`}</span>
                                                <button className='btn btn-cancel border-0' onClick={handleClosePicker}>{getLabel('LBL_Cancel')}</button>
                                                <button className='btn btn-save' onClick={handleApplyDatePicker}>{getLabel('LBL_Apply')}</button>
                                            </div>
                                        </>
                                    }
                                </li>
 
                                {merchants.length > 0 ?
                                <li className={Style.filterTab_handle}>

                                    <Link className={`btn filterTab_handle_js ${Style.filterTab_css} ${selectedMerchant.length > 0 ? 'active_filter':''}`}>
                                        {getLabel('LBL_Merchant')} 
                                        <span className={Style.ClearBtn} onClick={selectedMerchant.length > 0?(()=>handleClear(setSelectedMerchant([]),'sellers',false)):null}>
                                            <img src={selectedMerchant.length <= 0 ? downArrow:crossIcon} alt='arrow'/>
                                        </span>
                                    </Link>

                                    <ul className={"dropdownWrapper_js "+Style.dropdownWrapper}>
                                        {merchants && merchants.map((data)=>{
                                            return <li key={data.user_id}>
                                                <input type="checkbox" 
                                                    id={"merchant_"+data.user_id} 
                                                    onChange={() => handleMerchantCheckboxChange(data.user_id)}
                                                    checked={selectedMerchant.includes(data.user_id)}/>
                                                <label className={Style.filter_name} htmlFor={"merchant_"+data.user_id}>{data.user_name}</label>
                                            </li>
                                        })}
                                         <li><Link onClick={()=>handleClear(setSelectedMerchant([]),'sellers',false)} className={`clear_css ${selectedMerchant.length > 0 ? 'active_clear':'disabled'}`}>Clear</Link></li>
                                    </ul>
                                </li>
                                 :null
                                }

                                <li className={Style.filterTab_handle}>
                                    <Link className={`btn filterTab_handle_js ${Style.filterTab_css} ${selectedLocation.length > 0 ? 'active_filter':''}`}>
                                       {getLabel('LBL_Fulfillment_location')} 
                                        <span className={Style.ClearBtn} onClick={selectedLocation.length > 0?(()=>handleClear(setSelectedLocation([]),'locations',false)):null}>
                                            <img src={selectedLocation.length <= 0 ? downArrow:crossIcon} alt='arrow'/>
                                        </span>
                                    </Link>
                                    {locations.length > 0 ? 
                                        <ul className={"dropdownWrapper_js "+Style.dropdownWrapper}>
                                            {locations && locations.map((data)=>{
                                                return <li key={data.loc_id}>
                                                    <input type="checkbox" 
                                                        id={"location_"+data.loc_id} 
                                                        onChange={() => handleLocationCheckboxChange(data.loc_id)} 
                                                        checked={selectedLocation.includes(data.loc_id)}/>
                                                    <label className={Style.filter_name} htmlFor={"location_"+data.loc_id} >{data.loc_name}</label>
                                                </li>
                                            })}
                                            <li><Link onClick={()=>handleClear(setSelectedLocation([]),'locations',false)} className={`clear_css ${selectedLocation.length > 0 ? 'active_clear':'disabled'}`}>Clear</Link></li>
                                        </ul>
                                    :null}
                                </li>

                            </ul>
                        </div>
                    </form>
                </div>
            </section>
        </>
  )
}
