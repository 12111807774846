import './App.css';
import './custom.js';
import { Oval } from  'react-loader-spinner'
import Login from './components/Auth/view/Login';
import Protected from './components/Protected';
import Header from './components/Common/views/Header';
import Orders from './components/Orders/views/Index'
import PickupOrders from './components/PickupOrders/views/Index'
import Logout from './components/Auth/view/Logout';
import OrderViewPage from './components/Orders/views/View';
import PreOrderViewPage from './components/Orders/views/PreOrderView.jsx';
import PickupOrderViewPage from './components/PickupOrders/views/View';
import Shops from './components/Shops/views/Index'
import ThirdParty from './components/Shops/views/ThirdParty/Index'
import ImportedLabels from './components/ImportedLabels/views/Index'
import { BrowserRouter,Route,Routes,Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isLoggedIn  } from "./helpers/commonHelpers";
import Sidebar from './components/Common/views/Sidebar'; 
import AccountSettings from './components/AccountSettings/views/Index'
import { useContext, useEffect } from 'react';
import CommonContext from './context/CommonContext'
import Error from './components/Common/views/Error';
function App() {
  const {fetchLabels} = useContext(CommonContext);
  useEffect(()=>{
    fetchLabels();
    // eslint-disable-next-line
  },[])
  return (
    <>
      
    <BrowserRouter>
        <Routes>
          <Route exact path='/login' element={<Login/>} /> 
          <Route path='*' element={<CatchAllRoute />} />
          <Route element={<Header/>} >
            <Route element={<Sidebar/>} >
              <Route exact path="/" element={<Navigate to={`${process.env.REACT_APP_BASE_URL}/orders`} />} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/orders`} element={<Protected  Component={Orders}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/pickup-orders`} element={<Protected  Component={PickupOrders}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/orders/woocommerce`} element={<Protected  Component={Orders} channelId={1}/>}  /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/orders/shopify`} element={<Protected  Component={Orders} channelId={2}/>}  /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/orders/amazon`} element={<Protected  Component={Orders} channelId={3}/>}  /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/orders/strictly-auto-parts`} element={<Protected  Component={Orders} channelId={0}/>}  /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/orders/view/:id/:channelId`} element={<Protected  Component={OrderViewPage}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/pre-orders/view/:id/:channelId`} element={<Protected  Component={PreOrderViewPage}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/pickup-orders/view/:id/:channelId`} element={<Protected  Component={PickupOrderViewPage}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/imported-labels`} element={<Protected  Component={ImportedLabels}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/shops`} element={<Protected  Component={Shops}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/shops/third-party/:name/:id`} element={<Protected  Component={ThirdParty}/>} /> 
              {/* <Route exact path={`${process.env.REACT_APP_BASE_URL}/settings`} element={<Protected  Component={Settings}/>} />  */}
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/settings`} element={<Protected  Component={AccountSettings}/>} /> 
              <Route exact path={`${process.env.REACT_APP_BASE_URL}/logout`} element={<Protected  Component={Logout}/>} /> 
            </Route>
          </Route>
        </Routes>
    </BrowserRouter>
    <div className='no-print'>
      <Oval height={80 }width={80} color="#D31334" wrapperStyle={{justifyContent: 'center',alignItems: 'center',display:'none',overflow: 'hidden',position: 'fixed',top: '0',left: '0',width: '100%',height: '100%',zIndex: '9999'}} wrapperClass="circleLoader" visible={true} ariaLabel='oval-loading' secondaryColor="#f5b4bf" strokeWidth={2} strokeWidthSecondary={2}/>
      <ToastContainer position="bottom-center" theme="colored" pauseOnFocusLoss={false} autoClose={5000} pauseOnHover={false} limit={1}  hideProgressBar={true} />
    </div>
    </>
  );
}

function CatchAllRoute() {
  return isLoggedIn() ?  <Error /> : <Navigate to='/login' />;
}
export default App;
