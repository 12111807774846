import React, { useState,useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CommonContext from '../../../context/CommonContext';
import {dateConverter,addressFormatter,UniqueStatusesByOrderId,UniqueLocationsByOrderId,displayMoneyFormat, capitalizeFirstLetter} from '../../../helpers/commonHelpers'
import {markAsRead} from '../../../services/orders/index'
import NoRecordsFound from '../../Common/views/NoRecord'
import Pagination from '../../Common/views/Pagination'
import SortIcon from '../../../assets/images/sort-icon.svg'
import SortAscIcon from '../../../assets/images/sort-asc.svg'
import SortDescIcon from '../../../assets/images/sort-desc.svg'
import OrderListingLoader from '../../Skeleton/Loader'
import PackagesModal from '../../Modal/views/packages/Index'
import $ from 'jquery'
export default function Listing(props) {
    const {orderSearchArray,goToSearchPage,orderSearchData,isLoading,locations,updateOrderLocation,filters,updateProductLocation, channelId} = props
    const {allowToSearchPackageArray,shippedStatusArray,shippingMethodObject,shippingCompanyObject,constants,getLabel,unshippedStatusArray ,setFilterData, receivedObject} = useContext(CommonContext);
    const [sortCriteria, setSortCriteria] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [orderLocations, setOrderLocations] = useState([]);
    const [orderId, setOrderId] = useState('');
    const [locId, setLocId] = useState('');
    const [selectedOption ,setSelectedOption] = useState('')
    const [formData, setFormData] = useState([]);
    const navigate = useNavigate();

    const sortItems = (criteria) => {
        const newSortDirection = sortCriteria === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortCriteria(criteria);
        setSortDirection(newSortDirection);
        const sortOrder = [criteria,newSortDirection]
        goToSearchPage('sortOrder',sortOrder,true)
    };

    const handleRadioChange = (locationId) => {
        setSelectedOption(locationId)
        setLocId(locationId)
    }    

    const handleSubmit = (e,opId,orderId) =>{
        e.preventDefault();
        updateOrderLocation(locId,opId,[orderId])
        $('.popupwrap').removeClass('active');
        setLocId('')
        setSelectedOption('')
    }
    
    const handleCheckPackages = (order_location,orderId) => {
        setOrderLocations(order_location);
        setShowModal(true);
        setOrderId(orderId)
    }

    const handleMarkRead = async(orderId) => {
        const data = {
            "order_id":orderId 
        }
        try {
            const res = await markAsRead(data);
            if (res === true){
                navigate(`${process.env.REACT_APP_BASE_URL}/orders/view/${orderId}/${channelId}`)
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const handleSelectedOptionState = (data,orderId) => {
        setSelectedOption('')
        setFormData('');

        const initialForm = 
        {
            order_id: (orderId),
            products :[]
        }
        setFormData(initialForm);
    }

    useEffect(()=>{
        var limit = 10;
        if(orderSearchData.data && orderSearchData.data.limit){
            limit = orderSearchData.data.limit;
        }
        var rows = $("#orderListing_form tbody tr");
        rows.each(function (index) {
          if (index >= (limit - 3)) {
            $(this).addClass("last_tr");
          }
        });
    
        rows.each(function (index) {
          if (index < (limit - 3)) {
            $(this).removeClass("last_tr");
          }
        });
    },[isLoading, orderSearchData])

    const handleSelectChangeLocation = (event, index,op_id) => {
        const newValue = parseInt(event.target.value);

        const updatedItem = { ...formData.products[index], 'location_id': newValue,'op_id':op_id };
        const updatedLocation = [...formData.products];
        updatedLocation[index] = updatedItem;

        setFormData({
            ...formData,
            products: updatedLocation,
        });
    };
      
    const handleUpdateProductLocation = (e) => {
        e.preventDefault();
        const updatedData = { ...formData };
        updatedData.products = updatedData.products.filter(item => item !== null);

        updateProductLocation(updatedData)
        $('.popupwrap').removeClass('active');
        setFormData('');
    }

    const storeFilterData = () => {
        setFilterData(filters)
    }
    return (
        <>
            <form id="orderListing_form">
                <div className='table-responsive table-popup_css'>
                    <table width="100%" className="table table--hovered  order_listing_tbl">
                        <thead>
                            <tr>
                                <th><input type="checkbox" className='selectAll-js' id="selectAll-js"/><label htmlFor="selectAll-js"></label></th>
                                <th onClick={() => sortItems('order_date_added')} width="7%">{getLabel('LBL_Date')} {sortCriteria === 'order_date_added' ? (sortDirection === 'asc' ?<img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' /> }</th>
                                <th width="5%">{getLabel('LBL_Status')}</th>
                                <th onClick={() => sortItems('order_id')} width="12%">{getLabel('LBL_Order_ID')} {sortCriteria === 'order_id' ? (sortDirection === 'asc' ?<img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' /> }</th>
                                <th onClick={() => sortItems('oua_name')} width="10%">{getLabel('LBL_Customer')} {sortCriteria === 'oua_name' ? (sortDirection === 'asc' ?<img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' /> }</th>
                                <th width="11%">{getLabel('LBL_SKU')}</th>
                                <th width="10%">{getLabel('LBL_Merchant')}</th>
                                <th width="13%" className='hoveredThead'>{getLabel('LBL_Location')}</th>
                                <th width="12%" className="hoveredThead">{getLabel('LBL_Package')}</th>
                                <th width="16%" className="hoveredThead">{getLabel('LBL_Rates')}</th>
                            </tr>
                        </thead>
                        <tbody>

                        { isLoading ? <OrderListingLoader columns={10}/> :
                            orderSearchArray && orderSearchArray.length > 0 ? orderSearchArray.map((data,index) => { 
                                const uniqueStatusesArray = UniqueStatusesByOrderId(data);
                                const uniqueLocationsArray = UniqueLocationsByOrderId(data);
                                const allowToSearchPackage =  uniqueStatusesArray.some(item => allowToSearchPackageArray.includes(item.statusId))
                                const packageRates =  uniqueStatusesArray.some(item => shippedStatusArray.includes(item.statusId))
                                const cancelledShipmentArr = [constants.ORDER_CANCELLED];
                                const cancelledOrderStatus =  uniqueStatusesArray.every(item => cancelledShipmentArr.includes(item.statusId))
                                return  <tr key={index}>
                                            <td><input type="checkbox" name="order_id[]" className='selectItem--js' id={"order_"+index} value={data.order_id} /><label htmlFor={"order_"+index}></label></td>
                                            <td>{dateConverter(data.order_date_added,'dateYMD')}<br /><span className='time'>{dateConverter(data.order_date_added,'time')}</span></td>
                                            <td>
                                                <div className='list-status gap-2'>
                                                    <div>
                                                        {uniqueStatusesArray.map((statusItem, statusIndex) => {
                                                            return <div key={statusIndex} title={`SKUs ${statusItem.skus.join(', ')}`} className='vertical-space'>
                                                                <img src={`/images/status/${statusItem.statusId}.svg`} className='listing_img' alt={`${statusItem.statusId}-status`} />
                                                            </div>
                                                        })}
                                                    </div>
                                                    {data.unreadNotes&& data.unreadNotes.length && <div onClick={()=>handleMarkRead(data.order_id)}><img src={`/images/notes.svg`} className='listing_img' alt='unread-note' /></div>}
                                                </div>
                                            </td>
                                        
                                            <td className={data.order_unprinted === null ? 'link-color' : '' }>
                                                <Link to={`${process.env.REACT_APP_BASE_URL}/orders/view/${data.order_id}/${channelId}`} className='view_page_link' onClick={storeFilterData}></Link>
                                                <img src={`/images/channels/${data.order_channel}.svg`} className='channel_img' alt="channel" />
                                                {`${data.order_id} ${data.order_channel_oid ? '('+data.order_channel_oid+')' : ''}`}
                                                {data.order_created_by > 0 && <><br /><div className="order_received">{receivedObject[data.order_created_by]}</div></>}
                                                
                                            </td>

                                            <td>{data.shipDetails.oua_name ? (data.shipDetails.oua_name) : ''}</td>

                                            <td>
                                                {data.products.map((product,index) =>{
                                                        return <div key={index} className='vertical-space'>{`${(product.op_qty - product.op_refund_qty - product.totalCancelQty) } X ${product.op_selprod_sku}`}</div>
                                                    })
                                                }
                                            </td>

                                            <td>
                                                {data.seller.map((sellerData,index) =>{
                                                        return <div key={index} className='vertical-space'>{sellerData.shop_name ? sellerData.shop_name : sellerData.shop_identifier}</div>
                                                    })
                                                }
                                            </td>
                                            <td>
                                                {uniqueLocationsArray.map((locationData, index) => {
                                                    const hasUnshippedStatus = locationData.statusIds.some((statusId) =>
                                                        unshippedStatusArray.includes(statusId)
                                                    );
                                                return (
                                                    <div key={index} className='hoveredColumn'>
                                                        <div className={ hasUnshippedStatus === true ? 'locationDropdown clickable-div' : 'shipped_pack'} onClick={()=>handleSelectedOptionState(locationData,data.order_id)}>
                                                            {locations.map((location)=>(location.loc_id === locationData.locationId ? location.loc_name : null  ))}
                                                        </div>
                                                        <div className='popupwrap location'>
                                                            <div className='popup_head mb-2'>
                                                                {getLabel('LBL_Location')}
                                                            </div>
                                                            {locationData.opIds.length < 2 ? 
                                                                (<div className='popup_body'>
                                                                    {locations.map((location,loc_index)=>{
                                                                        return <div key={location.loc_id}>
                                                                            <input type="radio" value={location.loc_id} name={`location_id${data.order_id}${index}${loc_index}`}
                                                                                checked={((!selectedOption && location.loc_id === locationData.locationId) || (selectedOption && parseInt(selectedOption) === location.loc_id) ?true:false)}
                                                                                onChange={(e)=>handleRadioChange(e.target.value)}/>
                                                                            <span>{location.loc_name}</span>
                                                                            <p className='loc_address'>{addressFormatter(location)} </p>
                                                                        </div>
                                                                    })} 
                                                                    <div className="popup_foot">
                                                                        <button className='btn btn-save' onClick={(e)=>handleSubmit(e,locationData.opIds,data.order_id)}>Save</button>
                                                                    </div> 
                                                                </div>)
                                                                :
                                                                (
                                                                    <div className='popup_body'>
                                                                        {locationData.skus.map((sku,index)=>{
                                                                            return  <div key={index}>
                                                                                        <div className='mb-3'>
                                                                                            <div  className='mb-1'>{sku}</div>
                                                                                            <div className='input-select-group'>
                                                                                                <span className="input-group-addon">
                                                                                                    <img src={`/images/shipment-icons/location.svg`} alt="location" />
                                                                                                </span>
                                                                                                <select className='location-select-box' value={formData.products && formData.products[index] ? formData.products[index].location_id : locationData.locationId } name='updated_loc_id' onChange={(event) => handleSelectChangeLocation(event, index,locationData.opIds[index])}>
                                                                                                {locations.map((location,loc_index)=>(
                                                                                                    <option key={loc_index} value={location.loc_id}>{location.loc_name}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            })}
                                                                        <div className="popup_foot">
                                                                            <button className='btn btn-save' onClick={(e)=>handleUpdateProductLocation(e)}>Save</button>
                                                                        </div> 
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        
                                                    </div>
                                                )
                                                })}
                                            </td>
                                            <td>
                                                <div className="hoveredColumn">
                                                    {allowToSearchPackage === true ?
                                                        <div className='clickable-div' onClick={()=>{handleCheckPackages(uniqueLocationsArray,data.order_id)}}> 
                                                            {getLabel('LBL_Check_packages')}
                                                        </div>
                                                    :   
                                                        (cancelledOrderStatus ?
                                                            <div className='package_dimension_list shipped_pack'>
                                                                -
                                                            </div>
                                                        :
                                                            (
                                                                data.packages[0] && data.packages.length < 2 ? 
                                                                    (
                                                                        <div className='package_dimension_list shipped_pack'>
                                                                            {`${parseFloat(data.packages[0].opack_length)} x ${parseFloat(data.packages[0].opack_width)} x ${parseFloat(data.packages[0].opack_height)} in`} <br/> {`${parseFloat(data.packages[0].opack_weight)} lb`}
                                                                        </div>
                                                                    )
                                                                : 
                                                                data.packages.length === 0 ? 
                                                                    (
                                                                        <div className='package_dimension_list shipped_pack'>
                                                                            -
                                                                        </div>
                                                                    ) 
                                                                :
                                                                    (
                                                                        <>
                                                                            <div className='multiple_package clickable-div'>
                                                                                {getLabel('LBL_Multiple_packages')}
                                                                            </div>
                                                                            <div className="dropdownPackage">
                                                                                <div className="close"><button type="button" className="btn-close packageClosebtn" aria-label="Close"></button></div>
                                                                                <div className="heading mb-3">
                                                                                    <h2>{getLabel('LBL_Packages')}</h2>
                                                                                </div>
                                                                                <div className="row mt-2 pack_list">
                                                                                    {
                                                                                        data.packages.map((pack,index)=>(
                                                                                        <React.Fragment key={index}>
                                                                                            
                                                                                            <span className="Packagelist" key={index}>
                                                                                                <ul>
                                                                                                    <li><img className="img-fluid" src="/images/package.svg" alt='package'/>{getLabel('LBL_Package')} {index+1}</li>
                                                                                                    <li>{`${parseFloat(pack.opack_length)} x ${parseFloat(pack.opack_width)} x ${parseFloat(pack.opack_height)} in`}</li>
                                                                                                    <li>{parseFloat(pack.opack_weight)} lb</li>
                                                                                                </ul>
                                                                                            </span>
                                                                                            <div className="row mb-4 pack_btn">
                                                                                                <div className="col-lg-12">
                                                                                                {pack.ohistory_ship_details && JSON.parse(pack.ohistory_ship_details).map((item,index)=>(
                                                                                                        (<button key={index} type="button" className="btn btn-primary"> {item.qty && item.sku ? item.qty + ' x ' + item.sku : capitalizeFirstLetter(item.referenceMsg)}</button>)
                                                                                                    ))
                                                                                                }
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="hoveredColumn">
                                                {packageRates === false ?
                                                        (cancelledOrderStatus ?
                                                            <div className='single_package_rate shipped_pack'>
                                                                -
                                                            </div>
                                                        :
                                                            <div className='single_package_rate clickable-div'> 
                                                                <img src="/images/shipping-companies/default.svg" alt='shipping'/> {getLabel('LBL_Check_packages')}
                                                            </div>
                                                        )
                                                    : 
                                                        (
                                                            data.packages[0] && data.packages.length < 2 ? 
                                                                (
                                                                    <div className='single_package_rate shipped_pack'> 
                                                                        <img src={`/images/shipping-companies/${data.packages[0].opack_shipped_by}.svg`} alt='shipping'/> 
                                                                        <span>
                                                                            {`${shippingMethodObject[data.packages[0].opack_service_type] ? shippingMethodObject[data.packages[0].opack_service_type] : shippingCompanyObject[data.packages[0].opack_shipped_by]} `} 
                                                                            {data.packages[0].opack_shipped_by === constants.CANPAR_SHIPPING_COMPANY && data.packages[0].opack_total && data.packages[0].opack_total > 0 && <><br/> {displayMoneyFormat(data.packages[0].opack_total)} </>}
                                                                            
                                                                            {data.packages[0].opack_tcode &&
                                                                                Object.values(JSON.parse(data.packages[0].opack_tcode)).map((trackCode, trackIndex) =>
                                                                                    (<React.Fragment key={trackIndex}><br />{trackCode}</React.Fragment>)
                                                                                )
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )
                                                            :
                                                            (
                                                                data.packages.length === 0 ? (
                                                                    <div className='single_package_rate shipped_pack'>
                                                                        -
                                                                    </div>
                                                                )
                                                                :
                                                                ( 
                                                                    <>
                                                                        <div className='multiple_rate clickable-div'>
                                                                            <img src="/images/shipping-companies/exclamations.svg" alt='shipping'/> {getLabel('LBL_Multiple_packages')}
                                                                        </div>
                                                                        <div className='dropdownRates'>
                                                                            <div className="close"><button type="button" className="btn-close rateClosebtn" aria-label="Close"></button></div>
                                                                            <div className="heading mb-3">
                                                                                <h2>{getLabel('LBL_Rates')}</h2>
                                                                            </div>
                                                                            <div className="row rates_packages">
                                                                                {data.packages.map((pack,index)=>(
                                                                                    <div key={index} className='mb-4 list'>
                                                                                        <span className="Packagelist">
                                                                                            <ul>
                                                                                                <li><img className="img-fluid" src="/images/package.svg" alt='package-icon'/>{getLabel('LBL_Package')} {index+1}</li>
                                                                                            </ul>
                                                                                        </span>
                                                                                        <div className="row">
                                                                                            <div className="col-lg-12">
                                                                                                {pack.ohistory_ship_details && JSON.parse(pack.ohistory_ship_details).map((item,index)=>(
                                                                                                        (<button key={index} type="button" className="btn btn-primary">{item.qty && item.sku ? item.qty + ' x ' + item.sku : capitalizeFirstLetter(item.referenceMsg)}
                                                                                                        </button>)
                                                                                                    ))
                                                                                                }
                                                                                            </div>

                                                                                            <div className="col-lg-12">
                                                                                                <ul className='packInfo'>
                                                                                                    <li>{shippingCompanyObject[pack.opack_shipped_by]} {shippingMethodObject[pack.opack_service_type] ?  ` - ${shippingMethodObject[pack.opack_service_type]}` : ''}</li>
                                                                                                    <li>{`${parseFloat(pack.opack_length)} x ${parseFloat(pack.opack_width)} x ${parseFloat(pack.opack_height)} in`}</li>
                                                                                                    <li>{parseFloat(pack.opack_weight)} lb</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mb-2 mt-1 info align-items-center">
                                                                                            {(pack.opack_shipped_by === constants.CANPAR_SHIPPING_COMPANY ) ?
                                                                                                <>
                                                                                                    <div className="col-lg-12">
                                                                                                        <label className="est">{getLabel('LBL_Est._delivery:')} {dateConverter(pack.opack_delivery_date,'monthDay')}</label>
                                                                                                    </div>
                                                                                                    {
                                                                                                        pack.opack_tcode && Object.values(JSON.parse(pack.opack_tcode)).map((trackCode, trackingIndex) => (
                                                                                                            <div className="col-lg-6 mt-1 track-color" key={trackingIndex}>
                                                                                                                {trackCode}
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                    {pack.opack_total && pack.opack_total > 0 && 
                                                                                                    <div className="col-lg-6 rates-green">
                                                                                                        <label className="rate">{getLabel('LBL_Rate:')}&nbsp;{displayMoneyFormat(pack.opack_total)}</label>
                                                                                                    </div>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                    pack.opack_tcode && Object.values(JSON.parse(pack.opack_tcode)).map((trackCode, trackingIndex) => (
                                                                                                            <div className="col-lg-12 mt-1 track-color" key={trackingIndex}>
                                                                                                                {trackCode}
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            )
                                                        )
                                                }
                                                </div>
                                            </td>
                                        </tr>
                                }) : 
                                <NoRecordsFound column={10}/>
                        }
                        </tbody>
                    </table>
                </div>
            </form>
            { orderSearchArray && orderSearchArray.length > 0 ?<Pagination goToSearchPage={goToSearchPage} orderSearchData={orderSearchData}/>:null}
            {showModal && <PackagesModal show={showModal} setShow={setShowModal} orderLocations={orderLocations} locations={locations} order_id={orderId} filters={filters}/>}
        </>
    )
}
