import { useEffect } from 'react'
import {logout,doLogout} from '../../../services/auth/authentication';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'

export default function Logout() {
  const navigate = useNavigate();
  useEffect(()=>{
    $('body').removeClass('inner_page');
    logout().then((res)=>{
      doLogout(()=>{
        navigate('/login');
      })
    }).catch((error)=>{
      if(error.code === 'ERR_NETWORK'){
        toast.error(error.message);
      }else{
        toast.error(error.response.data.message);
      }
      doLogout(()=>{
        navigate('/login');
      })
    })
    // eslint-disable-next-line
  },[])
}
