import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { addProcessingLoader, removeProcessingLoader } from '../../../../helpers/commonHelpers';
import CommonContext from '../../../../context/CommonContext';
import Location from './Location'
import Search from './Search'

export default function PackagesModal(props) {
    const {getPackage,packageData,setPackageData,getShippingCompanies,shippingCompanies,setShipmentRates,getLabel,constants} = useContext(CommonContext);
    const {show ,setShow, orderLocations,locations,order_id,filters} = props
    const [showLocation,setShowLocation] = useState(false)

    const [formData, setFormData] = useState({
        order_id: order_id,
        location_id: '',
        packages: [],
    });
    useEffect(()=>{
        setShowLocation(false)
        if(orderLocations.length > 1){
            setShowLocation(true)
        }else{
            handleSelectLocation(orderLocations[0].locationId)
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        const initialPackages = packageData.map((item,index) => ({
            weight: (item.op_product_weight),
            width: (item.op_product_width),
            length: (item.op_product_length),
            height: (item.op_product_height),
            shiping_company_id: constants.CANPAR_SHIPPING_COMPANY, 
            shiping_method: 1, 
            tracking_number: [''], 
            op_ids: [parseInt(item.op_id)],
            sku: (item.op_selprod_sku),
        }));
      
        setFormData({
        ...formData,
        packages: initialPackages,
        });
        // eslint-disable-next-line
    },[packageData])

    
    const handleClose = () => {
        setShow(false)
        setPackageData([]);
        setShipmentRates([]);
    };
    
    const handleSelectLocation = async(LocationId) => {
        const data = {
            "order_id":order_id,
            "location_id":LocationId   
        }
        addProcessingLoader();
        try {
            const res = await getPackage(data);
            if(res === true){
                setShowLocation(false)
                getShippingCompanies()
                setFormData({
                    ...formData,
                    location_id: LocationId,
                });
            }else{
                handleClose()
            }
        } catch (error) {
            handleClose()
        }
        removeProcessingLoader();
    }



  return (
      <>
          {/* <Modal show={show} onHide={handleClose} centered className={showLocation && packageData.length < 1 ?'location':'custom-modal'}>
            {showLocation  ? 
                   <Location handleSelectLocation={handleSelectLocation} locations={locations} getLabel={getLabel}/>
                : 
                (packageData.length >0 ? 
                    <Search formData={formData} setFormData={setFormData} shippingCompanies={shippingCompanies} packageData={packageData}  order_id={order_id} location_id={formData.location_id} handleClose={handleClose} filters={filters}/>
                    : 
                    ''
                )
            }
          </Modal> */}

            {
                showLocation 
                ? 
                    <Modal show={show} onHide={handleClose} centered className={'location'}>
                        <Location handleSelectLocation={handleSelectLocation} locations={locations} getLabel={getLabel}/>
                    </Modal>
                :
                    packageData.length > 0 &&
                        <Modal show={show} onHide={handleClose} centered className={'custom-modal'}>
                            <Search formData={formData} setFormData={setFormData} shippingCompanies={shippingCompanies} packageData={packageData}  order_id={order_id} location_id={formData.location_id} handleClose={handleClose} filters={filters}/>
                        </Modal>
            }
      </>
  )
}
