import React, { useEffect , useState, useContext } from 'react'
import { useOrderSearch } from '../../../hooks/orders/useOrderSearch'
import SearchForm from './SearchForm'
import Listing from "./Listing"
import PreOrderListing from "./PreOrderListing"
import { Link } from 'react-router-dom'
import { FaAngleDown } from "react-icons/fa6";
import Style from '../css/index.module.css'
import {getFilters,getUnshippedCount,cancelOrder,printPacking,createShipmentWithlabel,createMultipleShipment,getLabel as getPackageLabel, orderLabelPrint, markOrderShipped, confirmOrder} from '../../../services/orders/index'
import LocationModal from '../../Modal/views/LocationModal'
import ExportModal from '../../Modal/views/ExportModal'
import PrintPacking from "./PrintPacking"
import PrintLabels from './PrintLabels'
import $ from 'jquery'
import { toast } from 'react-toastify'
import CommonContext from '../../../context/CommonContext';
import { addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers';
import OrderConfirmationModal from './OrderConfirmationModal';
export default function Index(props) {
    const {channelId} = props

    /* Get Order Data From Order Search Hooks */ 
    const {orderSearchData,orderSearchArray,loadData,goToSearchPage,merchants,goToSearchPageByDate,isLoading,updateOrderLocation,filters,updateProductLocation,setFilters} = useOrderSearch()
    const {getLabel, listingFilter, setListingFilter, setFilterData, filterData, getOrderPendingCount, orderListingType, printLable, setPrintLable, printLabelOrderId, setPrintLabelOrderId} = useContext(CommonContext); 
    const [locations,setLocations] = useState([]);
    const [channels,setChannels] = useState([]);
    const [orderIds,setOrderIds] = useState([]);
    const [locationModalShow, setLocationModalShow] = useState(false);
    const [exportModalShow, setExportModalShow] = useState(false);
    const [exportCourierRate, setExportCourierRate] = useState(false);
    const [orderCount, setOrderCount] = useState([]);
    const [status,setStatus] = useState([]);
    const [printData,setPrintData] = useState([]);
    const [printLabelValue,setPrintLabelValue] = useState(false);
    const [showConfirmOrderModal,setShowConfirmOrderModal] = useState(false);
    useEffect(()=>{
        getOrderFilters();
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(listingFilter === true){
            loadData(filterData)
            setFilters(filterData)
            setListingFilter(false)
            setFilterData({})
        }else{
            if(channelId !== undefined){
                const channel_id = [channelId];
                goToSearchPage('channels', channel_id, true, true, true)
            }else{
                goToSearchPage('channels', [], true, true, true)
            }
        }
        getUnshippedOrderCount();
        // eslint-disable-next-line
    },[channelId])

    useEffect(() => {
        $(".selectAll-js").prop("checked", false);
        $(".orderIdBased").addClass("disabled");

        $(".selectAllLabel-js").prop("checked", false);
        $(".labelIdBased").addClass("disabled");

    },[orderSearchArray])

    const getUnshippedOrderCount = async () => {
        const id = channelId  !== undefined ? channelId : '';
        try {
            const res = await getUnshippedCount(id);
            setOrderCount(res.data)
        } catch (error) {
            setOrderCount([])
        }
    }

    const getOrderFilters = async () => {
        try {
            const data = await getFilters();
            setLocations(data.locations)
            setChannels(data.order_channels)
            setStatus(data.orderStatus)
        } catch (error) {
            setLocations([])
            setChannels([])
        }
    }

    const setOrderIdArray = () => {
        const checkedInputs = document.querySelectorAll("input[name='order_id[]']:checked");
        const newOrderIds = Array.from(checkedInputs, input => input.value);
        setOrderIds(prevOrderIds => {
          const updatedOrderIds = prevOrderIds.filter(id => newOrderIds.includes(id));
          return [...new Set([...updatedOrderIds, ...newOrderIds])];
        });
        return newOrderIds
    }

    const changeLocation = () => {
        setOrderIdArray();
        setLocationModalShow(true);
    }

    const getOpIds = () => {
        const opIds = [];
        const selectedOrderIds = setOrderIdArray();
        for (const order of orderSearchArray) {
          if (selectedOrderIds.includes(order.order_id)) {
            for (const product of order.products) {
                opIds.push(product.op_id);
            }
          }
        }
        return opIds;
    };

    const cancelOrderRequest = async() => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_id': order_Ids,
            'order_op_id': getOpIds()
        }
        const confirmation = window.confirm(getLabel('LBL_Are_you_sure_you_want_to_reject_this_shipment?'));
        if (confirmation) {
            addProcessingLoader();
            try {
                const res = await cancelOrder(data);
                if(res === true){
                    loadData(filters)
                    $(".selectAll-js").prop("checked", false);
                    $(".orderIdBased").addClass("disabled");
                }
            } catch (error) {
                console.error(false)
            }
            removeProcessingLoader();
        }
    }

    const markOrderShippedRequest = async() => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids
        }
        addProcessingLoader();
        try {
            const res = await markOrderShipped(data);
            if(res === true){
                loadData(filters);
                getOrderPendingCount();
                $(".selectAll-js").prop("checked", false);
                $(".orderIdBased").addClass("disabled");
            }
        } catch (error) {
            console.error(false)
        }
        removeProcessingLoader();
    }

    const handleExport = (courierRate = false) => {
        setOrderIdArray();
        setExportModalShow(true);
        if(courierRate === true){
            setExportCourierRate(true);
        }
    }

    const handlePrint = async() => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids,
            'is_pickup_order':0
        }
        addProcessingLoader();
        try {
            const res = await printPacking(data);
            setPrintData(res.data)
        } catch (error) {
            console.log(false)
        }
        removeProcessingLoader();
    }
    const generateLable = async() => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids
        }
        addProcessingLoader();
        try {
            const msgRes = await getPackageLabel(data);
            removeProcessingLoader();
            if(msgRes.status === true){
                setPrintLable(msgRes.data)
                setPrintLabelValue(true)
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }
    }

    const generateShipmentLable = async() => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids
        }
        addProcessingLoader();
        try {
            const msgRes = await createShipmentWithlabel(data);
            removeProcessingLoader();
            if(msgRes.status === true){
                setPrintLable(msgRes.data)
                setPrintLabelValue(true)
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }
    }
    const generateMultipleShipment = async() => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids
        }
        addProcessingLoader();
        try {
            const msgRes = await createMultipleShipment(data);
            removeProcessingLoader();
            if(msgRes.status === true){
                toast.success(msgRes.message)
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }

    }
    
    useEffect(() => {
        const handleProdPrintAfterImagesLoaded = () => {
            if (printData.length > 0) {
                window.print();
            }
        };

        const imagePromises = [];
        const loadProdImage = (imageUrl,defaultImg = false) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve();
                };
                const baseUrl = defaultImg ? '':process.env.REACT_APP_IMAGE_BASE_URL;
                img.src = baseUrl + '/' + imageUrl;
            });
        };

        for (const item of printData) {
            for (const img of item.products) {
                if (img && img.images === null) {
                    imagePromises.push(loadProdImage('images/no_image.jpg',true))
                    continue;
                }
                imagePromises.push(loadProdImage(img.images[0].afile_physical_path));
            }
        }

        Promise.all(imagePromises)
            .then(() => {
                handleProdPrintAfterImagesLoaded();
            })
            .catch((error) => {
                console.error('Error loading images:', error);
            });
    }, [printData]);

    useEffect(() => {
        const handlePrintAfterImagesLoaded = () => {
            if (printLable.length > 0) {
                window.print();
            }
        };

        const imagePromises = [];
        const loadImage = (imageUrl) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve();
                };
                img.src = imageUrl;
            });
        };
        
        for (const item of printLable) {
            for (const imageUrl of item) {
                imagePromises.push(loadImage(imageUrl));
            }
        }

        Promise.all(imagePromises)
            .then(() => {
                handlePrintAfterImagesLoaded();
            })
            .catch((error) => {
                console.error('Error loading images:', error);
            });
    }, [printLable]);

    useEffect(() => {
        const handleAfterPrint = () => {
            setPrintData([]);
            setPrintLable([]);
            if(printLabelValue === true && printLabelOrderId === 0){
                const data = {
                    'order_ids' : setOrderIdArray()
                }
                orderLabelPrint(data);
                loadData(filters)
            }
            if(printLabelValue === false && printLabelOrderId !== '' && printLabelOrderId !== 0){
                const data = {
                    'order_ids' : [printLabelOrderId]
                }
                orderLabelPrint(data);
                loadData(filters)
            }
            setPrintLabelValue(false);
            setPrintLabelOrderId(0);
        };
        window.addEventListener('afterprint', handleAfterPrint);
        return () => {
          window.removeEventListener('afterprint', handleAfterPrint);
        };
        // eslint-disable-next-line
    }, [printLabelValue, printLabelOrderId]);

    const handleConfirmOrder = async() => {
        const data = {
            'order_ids': setOrderIdArray()
        }
        addProcessingLoader();
        try {
            const res = await confirmOrder(data);
            if(res === true){
                loadData(filters)
                $(".selectAllLabel-js").prop("checked", false);
                $(".labelIdBased").addClass("disabled");
            }
        } catch (error) {
            // Silently handle error
        }
        removeProcessingLoader();
    }
    return (
        <>
            <div id="body" className='no-print'>
                <div className="page no-print">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 space">
                                <div className="page__title">
                                    <div className="row">
                                        <div className="col--first col-md-12">
                                            <h5>Orders</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 right-btn">
                                            <Link className="btn orderIdBased print_label disabled " >
                                                <span className="print_content" onClick={generateShipmentLable}>
                                                    <img src={`/images/print-icon.svg`} alt="" /> {getLabel('LBL_Create_+_Print_labels')} 
                                                </span>
                                                <label className={"print_action"}><FaAngleDown /></label>
                                            </Link>
                                            <ul className={"print_action_list_js print_action_list"}>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={generateMultipleShipment}>{getLabel('LBL_Create_shipping_label')}</li>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={generateLable}>{getLabel('LBL_Print_shipping_label')}</li>
                                            </ul>
                                            <Link className="btn other_action">{getLabel('LBL_Other_action')} <FaAngleDown /></Link>
                                            <ul className={"other_action_list_js other_action_list"}>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={cancelOrderRequest}>{getLabel('LBL_Cancel_Shipment')}</li>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={markOrderShippedRequest}>{getLabel('LBL_Label_provided')}</li>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={changeLocation}>{getLabel('LBL_Change_location')}</li>
                                                <li className="other_action_item" onClick={handleExport}>{getLabel('LBL_Export_order_list')}</li>
                                                <li className="other_action_item" onClick={() => handleExport(true)}>{getLabel('LBL_Export_courier_rate')}</li>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={handlePrint}>{getLabel('LBL_Print_packing_list')}</li>
                                                <li className={'labelIdBased disabled other_action_item'} onClick={() => setShowConfirmOrderModal(true)}>{getLabel('LBL_Remove_hold')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='listingContent'>
                                    {/* Order Search Form */}
                                    <SearchForm goToSearchPage={goToSearchPage} goToSearchPageByDate={goToSearchPageByDate} merchants={merchants} Style={Style} locations={locations} channels={channels} unshippedOrderCount={orderCount} channelId={channelId} filters={filters}/>

                                    <section className="section">
                                        <div className="sectionbody">
                                            <div className="tablewrap">
                                                <div id="ordersListing">
                                                {
                                                   (orderSearchArray && orderListingType === false ? <Listing orderSearchArray={orderSearchArray} goToSearchPage={goToSearchPage} orderSearchData={orderSearchData} isLoading={isLoading} locations={locations} updateOrderLocation={updateOrderLocation}  filters={filters} updateProductLocation={updateProductLocation} channelId={channelId === undefined ? 'A' :channelId }/> : null)
                                                }
                                                {
                                                   (orderSearchArray && orderListingType === true ? <PreOrderListing orderSearchArray={orderSearchArray} goToSearchPage={goToSearchPage} orderSearchData={orderSearchData} isLoading={isLoading}   filters={filters}  channelId={channelId === undefined ? 'A' :channelId }/> : null)
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { <LocationModal show={locationModalShow} setShow={setLocationModalShow} orderIds={orderIds} locations={locations} updateOrderLocation={updateOrderLocation}   />}
            { <ExportModal show={exportModalShow} setShow={setExportModalShow} channelId={channelId} isPickup = "0" isCourierRateCall={exportCourierRate} setExportCourierRate={setExportCourierRate} merchants={merchants} orderIds = {orderIds}/>}
            {
                <div className='print'>
                    {printData && printData.length > 0 && printData.map((item,index) =>(
                            <PrintPacking key={index} data={item} location={locations} status={status}/>
                        ))
                    }
                    {printLable && printLable.length > 0 && printLable.map((item,index) =>(
                            <PrintLabels key={index} data={item}/>
                        ))
                    }
                </div>
            }
            <OrderConfirmationModal show={showConfirmOrderModal} setShow={setShowConfirmOrderModal} handleConfirmOrder={handleConfirmOrder}/>
        </> 
    )
}
