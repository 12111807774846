import React, { useContext, useState } from 'react'
import CommonContext from '../../../../context/CommonContext'
import UpdateShippingInfo from '../UpdateShippingInfo';
export default function ShippingInfo() {
    const { getLabel, orderAddressInfo } = useContext(CommonContext);
    const address = Object.keys(orderAddressInfo.address).length > 0 ? orderAddressInfo.address : {};
    const Shipping = orderAddressInfo.prodLangData.length > 0 ? orderAddressInfo.prodLangData : [];
    const colMd = Shipping.length > 0 ? '3' : '4';
    const [openShippingModal, setOpenShippingModal] = useState(false);
    const renderAddressLine = (line) => line ? <>{line} <br /></> : null;
    return (
        <>
            {address.oua_address1 !== '' ?
                <div className="row package_shipping_css">
                    <div className={`col-md-${colMd}`}>
                        <div className="head">
                            {getLabel('LBL_Ship_to')}
                        </div>
                        <span>
                            {address.oua_name ?? ''}
                            <br />
                            {address.oua_phone ?? ''}
                        </span>
                    </div>
                    <div className={`col-md-${parseInt(colMd)+2}`}>
                        <div className="head"></div>

                        <span>
                            {renderAddressLine(address.oua_address1)}
                            {renderAddressLine(address.oua_address2)}
                            {address.oua_city && `${address.oua_city}, `}
                            {address.oua_state && `${address.oua_state} `}
                            {renderAddressLine(address.oua_zip)}
                            {renderAddressLine(address.oua_country)}
                        </span>

                    </div>
                    {
                        Shipping.length > 0 ?
                            <div className={`col-md-${colMd}`}>
                                <div className="head"></div>
                                {
                                    Shipping.map((item, index) => {
                                        return <span key={index} className={`${item['op_shipping_duration_name'].toLowerCase().trim() === 'standard shipping' ? 'standard_shipping_name' : 'expedited_shipping_name'}`}>
                                            {item['op_shipping_duration_name']} <br />
                                        </span>
                                    })
                                }
                            </div>
                            :
                            ''
                    }

                    <div className={`col-md-${colMd-2} text-right`}>
                        <div className="head edit_link" onClick={() => setOpenShippingModal(true)}>{getLabel('LBL_Edit')}</div>
                    </div>
                    {openShippingModal && <UpdateShippingInfo show={openShippingModal} setShow={setOpenShippingModal} data={orderAddressInfo} isPackage={true} />}
                </div>
                :
                ''
            }
        </>
    )
}
