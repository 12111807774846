import { useNavigate, Navigate } from "react-router-dom";
import { isLoggedIn,setNavigateCallback } from "../helpers/commonHelpers";
import { useEffect } from "react";
export default function Protected(props){
    const {Component,channelId} = props
    const navigate = useNavigate();
    useEffect(() =>{
      setNavigateCallback(() => {
        navigate('/login');
      });
      // eslint-disable-next-line
    },[setNavigateCallback])
    if (isLoggedIn() === true) {
        return <Component channelId={channelId} />;
    } else {
        return <Navigate to="/login" />;
    }
}

