import React, { useContext, useEffect, useState } from 'react'
import Listing from "./Listing"
import CommonContext from '../../../context/CommonContext'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { getShippingLabel, markPrinted } from '../../../services/imported-labels/index'
import { addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers';
import { toast } from 'react-toastify'
export default function Index() {
    const { searchImportLabels, getLabel, importedLabelData, getImportLabelsCount } = useContext(CommonContext);
    const initialFilters = {
        page: 1,
        sortOrder: []
    };

    const [filters, setFilters] = useState(initialFilters);
    useEffect(() => {
        goToSearchPage()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        $(".selectAllLabel-js").prop("checked", false);
        $(".labelIdBased").addClass("disabled");
    }, [importedLabelData])

    const goToSearchPage = (type = 'page', value = 1, resetPage = false) => {
        const updatedFilters = {
            ...filters,
            [type]: value,
            page: resetPage ? 1 : value
        };
        setFilters(updatedFilters);
        searchImportLabels(updatedFilters);
    }

    const setOrderIdArray = () => {
        const checkedInputs = document.querySelectorAll("input[name='ilabel_id[]']:checked");
        const newOrderIds = Array.from(checkedInputs, input => parseInt(input.value));
        return newOrderIds
    }

    const generateLable = async (label_ids) => {
        if (label_ids < 1) {
            label_ids = setOrderIdArray();
        } else {
            label_ids = [label_ids];
        }

        const data = {
            'label_ids': label_ids
        }
        addProcessingLoader();
        try {
            const msgRes = await getShippingLabel(data);
            removeProcessingLoader();
            if (msgRes.status === true && msgRes.data) {
                downloadPdfs(msgRes.data);
                const marked = await markPrinted(data);
                if(marked.status === true){
                    searchImportLabels(filters);
                    getImportLabelsCount();
                }
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }
    }

    const downloadPdfs = (urls) => {
        urls.forEach((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    return (
        <>
            <div id="body">
                <div className="page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 space">
                                <div className="page__title">
                                    <div className="row">
                                        <div className="col--first col-md-12">
                                            <h5>{getLabel('LBL_Shipping_labels')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 right-btn">
                                            <Link className="btn labelIdBased print_label disabled" onClick={() => generateLable(0)}>
                                                {getLabel('LBL_Print_labels')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='listingContent'>
                                    <section className="section">
                                        <div className="sectionbody">
                                            <div className="tablewrap">
                                                <div id="labelsListing">
                                                    <Listing goToSearchPage={goToSearchPage} downloadPdf={generateLable} />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
