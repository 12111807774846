import React, { useState, useEffect, useRef  } from 'react';
export default function SearchForm(props) {
    const {goToSearchPage,getLabel} = props
    const [searchTerm, setSearchTerm] = useState('');
    const isInitialRender = useRef(true);
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        const delaySearch = setTimeout(() => {
            goToSearchPage('keyword', searchTerm,true);
        }, 500); 
    
        return () => clearTimeout(delaySearch);
        // eslint-disable-next-line
      }, [searchTerm]);

      const handleInputChange = (event) => {
        setSearchTerm(event.target.value.trim());
      };
      
      const handleSubmit = (event) => {
        event.preventDefault();
      };
  return (
      <>
          <section className="section searchform_filter">

              <div className="sectionbody space">
                  <form name="frmOrderSearch" id="frmOrderSearch" className="web_form" onSubmit={handleSubmit}>
                      <div className="row">
                          <div className="col-md-6">
                              <div className="field-set">
                                  <div className="caption-wraper">
                                      <label className="field_label" />
                                  </div>
                                  <div className="field-wraper">
                                      <div className="field_cover">
                                          <input className='search_btn' type="submit" name="btn_submit" value="Search" />
                                          <input id="keyword" className='KeywordInputCss' autoComplete="off" type="text" name="keyword" placeholder={getLabel('LBL_Search_by_shop_name,_email.')} onChange={handleInputChange} />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
          </section>
      </>
  )
}
