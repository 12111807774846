import React, { useEffect, useState, useContext } from 'react'
import { useOrderPickupSearch } from '../../../hooks/orders/usePickupOrderSearch'
import SearchForm from './SearchForm'
import Listing from "./Listing"
import { Link } from 'react-router-dom'
import { FaAngleDown } from "react-icons/fa6";
import Style from '../css/index.module.css'
import { getFilters, printPacking, changePickupStatus} from '../../../services/orders/index'
import ExportModal from '../../Modal/views/ExportModal'
import PrintPacking from "./PrintPacking"
import $ from 'jquery'
import { toast } from 'react-toastify'
import CommonContext from '../../../context/CommonContext';
import { addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers'
export default function Index() {

    /* Get Order Data From Order Search Hooks */
    const { pickupOrderSearchData, pickupOrderSearchArray, loadData, goToSearchPage, merchants, goToSearchPageByDate, isLoading, filters, setFilters } = useOrderPickupSearch()
    const { getLabel, constants, channelObject, listingFilter, setListingFilter, setFilterData, filterData } = useContext(CommonContext);
    const [locations, setLocations] = useState([]);
    const [exportModalShow, setExportModalShow] = useState(false);
    const [exportCourierRate, setExportCourierRate] = useState(false);
    const [status, setStatus] = useState([]);
    const [printData, setPrintData] = useState([]);
    const [orderIds, setOrderIds] = useState([]);

    useEffect(() => {
        getOrderFilters();
        if (listingFilter === true) {
            loadData(filterData)
            setFilters(filterData)
            setListingFilter(false)
            setFilterData({})
        } else {
            goToSearchPage('channels', [], true, true);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        $(".selectAll-js").prop("checked", false);
        $(".orderIdBased").addClass("disabled");
    }, [pickupOrderSearchArray])


    const getOrderFilters = async () => {
        try {
            const data = await getFilters();
            setLocations(data.locations)
            setStatus(data.orderStatus)
        } catch (error) {
            setLocations([])
        }
    }

    const setOrderIdArray = () => {
        const checkedInputs = document.querySelectorAll("input[name='order_id[]']:checked");
        const newOrderIds = Array.from(checkedInputs, input => input.value);
        return newOrderIds
    }

    const handleExport = () => {
        setExportModalShow(true);
        setOrderIds(setOrderIdArray());
    }

    const handlePrint = async () => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids,
            'is_pickup_order': 1
        }
        addProcessingLoader();
        try {
            const res = await printPacking(data);
            setPrintData(res.data)
        } catch (error) {
            console.log(false)
        }
        removeProcessingLoader();
    }
    const markedPickup = async () => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids,
            'order_op_ids': [],
            'order_status': constants.ORDER_PICKED_UP
        }
        addProcessingLoader();
        try {
            const msgRes = await changePickupStatus(data);
            removeProcessingLoader();
            if (msgRes.status === true) {
                toast.success(msgRes.message)
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }
    }

    const preparePickup = async () => {
        const order_Ids = setOrderIdArray();
        const data = {
            'order_ids': order_Ids,
            'order_op_ids': [],
            'order_status': constants.ORDER_PICKUP_READY
        }
        addProcessingLoader();
        try {
            const msgRes = await changePickupStatus(data);
            removeProcessingLoader();
            if (msgRes.status === true) {
                toast.success(msgRes.message)
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error)
        }

    }

    useEffect(() => {
        const handleProdPrintAfterImagesLoaded = () => {
            if (printData.length > 0) {
                window.print();
            }
        };

        const imagePromises = [];
        const loadProdImage = (imageUrl, defaultImg = false) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve();
                };
                const baseUrl = defaultImg ? '' : process.env.REACT_APP_IMAGE_BASE_URL;
                img.src = baseUrl + '/' + imageUrl;
            });
        };

        for (const item of printData) {
            for (const img of item.products) {
                if (img && img.images === null) {
                    imagePromises.push(loadProdImage('images/no_image.jpg', true))
                    continue;
                }
                imagePromises.push(loadProdImage(img.images[0].afile_physical_path));
            }
        }

        Promise.all(imagePromises)
            .then(() => {
                handleProdPrintAfterImagesLoaded();
            })
            .catch((error) => {
                console.error('Error loading images:', error);
            });
    }, [printData]);



    return (
        <>
            <div id="body" className='no-print'>
                <div className="page no-print">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 space">
                                <div className="page__title">
                                    <div className="row">
                                        <div className="col--first col-md-12">
                                            <h5>{getLabel('LBL_PICK_UP_ORDERS')} </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 right-btn">
                                            <Link className="btn pickup_option orderIdBased disabled" >{getLabel('LBL_PICK_UP_OPTIONS')} <FaAngleDown /></Link>
                                            <ul className={"pickup_option_list_js pickup_option_list"}>
                                                <li className={'orderIdBased disabled pickup_option_item'} onClick={preparePickup}>{getLabel('LBL_PREPARE_FOR_PICK_UP')}</li>
                                                <li className={'orderIdBased disabled pickup_option_item'} onClick={markedPickup}>{getLabel('LBL_MARKED_PICKED_UP')}</li>
                                            </ul>
                                            <Link className="btn other_action">{getLabel('LBL_Other_action')} <FaAngleDown /></Link>
                                            <ul className={"other_action_list_js other_action_list"}>
                                                <li className="other_action_item" onClick={handleExport}>{getLabel('LBL_Export_Order_list')}</li>
                                                <li className={'orderIdBased disabled other_action_item'} onClick={handlePrint}>{getLabel('LBL_Print_packing_list')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='listingContent'>
                                    {/* Order Search Form */}
                                    <SearchForm goToSearchPage={goToSearchPage} goToSearchPageByDate={goToSearchPageByDate} merchants={merchants} Style={Style} locations={locations} getLabel={getLabel} constants={constants} channelObject={channelObject} filters={filters} />

                                    <section className="section">
                                        <div className="sectionbody">
                                            <div className="tablewrap">
                                                <div id="ordersListing">
                                                    {
                                                        (pickupOrderSearchArray ? <Listing pickupOrderSearchArray={pickupOrderSearchArray} goToSearchPage={goToSearchPage} pickupOrderSearchData={pickupOrderSearchData} isLoading={isLoading} locations={locations} filters={filters} loadData={loadData}/> : null)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {< ExportModal show={exportModalShow} setShow={setExportModalShow} channelId="0" isPickup="1" isCourierRateCall={exportCourierRate} setExportCourierRate={setExportCourierRate} merchants={merchants} orderIds = {orderIds}/>}
            {
                <div className='print'>
                    {printData && printData.length > 0 && printData.map((item, index) => (
                        <PrintPacking key={index} data={item} status={status} />
                    ))
                    }
                </div>
            }

        </>
    )
}
