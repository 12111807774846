import React,{useState, useEffect, useContext} from 'react'
import Modal from 'react-bootstrap/Modal'
import CommonContext from '../../../../context/CommonContext'
import {dateConverter, removeSuffix, addProcessingLoader, removeProcessingLoader} from '../../../../helpers/commonHelpers'
import { validationSchema } from "../../../../schemas/packageSchema";
import Confirmation from './Confirmation';
import ShippingInfo from './ShippingInfo'
import { FaAngleDown } from "react-icons/fa6";
export default function Combination(props) {
  const {data, shippingCompanies, order_id, location_id, filters, handleClose, generateLable} = props
  
  const {getRates, shipmentRates, createShipment, orderSearch, constants, shippingMethodObject, getLabel, getOrderPendingCount} = useContext(CommonContext);
  const allOpIds = data.map((item) => item.op_id);
  const initialSelectedOpIds = [allOpIds];
  const [selectedOpIds, setSelectedIds] = useState(initialSelectedOpIds);
  const [numberOfDivs, setNumberOfDivs] = useState(1);
  const [dimensions, setDimensions] = useState([]);
  const [showTrackingField,setShowTrackingField] = useState({})
  const [currentIndex,setCurrentIndex] = useState(0)
  const [selectedOpIdFlatArray, setSelectedOpIdFlatArray] = useState([])
  const [removeCombinationDiv,setRemoveCombinationDiv] = useState({})
  const [errors, setErrors] = useState({});
  const [packageConfirmationPopup, setPackageConfirmationPopup] = useState(false);
  const [printShipLabel, setPrintShipLabel] = useState(true);
  const [formData, setFormData] = useState({
    order_id: order_id,
    location_id: location_id,
    packages: [{tracking_number: ['']}],
  });

  useEffect(() => {
    const calculatedDimensions = calculateDimensions(selectedOpIds, data);
    setDimensions(calculatedDimensions);
    setSelectedOpIdFlatArray( selectedOpIds.flat())
    // eslint-disable-next-line 
  }, [selectedOpIds]);
  
  const calculateDimensions = (selectedOpIds, data) => {
    const dimensionsArray = [];
  
    for (const opIdGroup of selectedOpIds) {
        let length = 0;
        let width = 0;
        let height = 0;
        let weight = 0;
        let highest_length = -Infinity;
        let highest_width = -Infinity;
        let highest_height = -Infinity;
      if (Array.isArray(opIdGroup)) {
        
        for (const opId of opIdGroup) {
          for (const item of data) {
            if (opId === item.op_id) {
              highest_length = Math.max(highest_length, parseFloat(item.op_product_length));
              highest_width = Math.max(highest_width, parseFloat(item.op_product_width));
              highest_height = Math.max(highest_height, parseFloat(item.op_product_height));
              // length += parseFloat(item.op_product_length);
              // width += parseFloat(item.op_product_width);
              // height += parseFloat(item.op_product_height);
              weight += parseFloat(item.op_product_weight);
            }
          }
          length = highest_length;
          width = highest_width;
          height = highest_height;
        }

        const dimensionsObject = {
          length: length.toFixed(2),
          width: width.toFixed(2),
          height: height.toFixed(2),
          weight: weight.toFixed(2),
        };
  
        dimensionsArray.push(dimensionsObject);
      } else {
        dimensionsArray.push({
          length: length.toFixed(2),
          width: width.toFixed(2),
          height: height.toFixed(2),
          weight: weight.toFixed(2),});
        }
    }

    if (dimensionsArray.length > 0 && currentIndex < formData.packages.length) {
      setFormData((prevFormData) => {
        const updatedPackages = [...prevFormData.packages];
        updatedPackages[currentIndex].length = dimensionsArray[currentIndex].length || 0;
        updatedPackages[currentIndex].width = dimensionsArray[currentIndex].width || 0;
        updatedPackages[currentIndex].height = dimensionsArray[currentIndex].height || 0;
        updatedPackages[currentIndex].weight = dimensionsArray[currentIndex].weight || 0;
        return {
          ...prevFormData,
          packages: updatedPackages,
        };
      });
    }

    return dimensionsArray;
  };

  useEffect(()=>{
    let highest_height = -Infinity;
    let highest_length = -Infinity;
    let highest_width = -Infinity;
    const accumulatedData = data.reduce((accumulator, item) => {
      highest_height = Math.max(highest_height, parseFloat(item.op_product_height));
      highest_length = Math.max(highest_length, parseFloat(item.op_product_length));
      highest_width = Math.max(highest_width, parseFloat(item.op_product_width)); 
      return {
        weight: (parseFloat(accumulator.weight) + parseFloat(item.op_product_weight)).toFixed(2),
        height: highest_height.toFixed(2), 
        length: highest_length.toFixed(2), 
        width: highest_width.toFixed(2), 
      };
    }, {
      weight: '0.00',
      width: '0.00',
      length: '0.00',
      height: '0.00',
    });


    const initialPackages = [{
        weight: (accumulatedData.weight),
        width: (accumulatedData.width),
        length: (accumulatedData.length),
        height: accumulatedData.height,
        shiping_company_id: constants.CANPAR_SHIPPING_COMPANY, 
        shiping_method: 1, 
        tracking_number: [''], 
        op_ids: selectedOpIds[0],
    }];

    setFormData({
    ...formData,
    packages: initialPackages,
    });

    // eslint-disable-next-line
  },[data])

  const toggleSKUSelection = (opId, index) => {
    setSelectedIds((prevSelectedOpIds) => {
        const newSelectedOpIds = [...prevSelectedOpIds];

        if (!Array.isArray(newSelectedOpIds[index])) {
          newSelectedOpIds[index] = [];
        }
    
        const selectedIndex = newSelectedOpIds[index].indexOf(opId);
    
        if (selectedIndex === -1) {
          newSelectedOpIds[index].push(opId);
        } else {
          newSelectedOpIds[index].splice(selectedIndex, 1);
        }

        setFormData((prevFormData) => {
          const updatedPackages = [...prevFormData.packages];
          updatedPackages[index].op_ids = newSelectedOpIds[index];
          return {
            ...prevFormData,
            packages: updatedPackages,
          };
        });
    
        return newSelectedOpIds;
    });
    setCurrentIndex(index)
  };
      
  const handleInputChange = (event, index, field) => {
    const newValue = event.target.value;

    const updatedDimensions = [...dimensions];

    if (!updatedDimensions[index]) {
      updatedDimensions[index] = {};
    }

    updatedDimensions[index][field] = newValue;

    setDimensions(updatedDimensions);

    const updatedItem = { ...formData.packages[index], [field]: newValue };
    const updatedPackages = [...formData.packages];
    updatedPackages[index] = updatedItem;
    setFormData({
    ...formData,
    packages: updatedPackages,
    });

    if(field === 'shiping_company_id'){
      setShowTrackingField(prevState => ({
        ...prevState, 
        [index]: parseInt(newValue) === constants.CANPAR_SHIPPING_COMPANY ? false:true,
      }));
    }
  };


  const addMoreCombination = () => {
    setNumberOfDivs(prevCount => prevCount + 1);
    const newPackage = {
      weight: '0.00', 
      width: '0.00',
      length: '0.00', 
      height: '0.00', 
      shiping_company_id: constants.CANPAR_SHIPPING_COMPANY, 
      shiping_method: 1,
      tracking_number: [''], 
      op_ids: [], 
    };

    // Update formData.packages with the new package
    setFormData((prevFormData) => ({
      ...prevFormData,
      packages: [...prevFormData.packages, newPackage],
    }));

  };

  const removeCombination = (index) => {
    if (index === 0 || index >= formData.packages.length) {
      return;
    }
    setSelectedIds((prevSelectedOpIds) => (
      prevSelectedOpIds.slice(0, index).concat(prevSelectedOpIds.slice(index + 1))
    ));

    setNumberOfDivs(prevCount => prevCount - 1);


    const updatedPackages = [...formData.packages];
    updatedPackages.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      packages: updatedPackages,
    }));

    setRemoveCombinationDiv(prevState => ({
      ...prevState, 
      [index]: false,
    }))

      const errorObject = { ...errors };

      for (const key in errorObject) {
          // Check if the key contains '[index]' and if it matches the indexToRemove
          if (key.includes(`[${index}]`)) {
              delete errorObject[key];
          }
      }
      setErrors(errorObject);
  };

  const addTrackingNumber = (index) => {
    const updatedPackages = [...formData.packages];
    updatedPackages[index].tracking_number.push(""); 
    setFormData({
        ...formData,
        packages: updatedPackages,
    });
  };

  const removeTrackingNumber = (packageIndex, trackingIndex) => {
    const updatedPackages = [...formData.packages];
    const packageToUpdate = updatedPackages[packageIndex];
    
    if (trackingIndex >= 0 && trackingIndex < packageToUpdate.tracking_number.length) {
      packageToUpdate.tracking_number.splice(trackingIndex, 1);
  
      setFormData({
        ...formData,
        packages: updatedPackages,
      });
    }
  };

  const handleTrackingNumberChange = (e, packageIndex, trackingIndex) => {
    const { value } = e.target;
    const updatedPackages = [...formData.packages];
    updatedPackages[packageIndex].tracking_number[trackingIndex] = value;
    setFormData({
      ...formData,
      packages: updatedPackages,
    });
  };

  const checkMissingOpIds = (printLabel) => {
    const missingOpIds = data.filter((item) => !selectedOpIdFlatArray.includes(item.op_id));
    if(missingOpIds.length > 0){
      setPackageConfirmationPopup(true);
      setPrintShipLabel(printLabel);
      return;
    }
    handleShipPackage(printLabel);
  }

  const handleShipPackage = async(printLabel) => {
    setPackageConfirmationPopup(false);
    addProcessingLoader();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      try {
        const res = await createShipment(removeSuffix(formData));
        if (res === true) {
          getOrderPendingCount();
          handleClose();
          orderSearch(filters);
          if(printLabel === true && order_id !== ''){
            generateLable(order_id);
          }
        }
      } catch (error) {
        // Silently handle error
      }
    } catch (validationErrors) {
      const errorsObj = {};
      validationErrors.inner.forEach((error) => {
        const path = error.path.split('.');
        const fieldName = path[1];
        const packageIndex = path[0];
        errorsObj[`${fieldName}_${packageIndex}`] = error.message;
      });
      setErrors(errorsObj);
    }
    removeProcessingLoader();
  };

  const handleShippingRate = async () => {
    addProcessingLoader();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      try {
        await getRates(removeSuffix(formData))
      }catch (e){
        // Silently handle error
      }
    } catch (validationErrors) {
      const errorsObj = {};
      validationErrors.inner.forEach((error) => {
        const path = error.path.split('.');
        const fieldName = path[1];
        const packageIndex = path[0];
        errorsObj[`${fieldName}_${packageIndex}`] = error.message;
      });
      setErrors(errorsObj);
    }
    removeProcessingLoader();
  };

    const handleRemoveCombinationDiv = (index) => {
      setRemoveCombinationDiv(prevState => ({
        ...prevState, 
        [index]: true,
      }))
    }

    const keepCombination = (index) => {
      setRemoveCombinationDiv(prevState => ({
        ...prevState, 
        [index]: false,
      }))
    }

  return (
    <>
        <>
          <Modal.Header closeButton className='mb-3 closeBtnCss'>
            <h1 className="modal-title modal-title-heading  combination" id="exampleModalLabel">
              <span className='constatnt-width'>
                {getLabel('LBL_Create_combinations')}
              </span>
              <span className="info tooltip-icon">
                <img className="img-fluid package-top-img" src="/images/info.svg" alt='icon' />
                <span className='combinations tooltip-msg'>{getLabel('LBL_The_colored_products_will_be_included_in_your_combination')}</span>
              </span>
            </h1>
          </Modal.Header>
          <Modal.Body>
          <ShippingInfo />
            {Array.from({ length: numberOfDivs }).map((_, index) => (
              <div className="row package-row" key={index} >
                <span className="loction combination-head mb-4">
                  <div className='left-head'>
                    <img className="img-fluid" src="/images/combination.svg" alt='icon' />
                    <h4>{getLabel('LBL_Combination')} {index + 1}</h4>
                  </div>

                  {index === 0 ? null : (
                    <span className='right-head'>
                      {!removeCombinationDiv[index] && <img className="img-fluid " src="/images/remove.svg" alt="icon" onClick={() => handleRemoveCombinationDiv(index)} />}
                    </span>
                  )}
                </span>
                {removeCombinationDiv[index] 
                ? 
                  <div className='col-lg-12 remove_combination'>
                    <div>
                      <h2>{getLabel('LBL_Remove_combination')}</h2>
                      <p>{getLabel('LBL_Are_you_sure_you_want_to_remove_Combination')} {index+1}?</p>
                    </div>
                    <div className='row'>
                      <div className="col-lg-6 col-sm-6">
                        <button type="button" className="btn btn-outline-secondary text-danger" onClick={() => removeCombination(index)} >{getLabel('LBL_Remove')}</button>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <button type="button" className="btn btn-outline-secondary" onClick={() => keepCombination(index)} >{getLabel('LBL_Keep')}</button>
                      </div>
                    </div>
                  </div>
                :
                <>
                    <div className="col-lg-12">
                      <div className="border-buton">
                        <ul>
                          <li className='dimension_input'>
                            <label className='dimension'>{getLabel('LBL_Length')} (L)</label>
                            <input type="text" className="dimension_box numberWithDots" name="length" value={index === 0 ? (dimensions[index] ? dimensions[index].length : '') : (formData.packages[index] ? formData.packages[index].length : '')} onChange={(event) => handleInputChange(event, index, 'length')} />
                            <span>in</span>
                            {errors[`length_packages[${index}]`] && (
                              <div className="list_error error">{errors[`length_packages[${index}]`]}</div>
                            )}
                          </li>

                          <li className='crossIcon'><img src="/images/close.svg" alt='icon' /></li>

                          <li className='dimension_input'>
                            <label className='dimension'>{getLabel('LBL_Width')} (W)</label>
                            <input type="text" className="dimension_box numberWithDots" name="width" value={index === 0 ? (dimensions[index] ? dimensions[index].width : '') : (formData.packages[index] ? formData.packages[index].width : '')} onChange={(event) => handleInputChange(event, index, 'width')} />
                            <span>in</span>
                            {errors[`width_packages[${index}]`] && (
                              <div className="list_error error">{errors[`width_packages[${index}]`]}</div>
                            )}
                          </li>

                          <li className='crossIcon'><img src="/images/close.svg" alt='icon' /></li>

                          <li className='dimension_input'>
                            <label className='dimension'>{getLabel('LBL_Height')} (H)</label>
                            <input type="text" className="dimension_box numberWithDots" name="height" value={index === 0 ? (dimensions[index] ? dimensions[index].height : '') : (formData.packages[index] ? formData.packages[index].height : '')} onChange={(event) => handleInputChange(event, index, 'height')} />
                            <span>in</span>
                            {errors[`height_packages[${index}]`] && (
                              <div className="list_error error">{errors[`height_packages[${index}]`]}</div>
                            )}
                          </li>
                          <div></div>
                          <div></div>
                          <div></div>
                          <li className='dimension_input'>
                            <label className='dimension weight'>{getLabel('LBL_Weight')}</label>
                            <input type="text" className="dimension_box numberWithDots" name="weight" value={index === 0 ? (dimensions[index] ? dimensions[index].weight : '') : (formData.packages[index] ? formData.packages[index].weight : '')} onChange={(event) => handleInputChange(event, index, 'weight')} />
                            <span>lbs</span>
                            {errors[`weight_packages[${index}]`] && (
                              <div className="list_error error">{errors[`weight_packages[${index}]`]}</div>
                            )}
                          </li>

                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-12 combination_sku">
                      {data.map((item, skuIndex) => {
                        const shouldToggleSelection = selectedOpIds.some((subArray, subIndex) =>
                          subIndex !== index && subArray && subArray.includes(item.op_id)
                        );
                        return <button key={skuIndex} type="button" className={`btn sku-btn ${selectedOpIds[index] && selectedOpIds[index].includes(item.op_id) ? 'selected' : ''} ${shouldToggleSelection ? 'disable' : ''}`}
                          onClick={shouldToggleSelection ? undefined : () => toggleSKUSelection(item.op_id, index)}
                        >{item.op_selprod_sku}</button>
                      })}
                    </div>

                    <div className="row ground">
                      <div className="col col-lg-6 col-sm-6">
                        <select className="form-select" aria-label="Default select example" name='shiping_company_id' value={formData.packages[index] ? formData.packages[index].shiping_company_id : ''} onChange={(event) => handleInputChange(event, index, event.target.name)}>
                          <option value={0}>{getLabel('LBL_Select Shipping')}</option>
                          {shippingCompanies && shippingCompanies.map((shippingCompany, index) => (
                            <option value={parseInt(shippingCompany.scompany_id)} key={index}>{shippingCompany.company_name}</option>
                          ))}
                        </select>
                        {errors[`shiping_company_id_packages[${index}]`] && (
                          <div className="company_error error">{errors[`shiping_company_id_packages[${index}]`]}</div>
                        )}
                        {shipmentRates[index] && (showTrackingField[index]=== false || showTrackingField[index] === undefined) ?
                          <label className="est">{getLabel('LBL_Est._delivery:')} {dateConverter(shipmentRates[index].estDlyDate, 'monthDay')}</label>
                          : ""}
                      </div>
                      {(showTrackingField[index]=== false || showTrackingField[index] === undefined)  && 
                        <div className="col col-lg-6 col-sm-6">
                          <select className="form-select" aria-label="Default select example" name='shiping_method' value={formData.packages[index] ? formData.packages[index].shiping_method : ''} onChange={(event) => handleInputChange(event, index, event.target.name)}>
                            {Object.entries(shippingMethodObject).map(([key, value]) => (
                              <option key={key} value={key}>
                                  {value}
                              </option>
                            ))}
                          </select>
                          <label className={`rate ${shipmentRates[index] && (showTrackingField[index]=== false || showTrackingField[index] === undefined) ? 'canpar_rate' : ''}`}>{getLabel('LBL_Rate:')} {shipmentRates[index] && (showTrackingField[index]=== false || showTrackingField[index] === undefined) ? '$'+(shipmentRates[index].price).toFixed(2) : '-'}</label>
                        </div>
                      }
                    </div>

                    <div className="row tracking_field ground">

                      {(showTrackingField[index]) && formData.packages[index].tracking_number && formData.packages[index].tracking_number.map((trackingNumber, trackingIndex) => (
                        <React.Fragment key={trackingIndex}>
                          <div className="col col-lg-6 col-sm-6">
                            <label className="tracking">{getLabel('LBL_Tracking_Numbers')}</label>
                            <input type="text" className="tracking-input" name={`tracking_number_${index}_${trackingIndex}`} value={trackingNumber} onChange={(e) => handleTrackingNumberChange(e, index, trackingIndex)} />
                          </div>
                          <div className="col col-lg-6 col-sm-6">
                            {trackingIndex === 0 ?
                              <img className="img-fluid plus" src="/images/plus.svg" alt="plus-icon" onClick={() => addTrackingNumber(index)} />
                              :
                              <img className="img-fluid plus" src="/images/remove.svg" alt="icon" onClick={() => removeTrackingNumber(index, trackingIndex)} />
                            }
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                }
              </div>
            ))}

            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <button type="button" className="btn btn-outline-secondary" onClick={addMoreCombination} >{getLabel('LBL_Add_combination')}</button>
              </div>
              <div className="col-lg-4 col-sm-4">
                <button type="button" className="btn btn-outline-secondary margin-bottom-16" onClick={handleShippingRate}>{getLabel('LBL_Get_Rates')}</button>
              </div>
              <div className="col-lg-4 col-sm-4 ship-btn-section combination-ship-layout">
                  <button type="button" className="btn btn-primary ship createWithPrintBtn">
                      <span className="ship-btn" onClick={() => checkMissingOpIds(true)}>
                        {getLabel('LBL_Create_+_Print_label')}
                      </span>
                      <span className="ship-btn-action"><FaAngleDown /></span>
                  </button>
                  <ul className="shipping_btn_list_js shipping_btn_action_list">
                      <li className='shipping_btn_action_item' onClick={() => checkMissingOpIds(false)}>{getLabel('LBL_Create_shipping_label')}</li>
                  </ul>
              </div>
            </div>
          </Modal.Body>
        </>
       {<Confirmation show={packageConfirmationPopup} setShow={setPackageConfirmationPopup} handleShipPackage={handleShipPackage} printShipLabel={printShipLabel}/>}
    </>
  )
}
