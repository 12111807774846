import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../context/CommonContext';
import { validationSchema } from '../../../schemas/trackingSchema';
import { useFormik } from "formik";
import { addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers';
import { toast } from 'react-toastify';
import { updateTrackInfo } from '../../../services/orders';
export default function UpdateTrackingInfo(props) {
    const { show, setShow, data, getOrderDetails, setLoadHistory, locations } = props
    const { getLabel, getShippingCompanies, shippingCompanies, shippingMethodObject, constants } = useContext(CommonContext);
    const [showMethodField, setShowMethodField] = useState(false)
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        getShippingCompanies();
        handleShippingMethodField(data.shiping_company_id, data.shiping_method)
        // eslint-disable-next-line
    }, [])

    const initialValues = data

    const { values, handleChange, handleSubmit, errors, touched } = useFormik(
        {
            initialValues,
            validationSchema: validationSchema,
            validateOnChange: false,

            onSubmit: async (values, { resetForm }) => {
                addProcessingLoader();
                try {
                    await updateTrackInfo(values);
                    resetForm();
                    getOrderDetails();
                    setLoadHistory(true);
                } catch (error) {
                    toast.error(error);
                }
                handleClose();
                removeProcessingLoader();
            },
        }
    );

    const addNewTrackingNumber = () => {
        handleChange({
            target: {
                name: 'tracking_number',
                value: [...values.tracking_number, ''],
            },
        });
    };

    const removeTrackingNumber = (indexToRemove) => {
        const updatedTrackingNumbers = [...values.tracking_number];
        updatedTrackingNumbers.splice(indexToRemove, 1);
    
        handleChange({
            target: {
                name: 'tracking_number',
                value: updatedTrackingNumbers,
            },
        });
    };

    const handleShippingMethodField = (value, method) => {
        if (parseInt(value) === constants.CANPAR_SHIPPING_COMPANY) {
            setShowMethodField(true);
            handleChange({
                target: {
                    name: 'shiping_method',
                    value: method ? method : 1,
                },
            });
            return;
        }
        handleChange({
            target: {
                name: 'shiping_method',
                value: 0,
            },
        });
        setShowMethodField(false);
    }
    return (
        <>
            <Modal show={show} centered onHide={handleClose} className='custom-modal tracking-info'>
                <Modal.Header closeButton >
                    <Modal.Title >{getLabel('LBL_Update_Package_Information')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row package-row mt-3">
                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <div className="input-select-group location">
                                    <span className="input-group-addon">
                                        <img src="/images/shipment-icons/location.svg" alt="location" />
                                    </span>
                                    <select className={`location-select-box form-select ${touched.location_id && errors.location_id ? ("border-error") : ''}`} name="location_id" value={values.location_id} onChange={handleChange}>
                                        {locations.map((loc, index) => (
                                            <option key={index} value={parseInt(loc.loc_id)}>{loc.loc_name}</option>
                                        ))}
                                    </select>
                                    <span className='zipcode-input-group-addon'>{locations.map((loc) => (loc.loc_id === parseInt(values.location_id) ? loc.loc_zip_code : ''))}</span>
                                    {touched.location_id && errors.location_id ? (<div className='company_error  error'>{errors.location_id}</div>) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <select className={`form-select ${touched.shiping_company_id && errors.shiping_company_id ? ("border-error") : ''}`} name='shiping_company_id' value={values.shiping_company_id} onChange={(e) => { handleChange(e); handleShippingMethodField(e.target.value, 0); }}>
                                    {shippingCompanies && shippingCompanies.map((shippingCompany, index) => (
                                        <option value={parseInt(shippingCompany.scompany_id)} key={index}>{shippingCompany.company_name}</option>
                                    ))}
                                </select>
                                {touched.shiping_company_id && errors.shiping_company_id ? (<div className='company_error  error'>{errors.shiping_company_id}</div>) : null}
                            </div>
                            {
                                showMethodField === true
                                    ?
                                    <div className="col col-lg-6 col-sm-6">
                                        <select className={`form-select`} name='shiping_method' value={values.shiping_method} onChange={handleChange}>
                                            {Object.entries(shippingMethodObject).map(([key, value]) => (
                                                <option key={key} value={key}>
                                                    {value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    :
                                    ''
                            }
                        </div>

                        <div className="row tracking_field ground">
                            {values.tracking_number.map((trackingNumber, index) => (
                                <React.Fragment key={index}>
                                    <div className="col col-lg-6 col-sm-6">
                                        <label className="tracking">{getLabel('LBL_Tracking_Number')}</label>
                                        <input type="text" className={`tracking-input ${touched.tracking_number && touched.tracking_number[index] && errors.tracking_number && errors.tracking_number[index] ? 'border-error': ''}`} name={`tracking_number`} value={trackingNumber} onChange={(e) => handleChange(`tracking_number[${index}]`)(e)} />
                                        {touched.tracking_number && touched.tracking_number[index] && errors.tracking_number && errors.tracking_number[index] ? (
                                            <div className='tracking_error error'>{errors.tracking_number[index]}</div>
                                        ) : null}
                                    </div>
                                    <div className="col col-lg-6 col-sm-6">
                                        {index === 0 ?
                                            <img className="img-fluid plus" src="/images/plus.svg" alt="plus-icon" onClick={addNewTrackingNumber} />
                                            :
                                            <img className="img-fluid plus" src="/images/remove.svg" alt="icon" onClick={() => removeTrackingNumber(index)}/>
                                        }
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="col-lg-11">
                            <div className="border-buton">
                                <ul>
                                    <li className={`dimension_input ${touched.opack_length && errors.opack_length ? 'border-error' : ''}`}>
                                        <label className='dimension'>{getLabel('LBL_Length')} (L)</label>
                                        <input type="text" name='opack_length' value={values.opack_length} onChange={handleChange} className={`numberWithDots  dimension_box ${touched.opack_length && errors.opack_length ? ("border-error") : ''}`} />
                                        <span>in</span>
                                        {touched.opack_length && errors.opack_length ? (<div className='list_error error'>{errors.opack_length}</div>) : null}
                                    </li>
                                    <li className='crossIcon'><img src="/images/close.svg" alt='icon' /></li>
                                    <li className={`dimension_input ${touched.opack_width && errors.opack_width ? 'border-error' : ''}`}>
                                        <label className='dimension'>{getLabel('LBL_Width')} (W)</label>
                                        <input type="text" name='opack_width' value={values.opack_width} onChange={handleChange} className={`numberWithDots  dimension_box ${touched.opack_width && errors.opack_width ? ("border-error") : ''}`} />
                                        <span>in</span>
                                        {touched.opack_width && errors.opack_width ? (<div className='list_error error'>{errors.opack_width}</div>) : null}
                                    </li>
                                    <li className='crossIcon'><img src="/images/close.svg" alt='icon' /></li>
                                    <li className={`dimension_input ${touched.opack_height && errors.opack_height ? 'border-error' : ''}`}>
                                        <label className='dimension'>{getLabel('LBL_Height')} (H)</label>
                                        <input type="text" name='opack_height' value={values.opack_height} onChange={handleChange} className={`numberWithDots  dimension_box ${touched.opack_height && errors.opack_height ? ("border-error") : ''}`} />
                                        <span>in</span>
                                        {touched.opack_height && errors.opack_height ? (<div className='list_error error'>{errors.opack_height}</div>) : null}
                                    </li>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <li className={`dimension_input ${touched.opack_weight && errors.opack_weight ? 'border-error' : ''}`}>
                                        <label className='dimension weight'>{getLabel('LBL_Weight')}</label>
                                        <input type="text" name='opack_weight' value={values.opack_weight} onChange={handleChange} className={`numberWithDots  dimension_box ${touched.opack_weight && errors.opack_weight ? ("border-error") : ''}`} />
                                        <span>lbs</span>
                                        {touched.opack_weight && errors.opack_weight ? (<div className='list_error error'>{errors.opack_weight}</div>) : null}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-cancel' onClick={handleClose}>{getLabel('LBL_Cancel')}</button>
                    <button className='btn btn-save' onClick={handleSubmit} type="submit">{getLabel('LBL_Save')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
