import React, { useEffect, useState } from 'react'
import { addNotes, getHistory } from '../../../services/orders/index'
import { toast } from 'react-toastify';
import { dateConverter, dateComparison, getInitialCharOfName, getLoggedUser, addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers'
import { noteSchema } from "../../../schemas/noteSchema";
import { useFormik } from "formik";
export default function History(props) {
    const { id, constants, getLabel, setLoadData, loadData } = props
    const [history, setHistory] = useState([]);
    const [groupedHistoryData, setGroupedHistoryData] = useState([]);

    useEffect(() => {
        getOrderHistory()
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (loadData) {
            getOrderHistory()
        }
        // eslint-disable-next-line
    }, [loadData])

    useEffect(() => {
        const groupedData = {};
        history.forEach(item => {
            const dateKey = (dateConverter(item.ohistory_added_on, 'dateYMD').substring(0, 10));
            if (!groupedData[dateKey]) {
                groupedData[dateKey] = [];
            }
            groupedData[dateKey].push(item);
        });

        for (const dateKey in groupedData) {
            if (groupedData.hasOwnProperty(dateKey)) {
                groupedData[dateKey].sort((a, b) => {
                    return new Date(b.ohistory_id) - new Date(a.ohistory_id);
                });
            }
        }

        const groupedDataArray = Object.entries(groupedData).map(([date, items]) => ({
            [date]: items
        }));

        groupedDataArray.sort((a, b) => {
            const dateA = Object.keys(a)[0];
            const dateB = Object.keys(b)[0];
            return new Date(dateB) - new Date(dateA);
        });

        setGroupedHistoryData(groupedDataArray);
    }, [history]);

    const getOrderHistory = async () => {
        try {
            const res = await getHistory({ order_id: id, is_pickup_order: constants.YES });
            setHistory(res.data);
            setLoadData(false);
        } catch (error) {
            toast.error(error);
        }
    }

    const initialValues = {
        order_id: id,
        order_comment: "",
    };

    const { values, handleChange, handleSubmit, errors, touched } = useFormik(
        {
            initialValues,
            validationSchema: noteSchema,
            validateOnChange: false,

            onSubmit: async (values, { resetForm }) => {
                addProcessingLoader();
                try {
                    const res = await addNotes(values);
                    if (res === true) {
                        getOrderHistory()
                        resetForm();
                        removeProcessingLoader();
                        return;
                    }
                    toast.error(res);
                } catch (error) {
                    toast.error(error);
                }
                removeProcessingLoader();
            },
        });

    return (
        <>
            <div className="gap"></div>
            <div className="audit_heading">{getLabel('LBL_Audit_log')}</div>
            <div className="gap"></div>
            <div className="gap"></div>
            <form onSubmit={handleSubmit} >
                <div className="row">
                    <div className="col-md-1">
                        <div className="name-circle">{getLoggedUser().admin_name ? getInitialCharOfName(getLoggedUser().admin_name) : ''}</div>
                    </div>
                    <div className="col-md-9">
                        <div className="input">
                            <input type="text" placeholder={getLabel('LBL_Note_to_team._Only_you_and_other_team_members_can_see_this_note.')} autoComplete="off" name="order_comment" value={values.order_comment} onChange={handleChange} className={`form-control ${touched.order_comment && errors.order_comment ? ("border-error") : null}`} />
                            {touched.order_comment && errors.order_comment ? (<span className='error'>{errors.order_comment}</span>) : null}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <button type="submit" className="btn btn-primary send-button">{getLabel('LBL_Send')}</button>
                    </div>
                </div>
            </form>
            <div className="container-timeline">
                {groupedHistoryData.length > 0 && groupedHistoryData.map((data, index) => {
                    const date = Object.keys(data)[0];
                    return <div key={index}>
                        <div className="line-border">
                            <span>
                                <hr />
                            </span>
                            <div className="today-button">{dateComparison(date)}</div>
                        </div>

                        {data[date].map((item, index) => {
                            const pickupSkus = item.pickup_sku;
                            return <div key={index}>
                                <div className="timeline-block timeline-block-right">
                                    <div className="marker">
                                        <img className="img-fluid" src={`/images/status/${item.ohistory_orderstatus_id ? item.ohistory_orderstatus_id : 'default'}.svg`} alt='status' />
                                    </div>
                                    <div className="timeline-content">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="name">{item.ohistory_added_by}</span>
                                                <span className="time">{dateConverter(item.ohistory_added_on, 'time')}</span>
                                            </div>
                                            <div className="col-md-8">
                                                <p className="full-filed whiteSpace">
                                                    {item.ohistory_comments ? item.ohistory_comments : getLabel('LBL_Comment_is_not_Available')}
                                                </p>
                                                {item.ohistory_orderstatus_id !== constants.ORDER_PLACED && item.ohistory_ship_details
                                                    ?
                                                    JSON.parse(item.ohistory_ship_details).map((shipDetail, index) => {
                                                        if ((item.ohistory_orderstatus_id === constants.ORDER_RETURN_REQUEST || item.ohistory_orderstatus_id === constants.ORDER_CANCELLATION_PENDING) && item.ohistory_op_id !== shipDetail.op_id) {
                                                            return null;
                                                        }
                                                        let requestedQty = '';

                                                        if (item.ohistory_orderstatus_id === constants.ORDER_PICKED_UP || item.ohistory_orderstatus_id === constants.ORDER_PICKUP_READY) {
                                                            const total_cancel_qty = (shipDetail.total_cancel_qty ? shipDetail.total_cancel_qty : 0);
                                                            requestedQty = shipDetail.qty - total_cancel_qty;
                                                        }

                                                        if (shipDetail.cancel_qty !== undefined && shipDetail.cancel_qty !== 0) {
                                                            requestedQty = shipDetail.cancel_qty;
                                                        } else if (shipDetail.refund_qty !== undefined && shipDetail.refund_qty !== 0) {
                                                            requestedQty = shipDetail.refund_qty;
                                                        }

                                                        if (!pickupSkus.includes(shipDetail.sku)) {
                                                            return null;
                                                        }
                                                        return <div key={index}>
                                                            <p className='audit-comment-msg'>
                                                                {requestedQty && shipDetail.sku ? requestedQty + ' x ' + shipDetail.sku : ''}
                                                            </p>
                                                            <div className='audit-comment-msg'>
                                                                {shipDetail.comment ? (
                                                                    <div className='extra-details'>
                                                                        <div className="head">{getLabel('LBL_Comment:')}&nbsp;</div>
                                                                        {shipDetail.comment}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className='audit-comment-msg'>
                                                                {shipDetail.orrmsg_tracking_code ? (
                                                                    <div className='extra-details'>
                                                                        <div className="head">{getLabel('LBL_Tracking_Code:')}&nbsp;</div>
                                                                        {shipDetail.orrmsg_tracking_code}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    })
                                                    :
                                                    ''
                                                }
                                                {
                                                    item.ohistory_orderstatus_id === constants.ORDER_PLACED && Object.keys(item.products).length > 0 &&
                                                    Object.values(item.products).map((prod) => {
                                                        if (prod.op_is_pickup === 0) {
                                                            return null;
                                                        }
                                                        return <div key={prod.op_id}>{prod.op_qty}x {prod.op_selprod_sku}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                })}
            </div>
        </>
    )
}
