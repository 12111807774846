import React,{useContext } from 'react'
import CommonContext from '../../../context/CommonContext'
import {displayMoneyFormat,dateConverter, UniquePackagesArray} from '../../../helpers/commonHelpers'
import logo from '../../../assets/images/sap-full-logo.png'
export default function PrintPacking(props) {
    const {data} = props
    const {shippingMethodObject,channelObject,shippingCompanyObject,getLabel, constants} = useContext(CommonContext);
    let subtotalAmount = 0;
    let totalShipping = 0;
    let totalTax = 0;
    let orderDiscount = 0;
    let sellerDiscount = 0;
    let discountCode = '';
    let uniquePackagesArrays = new Map();
    data.products.forEach((product) => {
        if (product.shipdetails) {
          product.shipdetails.forEach((packData) => {
            uniquePackagesArrays = UniquePackagesArray(packData, uniquePackagesArrays);
          });
        }
    });
    var mergedUniquePackagesArrays = uniquePackagesArrays;
    var additionalpackMap = new Map(data.additionalpacks.map(item => [item.opack_id, item]));
    mergedUniquePackagesArrays = new Map([...uniquePackagesArrays, ...additionalpackMap]);

    return (
        <>
            <div id="body" className='print-item'>
                <div className="page space">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <img src={logo} alt="logo" className="top-logo" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='print_order_id_css'>
                                    <p>
                                        <span className='print_order_invoice_clr_css'>{getLabel('LBL_Order_#')} </span>{constants.CHANNEL_WOOCOMMERCE === data.order_channel && data.order_channel_oid ? data.order_channel_oid : data.order_id}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <table className="top-table-css">
                            <tbody>
                                <tr>
                                    <th>{getLabel('LBL_Platform')}</th>
                                    <td>{channelObject[data.order_channel]}</td>
                                </tr>
                                <tr>
                                    <th>{getLabel('LBL_Order_date')}</th>
                                    <td>{dateConverter(data.order_date_added, 'dateYMD')} (EST)</td>
                                </tr>

                                { Array.from(mergedUniquePackagesArrays.values()).length > 0 && Array.from(mergedUniquePackagesArrays.values()).map((item, index) => {
                                    return  <React.Fragment key={index}>
                                                { (item.opack_id) &&
                                                    <>
                                                        <tr>
                                                            <th>{getLabel('LBL_Delivery')}</th>
                                                            <td>{
                                                                    item.opack_id
                                                                    &&
                                                                    (`${shippingMethodObject[item.opack_service_type] ? shippingMethodObject[item.opack_service_type] : shippingCompanyObject[item.opack_shipped_by]} (${dateConverter(item.opack_delivery_date, 'monthDay')})`)
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>{getLabel('LBL_Shipping')}</th>
                                                            <td>
                                                                {
                                                                    item.opack_id 
                                                                    &&
                                                                    (`${shippingCompanyObject[item.opack_shipped_by]} - ${item.opack_tcode && Object.values(JSON.parse(item.opack_tcode)).map((trackCode) => (trackCode))}`)
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                            </React.Fragment>
                                })}

                            </tbody>
                        </table>

                        <div className='shipping_section_css'>
                            <h6>
                                {getLabel('LBL_Ship_to')}
                            </h6>
                            <span>
                                {data.address.oua_name ?
                                    (
                                        <>
                                            {data.address.oua_name} <br />
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_company_name ?
                                    (
                                        <>
                                            {data.address.oua_company_name} <br />
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_address1 ?
                                    (
                                        <>
                                            {data.address.oua_address1} <br />
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_address2 ?
                                    (
                                        <>
                                            {data.address.oua_address2} <br />
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_city ?
                                    (
                                        <>
                                            {data.address.oua_city},&nbsp;
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_state ?
                                    (
                                        <>
                                            {data.address.oua_state}&nbsp;
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_zip ?
                                    (
                                        <>
                                            {data.address.oua_zip} <br />
                                        </>
                                    ) : ''
                                }

                                {data.address.oua_country ?
                                    (
                                        <>
                                            {data.address.oua_country} <br /><br />
                                        </>
                                    ) : ''
                                }
                                {data.address.oua_phone ?
                                    (
                                        <>
                                            {data.address.oua_phone} <br />
                                        </>
                                    ) : ''
                                }
                                {data.buyer.obuyer_email ?
                                    (
                                        <>
                                            {data.buyer.obuyer_email} <br />
                                        </>
                                    ) : ''
                                }
                            </span>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='line'></div>
                                <table className="table detail_page">
                                    <thead>
                                        <tr>
                                            <th>{getLabel('LBL_Product')}</th>
                                            <th></th>
                                            <th width="15%">{getLabel('LBL_Qty')}</th>
                                            <th width="15%">{getLabel('LBL_Unit_Price')}</th>
                                            <th style={{ textAlign: 'center' }}>{getLabel('LBL_Subtotal')}</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {data.products.map((product, index) => {
                                            subtotalAmount += product.op_qty * product.op_unit_price;
                                            totalShipping += product.op_actual_shipping_charges;
                                            totalTax += parseFloat(data.order_channel > 0 ? product.op_total_tax:product.op_deducted_tax);
                                            orderDiscount += product.op_discount_amt;
                                            sellerDiscount += parseFloat(product.op_total_sdiscount);
                                            discountCode = product.op_sdiscount_title !== '' ? product.op_sdiscount_title : discountCode;
                                            return <React.Fragment key={index}>
                                                <tr className='product_row'>
                                                    <td>
                                                        <div className='item__pic'>
                                                            <img src={product.images ? process.env.REACT_APP_IMAGE_BASE_URL+product.images[0].afile_physical_path : '/images/no_image.jpg'} alt="productImage" />
                                                        </div>
                                                    </td>
                                                    <td className='item__description'>
                                                        <div className='product_name'>{`${product.oProLang && product.oProLang.op_brand_name} ${product.oProLang && product.oProLang.op_product_name}`}</div>
                                                        <div >{product.op_selprod_compatibility_text && product.op_selprod_compatibility_text}</div>
                                                        <div className='variant_css'>{product.op_selprod_code &&  product.op_selprod_code}</div>
                                                        <div className='variant_css'>{product.op_selprod_sku}</div>
                                                    </td>
                                                    <td>{product.op_qty - product.cancel_qty - product.op_refund_qty }</td>
                                                    <td>
                                                        {displayMoneyFormat(product.op_unit_price)}
                                                        {product.op_sdiscount_per_unit > 0 && 
                                                            <>
                                                                <br /> <strike>{displayMoneyFormat(parseFloat(product.op_unit_price) + parseFloat(product.op_sdiscount_per_unit))}</strike>
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>{displayMoneyFormat(product.op_qty * product.op_unit_price)}</td>
                                                </tr>
                                                <tr className='line'>
                                                    <td colSpan={5}></td>
                                                </tr>

                                            </React.Fragment>
                                        })}

                                        <tr className="subtotal price">
                                            <td colSpan={2}></td>
                                            <td colSpan={2}> {getLabel('LBL_Subtotal')}</td>
                                            <td style={{ textAlign: 'right' }}>{displayMoneyFormat(parseFloat(subtotalAmount) + parseFloat(sellerDiscount))}</td>
                                        </tr>

                                        {
                                            sellerDiscount > 0 &&
                                            <tr className="price">
                                                <td colSpan={2}></td>
                                                <td colSpan={2}>{`${getLabel('LBL_Discount')} (${discountCode})`}</td>
                                                <td style={{ textAlign: 'right' }}>-{displayMoneyFormat(sellerDiscount)}</td>
                                            </tr>
                                        }
                                        
                                        <tr className="price">
                                            <td colSpan={2}></td>
                                            <td colSpan={2}> {getLabel('LBL_Shipping')}</td>
                                            <td style={{ textAlign: 'right' }}>{displayMoneyFormat(totalShipping)}</td>
                                        </tr>
                                    
                                        {orderDiscount > 0 && 
                                            <tr className="price">
                                                <td colSpan={2}></td>
                                                <td colSpan={2}> {getLabel('LBL_Discounts')}</td>
                                                <td style={{ textAlign: 'right' }}>{displayMoneyFormat(orderDiscount)}</td>
                                            </tr>
                                        }
                                        {totalTax > 0 && 
                                            <tr className="price">
                                                <td colSpan={2}></td>
                                                <td colSpan={2}>{getLabel('LBL_Tax')}</td>
                                                <td style={{ textAlign: 'right' }}>{displayMoneyFormat(totalTax)}</td>
                                            </tr>
                                        }
                                        <tr className="price total">
                                            <td colSpan={2}></td>
                                            <td colSpan={2}> {getLabel('LBL_Order_Total')}</td>
                                            <td style={{ textAlign: 'right' }}>{displayMoneyFormat(subtotalAmount+totalShipping+totalTax+orderDiscount)}</td>
                                        </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> 
    )
}
