import React, { useEffect,useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import {thirtPartyWebsByShopId,deleteWooCommerceCredential,updateWoocommerceCredential} from '../../../../services/shops/index'
import { toast } from 'react-toastify';
import Listing from './Listing'
import { FaCirclePlus } from "react-icons/fa6";
import Form from './Form';
import LeftArrow from '../../../../assets/images/leftArrow.svg'
import { addProcessingLoader, removeProcessingLoader } from '../../../../helpers/commonHelpers';
export default function Index() {
    const { id,name } = useParams();
    const search_object = {shop_id: parseInt(id)};
    const [data,setData] = useState([])
    const [shopInfo,setShopInfo] = useState({})
    const [isLoading,setIsLoading] = useState(true) 
    const [thirdPartyData,setThirdPartyData] = useState({})

    useEffect(() => {
        getThirdPartyWebsites();
        // eslint-disable-next-line
    },[])
    
    const getThirdPartyWebsites = async() => {
        try {
            const res = await thirtPartyWebsByShopId(search_object);
            setData(res.data.rows)
            setShopInfo(res.data.shop)
            setIsLoading(false)
        } catch (error) {
            toast.error(error);
            window.history.back();
        }
    } 

    const [show, setShow] = useState(false);
    const handleWooCommerceFormPage = (data) => {
        let request = {
            "user_id":parseInt(shopInfo.shop_user_id),
            "shop_id":parseInt(id),
            "channel_id":parseInt(1), 
            "consumer_key":"",
            "consumer_secret":"",
            "shop_url":"",
            "active":1,
            "scredential_id":"",
            "is_international_shop" : 1
        } ;
        if(data){
            const newData = JSON.parse(data.credential_web_keys);
            request = {
                "user_id":parseInt(data.shop_user_id),
                "shop_id":parseInt(data.shop_id),
                "channel_id":parseInt(data.scredential_channel_id), 
                "consumer_key":newData.consumerKey,
                "consumer_secret":newData.consumerSecret,
                "shop_url":data.credential_shop_url,
                "active":data.credential_active,
                "scredential_id":data.scredential_id,
                "is_international_shop" : data.credential_is_international,
            } 
        }
        setThirdPartyData(request)
        setShow(true)
    }

    const handleDelete = async(id) => {
        const data = {
            "scredential_id":id
        }
        addProcessingLoader();
        try {
            const res = await deleteWooCommerceCredential(data);
            toast.success(res.message);
            getThirdPartyWebsites();
        } catch (error) {
            toast.error(error);
        }
        removeProcessingLoader();
    }

    const handleToggleStatus = async(data) => {
        const newData = JSON.parse(data.credential_web_keys);
        const request = {
            "user_id":parseInt(data.shop_user_id),
            "shop_id":parseInt(data.shop_id),
            "channel_id":parseInt(data.scredential_channel_id), 
            "consumer_key":newData.consumerKey,
            "consumer_secret":newData.consumerSecret,
            "shop_url":data.credential_shop_url,
            "active":data.credential_active === 1 ? 0 : 1,
            "scredential_id":data.scredential_id
        } 
        addProcessingLoader();
        try {
            const res = await updateWoocommerceCredential(request);
            toast.success(res.message);
            getThirdPartyWebsites();
        } catch (error) {
            toast.error(error);
        }
        removeProcessingLoader();
    }
    return (
        <>
            <div id="body">
                <div className="page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 space">
                                <div className="page__title">
                                    <div className="col-md-6 back-button-div">
                                        <Link to={`${process.env.REACT_APP_BASE_URL}/shops`} className='back-btn margin-right'>
                                            <img src={LeftArrow} alt="left arrow" className='no-print' />
                                        </Link>
                                        <h5>{name}</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <span title='Add WooCommerce Credential' onClick={() => handleWooCommerceFormPage(null)}><FaCirclePlus  style={{ width: 24, height: 24 }} /></span>
                                    </div>
                                </div>
                                <div className='listingContent'>
                                    <section className="section">
                                        <div className="sectionbody">
                                            <div className="tablewrap">
                                                <div id="third_party_listing">
                                                    <Listing  data={data} isLoading={isLoading} handleFormPage={handleWooCommerceFormPage} handleDelete={handleDelete} handleToggleStatus={handleToggleStatus}/>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          {show && <Form show={show} setShow={setShow} data={thirdPartyData} getThirdPartyWebsites={getThirdPartyWebsites}/>}
        </>
    )
}
