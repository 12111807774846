import React, { useContext } from 'react'
import sapFullLogo from '../../../assets/images/sap-full-logo.png'
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom'
import { getLoggedUser, getInitialCharOfName } from '../../../helpers/commonHelpers'
import CommonContext from '../../../context/CommonContext';
export default function Header() {
    const { getLabel } = useContext(CommonContext)
    return (
        <>
            <header id="header" className="no-print">
                <div className="headerwrap">
                    <div className="one_third_grid">
                        <Link to={`${process.env.REACT_APP_BASE_URL}/orders`} className="menutrigger">
                            <img data-ratio="1:1" src={sapFullLogo} alt="logo" className='logo-is-expanded' />
                        </Link>
                        <div className="js-hamburger hamburger-toggle is-opened"><span className="bar-top" /><span className="bar-mid" /><span className="bar-bot" /></div>

                    </div>
                    <div className="one_third_grid ">
                        <div className='info-section'>
                            {getLoggedUser().admin_name ? getLoggedUser().admin_name : ''}
                            <span className='user-info'>{getLoggedUser().admin_name ? getInitialCharOfName(getLoggedUser().admin_name) : ''}</span>
                            <div className='user-dropdown-toggle'>
                                <Link to={`${process.env.REACT_APP_BASE_URL}/logout`} className='dropdown-link' title={getLabel('LBL_Sign_out')}>{getLabel('LBL_Sign_out')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
        </>
    )
}
