import * as Yup from "yup";

export const validationSchema = Yup.object({
    location_id: Yup.number().required("Location is required").test('non-zero-location-id', 'Location is required', (value) => value !== 0),
    tracking_number: Yup.array().of(Yup.string().required('Tracking Number is required')).required('Tracking Number is required'),
    shiping_company_id: Yup.number().required('Shipping company is required').test('non-zero-shiping-company-id', 'Shipping company is required', (value) => value !== 0),
    opack_weight: Yup.string().required("Weight is required"),
    opack_height: Yup.string().required("Height is required"),
    opack_length: Yup.string().required("Length is required"),
    opack_width: Yup.string().required("Width is required"),
});