import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    setting: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().required('Value is required'),
        })
    ),
});

  
  
  
  
  
  