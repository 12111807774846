import React, { useContext } from 'react'
import CommonContext from '../../../context/CommonContext'
export default function NoRecord(props) {
  const {column} = props
  const {getLabel} = useContext(CommonContext)
  return (
    <>
      <tr>
        <td colSpan={column?column:10} style={{textAlign: 'center'}}>{getLabel('LBL_No_data_found')}</td>
      </tr>
    </>
  )
}
