import {myAxios,headers} from '../../helpers/commonHelpers';

export const authentication = async (data) => {
  const response = await myAxios.post('/user/auth', data);
  return response;
}
export const logout = async () => {
  const response = await myAxios.post('/user/logout',null,{headers: headers()});
  return response;
}


export const doLogin = (res,next) => {
  localStorage.setItem('userData',JSON.stringify(res.data.data));
  localStorage.setItem('token',JSON.stringify(res.data.data.token));
  let actionTime = new Date().getTime();
  localStorage.setItem('tokenTime',JSON.stringify(actionTime));
  next();
}

export const doLogout = (next) => {
  localStorage.removeItem('userData');
  localStorage.removeItem('token');
  localStorage.removeItem('tokenTime');
  next();
}






