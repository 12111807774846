import React, { useState,useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import {addressFormatter} from '../../../helpers/commonHelpers'
import '../css/locationModal.css'
import $ from 'jquery'
import CommonContext from '../../../context/CommonContext';
export default function LocationModal(props) {
    const {show ,setShow, orderIds,locations,updateOrderLocation} = props
    const {getLabel} = useContext(CommonContext);
    const [locationId,setLocationId] = useState('');
    const handleClose = () => setShow(false);
    const handleUpdateLocation = (id) => {
      setLocationId(id)
    }   

    const handleSubmit = () => {
      updateOrderLocation(locationId,[],orderIds)
      $(".selectAll-js").prop("checked", false);
      $(".selectItem--js").prop("checked", false);
      $(".orderIdBased").addClass("disabled");
      handleClose()
    }   

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='location'>
        <Modal.Header closeButton >
          <Modal.Title >{getLabel('LBL_Change_shipping_location')}</Modal.Title>
        </Modal.Header>
        <div className='text-content'>{getLabel('LBL_Select_which_location_you\'d_like_to_ship_from')}</div>
        <Modal.Body>
          {locations.map((location)=>( 
            <div key={location.loc_id}>
                <input type="radio" value={location.loc_id} name="location_id"  onChange={(e)=>handleUpdateLocation(e.target.value,orderIds)} />
                <span>{location.loc_name}</span>
                <p className='loc_address'>{addressFormatter(location)}</p>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-save' onClick={handleSubmit}>{getLabel('LBL_Save')}</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
