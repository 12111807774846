import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../context/CommonContext';
import { validationSchema } from '../../../schemas/shippingSchema';
import { useFormik } from "formik";
import { addProcessingLoader, removeProcessingLoader, removeHyphens } from '../../../helpers/commonHelpers';
import { toast } from 'react-toastify';
import { updateAddress } from '../../../services/orders';
export default function UpdateTrackingInfo(props) {
    const { show, setShow, data, setAddressData, pageReload, isPackage } = props
    const { getLabel, states, getStates, setStates, addressSearch } = useContext(CommonContext);
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        setStates(data.states)
        // eslint-disable-next-line
    }, [])

    const initialValues = {
        "oua_order_id": data.address.oua_order_id,
        "oua_type": data.address.oua_type,
        "oua_name": data.address.oua_name,
        "oua_company_name": data.address.oua_company_name,
        "oua_address1": data.address.oua_address1,
        "oua_address2": data.address.oua_address2,
        "oua_city": data.address.oua_city,
        "oua_state": data.address.oua_state,
        "oua_country": data.address.oua_country,
        "oua_country_code": data.address.oua_country_code,
        "oua_country_id": (() => {
            const matchedCountry = data.countries.find(country => country.country_code === data.address.oua_country_code);
            return matchedCountry ? matchedCountry.country_id : '';
        })(),
        "oua_phone": removeHyphens(data.address.oua_phone),
        "oua_zip": data.address.oua_zip
    }

    const { values, setValues, handleChange, handleSubmit, errors, touched } = useFormik(
        {
            initialValues,
            validationSchema: validationSchema,
            validateOnChange: false,

            onSubmit: async (values, { resetForm }) => {
                addProcessingLoader();
                try {
                    await updateAddress(values);
                    resetForm();
                    if(isPackage === true){
                        addressSearch({'order_id': data.address.oua_order_id});
                    }else{
                        setAddressData({});
                        pageReload();
                    }
                } catch (error) {
                    toast.error(error);
                }
                handleClose();
                removeProcessingLoader();
            },
        }
    );

    const handleCountryChange = (countryId) => {
        const updatedValues = {
            ...values,
            oua_country_code: (() => {
                const matchedCountry = data.countries.find(country => parseInt(country.country_id) === parseInt(countryId));
                return matchedCountry ? matchedCountry.country_code : '';
            })(),
            oua_country: (() => {
                const matchedCountry = data.countries.find(country => parseInt(country.country_id) === parseInt(countryId));
                return matchedCountry ? matchedCountry.country_name : '';
            })(),
            oua_country_id: parseInt(countryId),
            oua_state: ''
        };
        setValues(updatedValues);
        getStates({'country_id' : countryId});
    }

    return (
        <>
            <Modal show={show} centered onHide={handleClose} className='custom-modal tracking-info'>
                <Modal.Header closeButton className='closeBtnCss'>
                    <Modal.Title >{getLabel('LBL_Update_Shipping_Information')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row package-row mt-3">
                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control ${touched.oua_name && errors.oua_name ? 'border-error' : ''}`} autoComplete="off" placeholder='Name' name='oua_name' value={values.oua_name} onChange={handleChange} />
                                {touched.oua_name && errors.oua_name ? (
                                    <div className='reference_error error'>{errors.oua_name}</div>
                                ) : null}
                            </div>
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control`} autoComplete="off" placeholder='Company Name' name='oua_company_name' value={values.oua_company_name} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control ${touched.oua_address1 && errors.oua_address1 ? 'border-error' : ''}`} autoComplete="off" placeholder='Address' name='oua_address1' value={values.oua_address1} onChange={handleChange} />
                                {touched.oua_address1 && errors.oua_address1 ? (
                                    <div className='reference_error error'>{errors.oua_address1}</div>
                                ) : null}
                            </div>
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control`} autoComplete="off" placeholder='Apt, suite, floor number (optional)' name='oua_address2' value={values.oua_address2} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control ${touched.oua_city && errors.oua_city ? 'border-error' : ''}`} autoComplete="off" placeholder='City' name='oua_city' value={values.oua_city} onChange={handleChange} />
                                {touched.oua_city && errors.oua_city ? (
                                    <div className='reference_error error'>{errors.oua_city}</div>
                                ) : null}
                            </div>
                            <div className="col col-lg-6 col-sm-6">
                                <select className={`form-select ${touched.oua_state && errors.oua_state ? 'border-error' : ''}`} name="oua_state" value={values.oua_state} onChange={handleChange}>
                                    <option>{getLabel('LBL_Select_Province')}</option>
                                    {states && states.map((states, index) => (
                                        <option value={states.state_name} key={index}>{states.state_name}</option>
                                    ))}
                                </select>
                                {touched.oua_state && errors.oua_state ? (<div className='company_error  error'>{errors.oua_state}</div>) : null}
                            </div>
                        </div>

                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control ${touched.oua_zip && errors.oua_zip ? 'border-error' : ''}`} autoComplete="off" placeholder='Postal Code' name='oua_zip' value={values.oua_zip} onChange={handleChange} />
                                {touched.oua_zip && errors.oua_zip ? (
                                    <div className='reference_error error'>{errors.oua_zip}</div>
                                ) : null}
                            </div>
                            <div className="col col-lg-6 col-sm-6">
                                <select className={`form-select ${touched.oua_country_id && errors.oua_country_id ? 'border-error' : ''}`} name="oua_country_id" value={values.oua_country_id} onChange={(e) => {handleCountryChange(e.target.value) }}>
                                    <option value={0}>{getLabel('LBL_Select_Country')}</option>
                                    {data && data.countries && data.countries.map((country, index) => (
                                        <option value={country.country_id} key={index}>{country.country_name}</option>
                                    ))}
                                </select>
                                {touched.oua_country_id && errors.oua_country_id ? (<div className='company_error  error'>{errors.oua_country_id}</div>) : null}
                            </div>
                        </div>

                        <div className="row ground">
                            <div className="col col-lg-6 col-sm-6">
                                <input type="text" className={`reference-form-control numberInput ${touched.oua_phone && errors.oua_phone ? 'border-error' : ''}`} autoComplete="off" placeholder='Phone number' name='oua_phone' value={values.oua_phone} onChange={handleChange} />
                                {touched.oua_phone && errors.oua_phone ? (
                                    <div className='reference_error error'>{errors.oua_phone}</div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-cancel' onClick={handleClose}>{getLabel('LBL_Cancel')}</button>
                    <button className='btn btn-save' onClick={handleSubmit} type="submit">{getLabel('LBL_Save')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
