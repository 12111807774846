import React ,{useEffect} from 'react'
import { useFormik } from "formik";
import { loginSchema } from "../../../schemas/loginSchema";
import '../css/Login.css';
import {authentication , doLogin} from '../../../services/auth/authentication';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isLoggedIn } from "../../../helpers/commonHelpers";
import logo from '../../../assets/images/sap-favicon.png';
import $ from 'jquery'
import { v4 as uuidv4 } from 'uuid';
export default function Login() {
    useEffect(()=>{
        if(isLoggedIn() === true){
            navigate(`${process.env.REACT_APP_BASE_URL}/orders`);
            return;
        }
        // eslint-disable-next-line
    },[]);
  
    const navigate = useNavigate();
    const initialValues = {
        username: "",
        password: "",
        device_id: uuidv4(),
    };

    const { values, handleChange, handleSubmit, errors, touched } = useFormik(
    {
      initialValues,
      validationSchema: loginSchema,
      validateOnChange: true,

      onSubmit: (values) => {
        authentication(values).then((res)=>{
            doLogin(res,()=>{
                $('body').addClass('inner_page');
                navigate(`${process.env.REACT_APP_BASE_URL}/orders`);
            })
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                toast.error(error.message);
            }else{
                toast.error(error.response.data.message);
            }
        })
      }
    });

  return (
      <> 
            <div className="page-container" />
          <div className="page--front login">
              <div className="page__cell">
                  <div className="container container-fluid container--narrow">
                      <div className="box box--white">
                          <figure className="logo">
                              <img data-ratio="1:1" title="Strictly Auto Parts" src={logo} alt="Strictly Auto Parts" width="50px" />
                          </figure>
                          <div className="box__centered box__centered--form">
                              <form name="frmLogin" method="post" id="adminLoginForm" className="web_form" onSubmit={handleSubmit}>
                                  <div className="row">
                                      <div className="col-md-12">
                                          <div className="field-set">
                                              <div className="field_cover field_cover--user">
                                                  <input title="Username" autoComplete="off" placeholder="Username" data-field-caption="Username" type="text" name="username" value={values.username} onChange={handleChange} className={touched.username && errors.username ? ("error") : null} />
                                                  {touched.username && errors.username ? (<ul className="errorlist erlist_username"><li><span>{errors.username}</span></li></ul>) : null}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-12">
                                          <div className="field-set">
                                              <div className="field_cover field_cover--lock">
                                                  <input title="Password" autoComplete="off" placeholder="Password" type="password" name="password" value={values.password} onChange={handleChange} className={touched.password && errors.password ? ("error") : null} />
                                                  {touched.password && errors.password ? (<ul className="errorlist erlist_username"><li><span>{errors.password}</span></li></ul>) : null}
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div className="row">
                                      <div className="col-md-12">
                                            <div className="field-set">
                                              <input type="submit" name="btn_submit" value="Sign In" /> 
                                              <input type="hidden" value={values.device_id} name='device_id' />
                                            </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
      </>
  )
}
