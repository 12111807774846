import React,{useContext, useState} from 'react'
import Loader from '../../../Skeleton/Loader'
import NoRecordsFound from '../../../Common/views/NoRecord'
import { Link } from 'react-router-dom';
import CommonContext from '../../../../context/CommonContext';
import RemoveChannel from './RemoveChannel';
export default function Listing(props) {
    const {data,isLoading,handleFormPage,handleDelete,handleToggleStatus} = props
    const {channelObject,getLabel} = useContext(CommonContext);
    const condition = data && data.length > 0 ;
    const [show, setShow] = useState(false);
    const [id, setId] = useState('');

    const openModal = (id) =>{
        setId(id)
        setShow(true)
    }
    return (
        <>  
            <form id="third_party_listing_form">
                <table width="100%" className="table table-responsive table--hovered">
                    <thead>
                        <tr>
                            <th width="30%">{getLabel('LBL_Sales_Channel')}</th>
                            <th width="30%">{getLabel('LBL_Shop_Url')}</th>
                            <th width="20%">{getLabel('LBL_Status')} </th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading
                            ?
                            <Loader columns={4} />
                            :
                            condition ? data.map((item, index) => (
                                <tr key={index}>
                                    <td>{channelObject[item.scredential_channel_id]}</td>
                                    <td><Link to={item.credential_shop_url} target='_blank'>{item.credential_shop_url}</Link></td>
                                    <td>
                                        <label className="statustab">
                                            <input checked={item.credential_active === 1} type="checkbox" name='status' className="switch-labels" onChange={()=>handleToggleStatus(item)} />
                                            <i className="switch-handles"></i>
                                        </label>
                                    </td>
                                    <td>
                                        <ul className="list-actions actions--centered">
                                            <li className="droplink">
                                                <Link className="button">
                                                    <i className="fa fa-ellipsis-h icon"></i>
                                                </Link>
                                                <div className="dropwrap">
                                                    <ul className="linksvertical">
                                                        <li>
                                                            <Link onClick={() => handleFormPage(item)}>{getLabel('LBL_Edit')}</Link>
                                                        </li>
                                                        <li>
                                                            <Link onClick={() => openModal(item.scredential_id)}>{getLabel('LBL_Delete_Channel')}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            )) : <NoRecordsFound column={4} />
                        }
                    </tbody>
                </table>
            </form>
            <RemoveChannel show={show} setShow={setShow} handleDelete={handleDelete} id={id} setId={setId}/>
        </>
    )
}
