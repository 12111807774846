import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CommonContext from '../../../context/CommonContext';
import { dateConverter, UniqueStatusesByOrderId, UniqueLocationsByOrderId } from '../../../helpers/commonHelpers'
import { markAsRead } from '../../../services/orders/index'
import NoRecordsFound from '../../Common/views/NoRecord'
import Pagination from '../../Common/views/Pagination'
import SortIcon from '../../../assets/images/sort-icon.svg'
import SortAscIcon from '../../../assets/images/sort-asc.svg'
import SortDescIcon from '../../../assets/images/sort-desc.svg'
import OrderListingLoader from '../../Skeleton/Loader'
import PickupModal from '../../Modal/views/PickupModal'
import $ from 'jquery'
export default function Listing(props) {
    const { pickupOrderSearchData, pickupOrderSearchArray, goToSearchPage, isLoading, locations, filters, loadData } = props
    const { getLabel, setFilterData, constants, allowToPrepareOrderArray, allowToPickedUpOrderArray } = useContext(CommonContext);
    const [sortCriteria, setSortCriteria] = useState('');
    const [sortDirection, setSortDirection] = useState('');

    const [show, setShow] = useState(false);

    const [formData, setFormData] = useState({
        'order_ids': 0,
        'order_status': 0,
        'products': []
    });

    const openModal = (id, status, data) =>{
        setFormData({
            ...formData,
            order_ids: id,
            order_status: parseInt(status),
            products: data
        })
        setShow(true)
    }

    const navigate = useNavigate();

    const sortItems = (criteria) => {
        const newSortDirection = sortCriteria === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortCriteria(criteria);
        setSortDirection(newSortDirection);
        const sortOrder = [criteria, newSortDirection]
        goToSearchPage('sortOrder', sortOrder, true)
    };

    const handleMarkRead = async (orderId) => {
        const data = {
            "order_id": orderId
        }
        try {
            const res = await markAsRead(data);
            if (res === true) {
                navigate(`${process.env.REACT_APP_BASE_URL}/orders/view/${orderId}`)
            }
        } catch (error) {
            toast.error(error)
        }
    }

    useEffect(() => {
        var limit = 10;
        if(pickupOrderSearchData.data && pickupOrderSearchData.data.limit){
            limit = pickupOrderSearchData.data.limit;
        }

        var rows = $("#orderListing_form tbody tr");
        rows.each(function (index) {
            if (index >= (limit - 3)) {
                $(this).addClass("last_tr");
            }
        });

        rows.each(function (index) {
            if (index < (limit - 3)) {
                $(this).removeClass("last_tr");
            }
        });
    }, [isLoading, pickupOrderSearchData])

    const storeFilterData = () => {
        setFilterData(filters)
    }

    return (
        <>
            <form id="orderListing_form">
                <div className='table-responsive table-popup_css'>
                    <table width="100%" className="table table--hovered  order_listing_tbl">
                        <thead>
                            <tr>
                                <th width="1%"><input type="checkbox" className='selectAll-js' id="selectAll-js" /><label htmlFor="selectAll-js"></label></th>
                                <th onClick={() => sortItems('order_date_added')} width="7%">{getLabel('LBL_Date')} {sortCriteria === 'order_date_added' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
                                <th width="4%">{getLabel('LBL_Status')}</th>
                                <th onClick={() => sortItems('order_id')} width="12%">{getLabel('LBL_Order_ID')} {sortCriteria === 'order_id' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
                                <th onClick={() => sortItems('oua_name')} width="10%">{getLabel('LBL_Customer')} {sortCriteria === 'oua_name' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
                                <th width="13%">{getLabel('LBL_SKU')}</th>
                                <th width="10%">{getLabel('LBL_Merchant')}</th>
                                <th width="12%">{getLabel('LBL_Location')}</th>
                                <th width="31%">{getLabel('LBL_Pick_up')}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {isLoading ? <OrderListingLoader columns={10} /> :
                                pickupOrderSearchArray && pickupOrderSearchArray.length > 0 ? pickupOrderSearchArray.map((data, index) => {
                                    const uniqueStatusesArray = UniqueStatusesByOrderId(data);
                                    const allowToPrepareOrder =  uniqueStatusesArray.some(item => allowToPrepareOrderArray.includes(item.statusId))
                                    const allowToPickedUpOrder =  uniqueStatusesArray.some(item => allowToPickedUpOrderArray.includes(item.statusId))
                                    const uniqueLocationsArray = UniqueLocationsByOrderId(data);
                                    return <tr key={index}>
                                        <td><input type="checkbox" name="order_id[]" className='selectItem--js' id={"order_" + index} value={data.order_id}/><label htmlFor={"order_" + index}></label></td>
                                        <td>{dateConverter(data.order_date_added, 'dateYMD')}<br /><span className='time'>{dateConverter(data.order_date_added, 'time')}</span></td>
                                        <td>
                                            <div className='list-status gap-2'>
                                                <div>
                                                    {uniqueStatusesArray.map((statusItem, statusIndex) => (
                                                        <div key={statusIndex} title={`SKUs ${statusItem.skus.join(', ')}`} className='vertical-space'>
                                                            <img src={`/images/status/${statusItem.statusId}.svg`} className='listing_img' alt={`${statusItem.statusId}-status`} />
                                                        </div>
                                                    ))}
                                                </div>
                                                {data.unreadNotes && data.unreadNotes.length && <div onClick={() => handleMarkRead(data.order_id)}><img src={`/images/notes.svg`} className='listing_img' alt='unread-note' /></div>}
                                            </div>
                                        </td>

                                        <td><Link to={`${process.env.REACT_APP_BASE_URL}/pickup-orders/view/${data.order_id}/P`} className='view_page_link' onClick={storeFilterData}></Link><img src={`/images/channels/${data.order_channel}.svg`} className='channel_img' alt="channel" />{`${data.order_id} ${data.order_channel_oid ? '(' + data.order_channel_oid + ')' : ''}`}</td>

                                        <td>{data.shipDetails.oua_name ? (data.shipDetails.oua_name) : ''}</td>

                                        <td>
                                            {data.products.map((product, index) => {
                                                return <div key={index} className='vertical-space'>{`${(product.op_qty - product.op_refund_qty - product.totalCancelQty)} X ${product.op_selprod_sku}`}</div>
                                            })
                                            }
                                        </td>

                                        <td>
                                            {data.seller.map((sellerData, index) => {
                                                return <div key={index} className='vertical-space'>{sellerData.shop_name ? sellerData.shop_name : sellerData.shop_identifier}</div>
                                            })
                                            }
                                        </td>

                                        <td>
                                            {uniqueLocationsArray.map((locationData, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="vertical-space">
                                                            {locations.map((location) => (location.loc_id === locationData.locationId ? location.loc_name : null))}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            <span className={`btn ${allowToPrepareOrder === false ? 'disabled' : ''}`} onClick={ allowToPrepareOrder === true ? () => openModal(data.order_id, constants.ORDER_PICKUP_READY, data.products) : null}>{getLabel('LBL_PREPARE_FOR_PICK_UP')}</span>
                                            <span className={`btn pickup_btn ${allowToPickedUpOrder === false ? 'disabled' : ''}`} onClick={ allowToPickedUpOrder === true ? () => openModal(data.order_id, constants.ORDER_PICKED_UP, data.products) : null}>{getLabel('LBL_MARKED_PICKED_UP')}</span>
                                        </td>
                                    </tr>
                                }) :
                                    <NoRecordsFound column={10} />
                            }
                        </tbody>
                    </table>
                </div>
            </form>
            {pickupOrderSearchArray && pickupOrderSearchArray.length > 0 ? <Pagination goToSearchPage={goToSearchPage} orderSearchData={pickupOrderSearchData} /> : null}
            <PickupModal show={show} setShow={setShow} data={formData} setData={setFormData} filters={filters} loadData={loadData} />
        </>
    )
}
