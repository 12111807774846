import axios from "axios";
import { doLogout } from '../services/auth/authentication'

export const myAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

let navigateCallback;

myAxios.interceptors.request.use((config) => {
  checkInactivity();
  return config;
}, (error) => {
  return Promise.reject(error);
});

myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      handleUnauthorized(() => {
        if (navigateCallback) {
          navigateCallback();
        }
      })
    }
    return Promise.reject(error);
  }
);

const handleUnauthorized = (callback) => {
  doLogout(() => {
    callback();
  })
};

export const setNavigateCallback = (callback) => {
  navigateCallback = callback;
};

export const isLoggedIn = () => {
  let token = localStorage.getItem('token');
  let tokenTime = localStorage.getItem('tokenTime');
  let user = JSON.parse(localStorage.getItem('userData'));
  if (token && user && tokenTime) {
    return true;
  }
  return false;
}

export const getLoggedUser = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem('userData'));
  } else {
    return 'undefined';
  }
}

export const getLoggedUserToken = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem('token'));
  }
  return false;
}

export const headers = () => {
  const header = {
    'Authorization': 'Bearer ' + getLoggedUserToken(),
  }
  return header;
}


export const dateConverter = (date, type = '') => {
  var dateTime = '';
  const inputDateTime = date;

  const dateObject = new Date(inputDateTime);

  const day = dateObject.toLocaleDateString('en-US', { day: '2-digit', timeZone: 'America/Toronto' });
  const monthName = dateObject.toLocaleDateString('en-US', { month: 'short', timeZone: 'America/Toronto' });
  const month = dateObject.toLocaleDateString('en-US', { month: '2-digit', timeZone: 'America/Toronto' });
  const year = dateObject.toLocaleDateString('en-US', { year: 'numeric', timeZone: 'America/Toronto' });
  const options = { hour: '2-digit', minute: 'numeric', hour12: true, timeZone: 'America/Toronto' };
  const time = dateObject.toLocaleString('en-US', options).toLowerCase();

  dateTime = `${day}/${month}/${year} ${time}`
  if (type === 'date') {
    dateTime = `${day}/${month}/${year}`
  }
  if (type === 'dateYMD') {
    dateTime = `${year}-${month}-${day}`
  }
  if (type === 'time') {
    dateTime = `${time}`
  }
  if (type === 'monthDay') {
    dateTime = `${monthName} ${day}`
  }
  if (type === 'monthDayYear') {
    dateTime = `${month}/${day}/${year} at ${time}`
  }
  if (type === 'datePicker') {
    const datePickerOption = { day: '2-digit', month: 'short' };
    dateTime = dateObject.toLocaleString('en-US', datePickerOption)
  }
  if (type === 'dateYMDPicker') {
    const datePickerDay = dateObject.toLocaleDateString('en-US', { day: '2-digit' });
    const datePickerMonth = dateObject.toLocaleDateString('en-US', { month: '2-digit' });
    const datePickerYear = dateObject.toLocaleDateString('en-US', { year: 'numeric' });
    dateTime = `${datePickerYear}-${datePickerMonth}-${datePickerDay}`
  }
  if (type === 'dateYMDExport') {
    const dateExportDay = dateObject.toLocaleDateString('en-US', { day: '2-digit' });
    const dateExportMonth = dateObject.toLocaleDateString('en-US', { month: '2-digit' });
    const dateExportYear = dateObject.toLocaleDateString('en-US', { year: 'numeric' });
    dateTime = `${dateExportYear}${dateExportMonth}${dateExportDay}`
  }
  return dateTime;
}

export const dateComparison = (dateString) => {

  const inputDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const inputDateUTC = Date.UTC(
    inputDate.getUTCFullYear(),
    inputDate.getUTCMonth(),
    inputDate.getUTCDate()
  );
  const todayUTC = Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate()
  );
  const yesterdayUTC = Date.UTC(
    yesterday.getUTCFullYear(),
    yesterday.getUTCMonth(),
    yesterday.getUTCDate()
  );

  if (inputDateUTC === todayUTC) {
    return "Today";
  }
  if (inputDateUTC === yesterdayUTC) {
    return "Yesterday";
  }

  return dateString;
}

export const UniqueStatusesByOrderId = (orderData) => {
  const statusMap = new Map();
  orderData.products.forEach((product) => {
    if (product.op_is_ship_completed === -1) {
      product.op_status_id = 8;//ORDER_CANCELLED Status 
    }
    const statusId = product.op_status_id;
    const sku = product.op_selprod_sku;

    if (!statusMap.has(statusId)) {
      statusMap.set(statusId, { statusId, skus: [] });
    }

    statusMap.get(statusId).skus.push(sku);
  });

  return Array.from(statusMap.values());
};

export const UniqueLocationsByOrderId = (orderData) => {
  const locationMap = new Map();

  orderData.products.forEach((product) => {
    const locationId = product.op_location_id;
    const opId = product.op_id;
    const statusId = product.op_status_id;
    const sku = product.op_selprod_sku;
    if (!locationMap.has(locationId)) {
      locationMap.set(locationId, { locationId, opIds: [], statusIds: [], skus: [] });
    }

    locationMap.get(locationId).opIds.push(opId);
    locationMap.get(locationId).statusIds.push(statusId);
    locationMap.get(locationId).skus.push(sku);
  });
  return Array.from(locationMap.values());
};

export const addressFormatter = (location) => {
  const commaAddress1 = location['loc_address_1'] ? ', ' : '';
  const commaAddress2 = location['loc_address_2'] ? ', ' : '';
  const commaCity = location['loc_city'] ? ', ' : '';
  const commastate = location['loc_zip_code'] || location['state_code'] ? ', ' : '';
  return `${location['loc_address_1'] + commaAddress1 + location['loc_address_2'] + commaAddress2 + location['loc_city'] + commaCity + location['state_code'] + ' ' + location['loc_zip_code'] + commastate + location['country_name']}`
}

export const getInitialCharOfName = (str) => {
  const words = str.split(/\s+/);
  let initialWord = '';
  for (const w of words) {
    if (w) {
      const firstLetter = w.charAt(0);
      initialWord += firstLetter;
    }
  }
  return initialWord;
}

export const displayMoneyFormat = (amount, fixedTwoDigit = true) => {
  const numericAmount = parseFloat(amount);
  var displayAmount = '$' + numericAmount;
  if (fixedTwoDigit) {
    displayAmount = '$' + numericAmount.toFixed(2);
  }
  return displayAmount;
}

export const hoursRange = (lower = 0, upper = 23.5, step = 0.5, format = '') => {
  const times = {};
  if (!format) {
    format = 'h:mm a';
  }
  for (let hour = lower; hour <= upper; hour += step) {
    const roundedHour = Math.floor(hour);
    const minutes = (hour - roundedHour) * 60;
    const date = new Date();
    date.setHours(roundedHour, minutes, 0, 0);
    const formattedTime = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
    const formattedKeyTime = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: false });
    times[`${formattedKeyTime === '24:00' ? '00:00' : formattedKeyTime === '24:30' ? '00:30' : formattedKeyTime}:00`] = formattedTime;
  }
  return times;
}

export const removeSuffix = (formData) => {
  const updatedData = {
    ...formData,
    packages: formData.packages.map(packageData => ({
      ...packageData,
      op_ids: packageData.op_ids.map(opId => String(opId).replace(/_\d+$/, ''))
    }))
  };
  return updatedData;
}

export const addProcessingLoader = () => {
  const loader = document.querySelector('.circleLoader');
  const body_container = document.querySelector('.body_container');
  const header = document.querySelector('#header');
  const body = document.body;
  const modal_backdrop = document.querySelector('.modal-backdrop');
  const modal = document.querySelector('.modal');
  if (loader.style.display === 'none') {
    loader.style.display = 'flex';
    body_container.classList.add('processing-loader');
    header.classList.add('processing-loader');
    body.classList.add('body_overflow');
    if (modal_backdrop && modal) {
      modal_backdrop.classList.add('loader');
      modal.classList.add('loader');
    }
  }
}

export const removeProcessingLoader = () => {
  const loader = document.querySelector('.circleLoader');
  const body_container = document.querySelector('.body_container');
  const header = document.querySelector('#header');
  const body = document.body;
  const modal_backdrop = document.querySelector('.modal-backdrop');
  const modal = document.querySelector('.modal');
  if (loader.style.display === 'flex') {
    loader.style.display = 'none';
    body_container.classList.remove('processing-loader');
    header.classList.remove('processing-loader');
    body.classList.remove('body_overflow');
    if (modal_backdrop && modal) {
      modal_backdrop.classList.remove('loader');
      modal.classList.remove('loader');
    }
  }
}

export const UniquePackagesArray = (pack, historyMap) => {
  const opack_id = pack.opack_id != null ? pack.opack_id : pack.ohistory_id;
  if (!historyMap.has(opack_id)) {
    historyMap.set(opack_id, pack);
  }
  return historyMap;
};

let lastActionTime = new Date().getTime();
document.addEventListener("click", () => {
  lastActionTime = new Date().getTime();
  localStorage.setItem('tokenTime',JSON.stringify(lastActionTime));
});

window.addEventListener("load", () => {
  lastActionTime = new Date().getTime();
  localStorage.setItem('tokenTime', JSON.stringify(lastActionTime));
});
document.addEventListener("keydown", () => {
  lastActionTime = new Date().getTime(); 
  localStorage.setItem('tokenTime',JSON.stringify(lastActionTime));
});

const inactivityPeriod = 60 * 60 * 1000; // One hour in milliseconds
export const checkInactivity = () => {
  const currentTime = new Date().getTime();
  const updatedTokenTime = localStorage.getItem('tokenTime');
  if (currentTime - updatedTokenTime >= inactivityPeriod) {
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenTime');
  }
}

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const removeHyphens = (inputString) => {
  const result = inputString.replace(/-/g, '');
  return result;
}

export const getUniquePickupLocations = (locations) => {
  const uniqueLocations = [];
  const uniqueNames = new Set();
  locations.forEach(loc => {
      if (!uniqueNames.has(loc.ploc_name)) {
          uniqueLocations.push(loc);
          uniqueNames.add(loc.ploc_name);
      }
  });
  return uniqueLocations;
}