import {myAxios,headers} from '../../helpers/commonHelpers';

export const thirtPartyWebsByShopId = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/shop/third-party-webs`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}

export const addWoocommerceCredential = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/shop/add-woocommerce-credential`,data,{headers: headers()}).then((res)=>{
                resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}
export const updateWoocommerceCredential = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/shop/update-woocommerce-credential`,data,{headers: headers()}).then((res)=>{
                resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}
export const deleteWooCommerceCredential = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/shop/delete-credential`,data,{headers: headers()}).then((res)=>{
                resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }else{
                reject(error.response.data.message);
            }
        })
    });
}
