import {myAxios,headers} from '../../helpers/commonHelpers';
export const getShippingLabel = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/label/imported-label`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}

export const markPrinted = async (data) => {
    return new Promise((resolve, reject) => {
        myAxios.post(`/label/import-label-printed`,data,{headers: headers()}).then((res)=>{
            resolve(res.data);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                reject(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                reject(error.response.data.message);
            }
        })
    });
}


