import {useContext, useState} from 'react'; 
import CommonContext from '../../context/CommonContext';
// import { addProcessingLoader, removeProcessingLoader } from '../../helpers/commonHelpers';
export const useOrderPickupSearch = () => {
    const {pickupOrderSearch,pickupOrderSearchData,pickupOrderSearchArray,merchants,getSeller,isLoading} = useContext(CommonContext);
    
    const initialFilters = {
        page: 1,
        keyword: '',
        order_is_paid: '',
        date_from: '',
        date_to: '',
        price_from: '',
        price_to: '',
        status: [],
        channels:[],
        locations:[],
        sellers:[],
        sortOrder:[],
        cancel_shipment:''
    };

    const [filters, setFilters] = useState(initialFilters);


    const goToSearchPage = async (type='page',value = 1,resetPage = false,getSellerReq = false) => {
        
        let updatedFilters = {
            ...filters,
            [type]: value,
            page: resetPage ? 1 : value
        };
        if(type === 'status'){
            updatedFilters = {
                ...updatedFilters,
                'cancel_shipment': ''
            };
        } 

        if(getSellerReq === true){
            getSeller(updatedFilters);
        }
        setFilters(updatedFilters);
        loadData(updatedFilters);
    };

    const goToSearchPageByDate = async (start,end) => {
        const updatedFilter = {
            ...filters,
            'date_from': start,
            'date_to':  end
        };

        setFilters(updatedFilter)
        loadData(updatedFilter)
    };

    const loadData = async (initialValues = []) => {
        pickupOrderSearch(initialValues);
    };
 

  return {pickupOrderSearchData,pickupOrderSearchArray,loadData,goToSearchPage,merchants,goToSearchPageByDate,getSeller,isLoading,filters,setFilters}
};


