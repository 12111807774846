import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom';
import LeftArrow from '../../../assets/images/leftArrow.svg'
import { viewOrder } from '../../../services/orders/index'
import { toast } from 'react-toastify';
import CommonContext from '../../../context/CommonContext'
import { getFilters } from '../../../services/orders/index'
import History from './History';
import { displayMoneyFormat, dateConverter, addProcessingLoader, removeProcessingLoader, getUniquePickupLocations } from '../../../helpers/commonHelpers'
import PrintPacking from './PrintPacking';
export default function View() {
    const { id, channelId } = useParams();
    const [data, setData] = useState({})
    const { constants, shippingMethodObject, getLabel, setListingFilter, filterData } = useContext(CommonContext);
    const [status, setStatus] = useState([]);
    const [location, setLocation] = useState([]);
    const [uniqueStatuses, setUniqueStatuses] = useState([]);
    const [loadHistory, setLoadHistory] = useState(false);
    let subtotalAmount = 0;
    let totalShipping = 0;
    let totalTax = 0;
    let orderDiscount = 0;
    let sellerDiscount = 0;
    let discountCode = '';
    let pickupDate = [];
    useEffect(() => {
        getOrderDetails()
        getOrderStatus()
        // eslint-disable-next-line
    }, [])

    const getOrderDetails = async () => {
        addProcessingLoader();
        try {
            const res = await viewOrder(id, channelId);
            setData(res.data)
            const orderStatuses = res.data.products.map((product) => (product.op_is_ship_completed === constants.ORDER_SHIPMENT_IS_CANCELLED ? constants.ORDER_CANCELLED : product.op_status_id));
            const uniqueStatuses = [...new Set(orderStatuses)];
            setUniqueStatuses(uniqueStatuses);
        } catch (error) {
            toast.error(error);
            window.history.back();
        }
        removeProcessingLoader();
    }

    const getOrderStatus = async () => {
        try {
            const data = await getFilters();
            setStatus(data.orderStatus)
            setLocation(data.locations)
        } catch (error) {
            setStatus([])
        }
    }

    const handlePrint = () => {
        window.print();
    }

    const backPage = () => {
        if (Object.keys(filterData).length > 0) {
            setListingFilter(true);
        }
        window.history.back();
    }

    return (
        <>
            {Object.keys(data).length <= 0 ? '' :
                <>
                    <div id="body" className='no-print'>
                        <div className="page space">
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className='orderIdCss no-print'>
                                            <div onClick={backPage} className='no-print back-btn'>
                                                <img src={LeftArrow} alt="left arrow" className='no-print' />
                                            </div>
                                            <p>
                                                {getLabel('LBL_Order_ID:')} <img src={`/images/channels/${data.order_channel}.svg`} alt="channel-img" className="channelImgCss" /><span>{`${data.order_id} ${data.order_channel_oid ? '(' + data.order_channel_oid + ')' : ''}`}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-2 text-right'>
                                        <Link className="btn no-print printBtn" onClick={handlePrint}>{getLabel('LBL_Packing_slip')}</Link>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-9'>
                                        <div className="cards mb-5">
                                            <div className="orderInfo">
                                                <div className="topStatus">
                                                    <ul>
                                                        {uniqueStatuses.map((statusId) => (
                                                            <li key={statusId}><img src={`/images/status/${statusId}.svg`} className="statusImg" alt={statusId} /> {status.map((Status) => (Status.orderstatus_id === statusId ? Status.status : null))}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className='table-responsive'>
                                                    <table className="table detail_page pickup-detail-page">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th width="25%">{getLabel('LBL_Product')}</th>
                                                                <th>{getLabel('LBL_SKU')}</th>
                                                                <th>{getLabel('LBL_Qty')}</th>
                                                                <th>{getLabel('LBL_Unit_Price')}</th>
                                                                <th>{getLabel('LBL_Subtotal')}</th>
                                                                <th>{getLabel('LBL_Tax')}</th>
                                                                <th></th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>

                                                            {data.products.map((product, index) => {
                                                                subtotalAmount += product.op_qty * product.op_unit_price;
                                                                totalShipping += product.op_actual_shipping_charges;
                                                                totalTax += parseFloat(data.order_channel > 0 ? product.op_total_tax : product.op_deducted_tax);
                                                                orderDiscount += product.op_discount_amt;
                                                                sellerDiscount += parseFloat(product.op_total_sdiscount);
                                                                discountCode = product.op_sdiscount_title !== '' ? product.op_sdiscount_title : discountCode;
                                                                product.op_status_id = product.op_is_ship_completed === constants.ORDER_SHIPMENT_IS_CANCELLED ? constants.ORDER_CANCELLED : product.op_status_id;
                                                                product.shipdetails && pickupDate.push(dateConverter(product.shipdetails[0].ohistory_added_on, 'dateYMD'))
                                                                pickupDate = [...new Set(pickupDate)]
                                                                return <React.Fragment key={index}>
                                                                    <tr className='product_row'>
                                                                        <td>
                                                                            <div className='item__pic'>
                                                                                <img src={product.images ? process.env.REACT_APP_IMAGE_BASE_URL + product.images[0].afile_physical_path : '/images/no_image.jpg'} alt="productImage" />
                                                                            </div>
                                                                        </td>
                                                                        <td className='item__description'>
                                                                            <div className='product_name'>{`${product.oProLang && product.oProLang.op_brand_name} ${product.oProLang && product.oProLang.op_product_name}`}</div>
                                                                            <div>{product.op_selprod_compatibility_text && product.op_selprod_compatibility_text}</div>
                                                                            <span className='variant_css'>{product.op_selprod_code && product.op_selprod_code}</span>
                                                                        </td>
                                                                        <td>{product.op_selprod_sku}</td>
                                                                        <td>
                                                                            <div className={`vertical-space ${product.op_refund_qty || product.cancel_qty ? 'line-through' : ''}`}>{product.op_qty}</div>
                                                                            {product.op_refund_qty || product.cancel_qty ? <div className="vertical-space refund-qty"><img src="/images/refund.svg" alt="refund" className='refund-img' />-{product.op_refund_qty + product.cancel_qty}</div> : ''}
                                                                        </td>
                                                                        <td>
                                                                            {displayMoneyFormat(product.op_unit_price)}
                                                                            {product.op_sdiscount_per_unit > 0 && 
                                                                                <>
                                                                                    <br /> <strike>{displayMoneyFormat(parseFloat(product.op_unit_price) + parseFloat(product.op_sdiscount_per_unit))}</strike>
                                                                                </>
                                                                            }
                                                                        </td>
                                                                        <td>{displayMoneyFormat(product.op_qty * product.op_unit_price)}</td>
                                                                        <td>{data.order_channel > 0 ? displayMoneyFormat(product.op_total_tax) : displayMoneyFormat(product.op_deducted_tax)}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr className="package_detail">
                                                                        {product.cancel_qty || product.op_refund_qty 
                                                                            ?
                                                                            <td colSpan={8}>
                                                                                {product.cancel_qty 
                                                                                    ? 
                                                                                        <span className={`statusMsg ${product.cancel_qty && product.op_refund_qty ? 'refunded-warning' : ''}`}>
                                                                                            <img src={`/images/warning.svg`} alt='warning' />
                                                                                            {`${product.cancel_qty} x ${product.op_selprod_sku} was cancelled. Please ship out ${product.op_qty - product.cancel_qty}`}
                                                                                        </span>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                                {product.op_refund_qty 
                                                                                    ? 
                                                                                        <span className={`statusMsg`}>
                                                                                            <img src={`/images/warning.svg`} alt='warning' />
                                                                                            {`${product.op_refund_qty} x ${product.op_selprod_sku} was refunded. Please ship out ${product.op_qty - product.op_refund_qty - product.cancel_qty}`}
                                                                                        </span>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </td>
                                                                            : 
                                                                            <td></td>
                                                                        }
                                                                    </tr>
                                                                </React.Fragment>
                                                            })}

                                                            <tr className="subtotal price">
                                                                <td colSpan={6}></td>
                                                                <td>{getLabel('LBL_Subtotal')}</td>
                                                                <td >{displayMoneyFormat(parseFloat(subtotalAmount) + parseFloat(sellerDiscount))}</td>
                                                            </tr>
                                                            {
                                                                sellerDiscount > 0 &&
                                                                <tr className="price">
                                                                    <td colSpan={6}></td>
                                                                    <td>{`${getLabel('LBL_Discount')} (${discountCode})`}</td>
                                                                    <td >-{displayMoneyFormat(sellerDiscount)}</td>
                                                                </tr>
                                                            }
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td> {getLabel('LBL_Shipping')}</td>
                                                                <td >{displayMoneyFormat(totalShipping)}</td>
                                                            </tr>
                                                            {orderDiscount > 0 &&
                                                                <tr className="price">
                                                                    <td colSpan={6}></td>
                                                                    <td> {getLabel('LBL_Discounts')}</td>
                                                                    <td>{displayMoneyFormat(orderDiscount)}</td>
                                                                </tr>
                                                            }

                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td>{getLabel('LBL_Tax')}</td>
                                                                <td>{displayMoneyFormat(totalTax)}</td>
                                                            </tr>
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td> {getLabel('LBL_Total')}</td>
                                                                <td>{displayMoneyFormat(subtotalAmount + totalShipping + totalTax + orderDiscount)} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row no-print'>
                                            <div className='col-md-12'>
                                                <div className="cards">
                                                    <div className="orderInfo">
                                                        <div className='auditHistory'>
                                                            <History id={id} constants={constants} location={location} shippingMethodObject={shippingMethodObject} getLabel={getLabel} loadData={loadHistory} setLoadData={setLoadHistory} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-md-3'>
                                        <div className="cards">
                                            <div className="orderInfo">
                                                <div className='customer_section_css'>
                                                    <div className='right_heading'>
                                                        {getLabel('LBL_Customer')}
                                                    </div>
                                                    <span className='user_name_css'>{`${data.buyer.obuyer_fname} ${data.buyer.obuyer_lname}`}</span>
                                                </div>
                                                <hr />
                                                {data.pickupLocations.length > 0 && getUniquePickupLocations(data.pickupLocations).map((loc, index) => (
                                                    <div className={`shipping_section_css ${index >= 1 ? 'pt-3' : ''}`} key={index} >
                                                        <div className='shipping-side-info'>
                                                            <div className='right_heading'>
                                                                {getLabel('LBL_Pickup_Address') + ' '+ (index >= 1 && data.pickupLocations.length > 1 ? ++index : '')}
                                                            </div>
                                                        </div>
                                                        <span>
                                                            {loc.ploc_name ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_name} <br />
                                                                    </>
                                                                ) : ''
                                                            }
                                                            
                                                            {loc.ploc_address_1 ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_address_1} <br />
                                                                    </>
                                                                ) : ''
                                                            }
                                                            {loc.ploc_address_2 ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_address_2} <br />
                                                                    </>
                                                                ) : ''
                                                            }
                                                            {loc.ploc_city ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_city},&nbsp;
                                                                    </>
                                                                ) : ''
                                                            }
                                                            {loc.ploc_state ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_state}&nbsp;
                                                                    </>
                                                                ) : ''
                                                            }
                                                            {loc.ploc_zip_code ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_zip_code} <br />
                                                                    </>
                                                                ) : ''
                                                            }

                                                            {loc.ploc_country ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_country} <br />
                                                                    </>
                                                                ) : ''
                                                            }
                                                            {loc.ploc_phone ?
                                                                (
                                                                    <>
                                                                        {loc.ploc_phone} <br />
                                                                    </>
                                                                ) : ''
                                                            }
                                                        </span>
                                                    </div>
                                                ))}
                                                <hr />
                                                <div className='shipment_section_css'>
                                                    <div className='right_heading'>
                                                        {getLabel('LBL_Shipment')}
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div className='shipment_icon'><img src={`/images/shipment-icons/purchase.svg`} alt="purchase" /></div>
                                                            <span className='title'>{getLabel('LBL_Purchase')}</span>
                                                            <span className='value'>{dateConverter(data.order_date_added, 'dateYMD')}</span>
                                                        </li> 
                                                        {pickupDate.length > 0 && pickupDate.map((date, index) => {
                                                            return <li key={index}>
                                                                    <div className='shipment_icon'><img src={`/images/shipment-icons/pickup-icon.svg`} alt="Shipment" /></div>
                                                                    <span className='title'>{getLabel('LBL_Pick_up')}</span>
                                                                    <span className='value'>{date}</span>
                                                                </li>
                                                        })}
                                                        {data.pickupLocations.length > 0 && getUniquePickupLocations(data.pickupLocations).map((item, index) => {
                                                            return  <li key={index}>
                                                                    <div className='shipment_icon'><img src={`/images/shipment-icons/location.svg`} alt="location" /></div>
                                                                    <span className='title'>{getLabel('LBL_Location')}</span>
                                                                    <span className='value'>{item.ploc_name}</span>
                                                                </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='print'>
                        <PrintPacking data={data} />
                    </div>
                </>
            }
        </>
    )
}
