import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CommonContext from '../../../context/CommonContext';
import { dateConverter, UniqueStatusesByOrderId } from '../../../helpers/commonHelpers'
import { markAsRead } from '../../../services/orders/index'
import NoRecordsFound from '../../Common/views/NoRecord'
import Pagination from '../../Common/views/Pagination'
import SortIcon from '../../../assets/images/sort-icon.svg'
import SortAscIcon from '../../../assets/images/sort-asc.svg'
import SortDescIcon from '../../../assets/images/sort-desc.svg'
import OrderListingLoader from '../../Skeleton/Loader'
export default function Listing(props) {
    const { orderSearchArray, goToSearchPage, orderSearchData, isLoading, filters, channelId } = props
    const { getLabel, setFilterData } = useContext(CommonContext);
    const [sortCriteria, setSortCriteria] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const navigate = useNavigate();
    const sortItems = (criteria) => {
        const newSortDirection = sortCriteria === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortCriteria(criteria);
        setSortDirection(newSortDirection);
        const sortOrder = [criteria, newSortDirection]
        goToSearchPage('sortOrder', sortOrder, true)
    };

    const handleMarkRead = async (orderId) => {
        const data = {
            "order_id": orderId
        }
        try {
            const res = await markAsRead(data);
            if (res === true) {
                navigate(`${process.env.REACT_APP_BASE_URL}/pre-orders/view/${orderId}/${channelId}`)
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const storeFilterData = () => {
        setFilterData(filters)
    }
    return (
        <>
            <form id="orderListing_form">
                <div className='table-responsive table-popup_css'>
                    <table width="100%" className="table table--hovered  order_listing_tbl">
                        <thead>
                            <tr>
                                <th><input type="checkbox" className='selectAllLabel-js' id="selectAllLabel-js" /><label htmlFor="selectAllLabel-js"></label></th>
                                <th onClick={() => sortItems('order_date_added')} width="7%">{getLabel('LBL_Date')} {sortCriteria === 'order_date_added' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
                                <th width="5%">{getLabel('LBL_Status')}</th>
                                <th onClick={() => sortItems('order_id')} width="12%">{getLabel('LBL_Order_ID')} {sortCriteria === 'order_id' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
                                <th onClick={() => sortItems('oua_name')} width="10%">{getLabel('LBL_Customer')} {sortCriteria === 'oua_name' ? (sortDirection === 'asc' ? <img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' />}</th>
                                <th width="13%">{getLabel('LBL_SKU')}</th>
                                <th width="49%">{getLabel('LBL_Merchant')}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {isLoading ? <OrderListingLoader columns={7} /> :
                                orderSearchArray && orderSearchArray.length > 0 ? orderSearchArray.map((data, index) => {
                                    const uniqueStatusesArray = UniqueStatusesByOrderId(data);
                                    return <tr key={index}>
                                        <td><input type="checkbox" name="order_id[]" className='selectLabelItem--js' id={"order_" + index} value={data.order_id} /><label htmlFor={"order_" + index}></label></td>
                                        <td>{dateConverter(data.order_date_added, 'dateYMD')}<br /><span className='time'>{dateConverter(data.order_date_added, 'time')}</span></td>
                                        <td>
                                            <div className='list-status gap-2'>
                                                <div>
                                                    {uniqueStatusesArray.map((statusItem, statusIndex) => {
                                                        const orderStatus = (statusItem.statusId);
                                                        return <div key={statusIndex} title={`SKUs ${statusItem.skus.join(', ')}`} className='vertical-space'>
                                                            <img src={`/images/status/${orderStatus}.svg`} className='listing_img' alt={`${orderStatus}-status`} />
                                                        </div>
                                                    })}
                                                </div>
                                                {data.unreadNotes && data.unreadNotes.length && <div onClick={() => handleMarkRead(data.order_id)}><img src={`/images/notes.svg`} className='listing_img' alt='unread-note' /></div>}
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={`${process.env.REACT_APP_BASE_URL}/pre-orders/view/${data.order_id}/${channelId}`} className='view_pre_order_page_link' onClick={storeFilterData}></Link>
                                            <img src={`/images/channels/${data.order_channel}.svg`} className='channel_img' alt="channel" />
                                            {`${data.order_id} ${data.order_channel_oid ? '(' + data.order_channel_oid + ')' : ''}`}
                                        </td>
                                        <td>
                                            {data.shipDetails.oua_name ? (data.shipDetails.oua_name) : ''}
                                        </td>
                                        <td>
                                            {
                                                data.products.map((product, index) => {
                                                    return <div key={index} className='vertical-space'>{`${product.op_qty - product.op_refund_qty} X ${product.op_selprod_sku}`}</div>
                                                })
                                            }
                                        </td>

                                        <td>
                                            {
                                                data.seller.map((sellerData, index) => {
                                                    return <div key={index} className='vertical-space'>{sellerData.shop_name ? sellerData.shop_name : sellerData.shop_identifier}</div>
                                                })
                                            }
                                        </td>
                                    </tr>
                                })
                                    :
                                    <NoRecordsFound column={7} />
                            }
                        </tbody>
                    </table>
                </div>
            </form>
            {orderSearchArray && orderSearchArray.length > 0 ? <Pagination goToSearchPage={goToSearchPage} orderSearchData={orderSearchData} /> : null}
        </>
    )
}
