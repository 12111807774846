import React ,{useContext} from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../../context/CommonContext';
export default function RemoveChannel(props) {
    const {show ,setShow, handleDelete, id, setId} = props
    const {getLabel} = useContext(CommonContext);
    const handleClose = () => {
        setShow(false);
        setId('');
    }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='location custom-modal remove-modal'>
        <Modal.Header closeButton >
          <Modal.Title className='top-title'>{getLabel('LBL_Delete_sales_channel')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='text-center'>{getLabel('LBL_Are_you_sure_you_want_to_delete_this_Woocommerce_sales_channel?')}</div>
            <div className='row mt-5'>
                <div className="col-lg-6 col-sm-6">
                <button type="button" className="btn btn-outline-secondary text-danger" onClick={() => {handleDelete(id); handleClose()}}>{getLabel('LBL_Remove')}</button>
                </div>
                <div className="col-lg-6 col-sm-6">
                <button type="button" className="btn btn-outline-secondary text-dark" onClick={handleClose}>{getLabel('LBL_Keep')}</button>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
