import React from 'react'
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
export default function Pagination(props) {
    const { orderSearchData, goToSearchPage } = props
    const page = orderSearchData.data.offset;
    const pageSize = orderSearchData.data.limit;
    const startIdx = ((page - 1) * pageSize + 1);
    const prevPage = (page - 1);
    const nextPage = (page + 1);
    const totalPage = orderSearchData.data.pages;
    const recordCount = orderSearchData.data.count;
    const handleGoToSearchPage = (page) => {
        goToSearchPage('page', page)
    }

    return (
        <>
            <div className="section footinfo">
                <aside className='grid_3'></aside>
                <aside className="grid_1">
                    {totalPage <= 1 ? null :
                        <ul className="pagination">
                            {page <= 1 ? null :
                                <>
                                    {page > 2
                                        &&
                                        <li>
                                            <Link onClick={() => handleGoToSearchPage(1)}>
                                                <FaChevronLeft /><FaChevronLeft /></Link>
                                        </li>
                                    }
                                    <li>
                                        <Link onClick={() => handleGoToSearchPage(prevPage)}>
                                            <FaChevronLeft /></Link>
                                    </li>
                                    {page >= 2 ?
                                        <li>
                                            <Link onClick={() => handleGoToSearchPage(page - 1)}>{page - 1}</Link>
                                        </li>
                                        :
                                        null

                                    }
                                </>
                            }
                            <li className="selected">
                                <Link >{page}</Link>
                            </li>
                            {page < totalPage ?
                                <>
                                    {page <= totalPage ?
                                        <li>
                                            <Link onClick={() => handleGoToSearchPage(page + 1)}>{page + 1}</Link>
                                        </li>
                                        :
                                        null

                                    }
                                    {page <= totalPage - 2 ?
                                        <li>
                                            <Link onClick={() => handleGoToSearchPage(page + 2)}>{page + 2}</Link>
                                        </li>
                                        :
                                        null
                                    }
                                    {page <= totalPage - 3 ?
                                        <li>
                                            <Link onClick={() => handleGoToSearchPage(page + 3)}>{page + 3}</Link>
                                        </li>
                                        :
                                        null
                                    }

                                    <li>
                                        <Link onClick={() => handleGoToSearchPage(nextPage)}>
                                            <FaChevronRight />
                                        </Link>
                                    </li>
                                    {page <= totalPage - 2
                                        &&
                                        <li>
                                            <Link onClick={() => handleGoToSearchPage(totalPage)}>
                                                <FaChevronRight /><FaChevronRight /></Link>
                                        </li>
                                    }
                                </>
                                : null}
                        </ul>
                    }
                </aside>
                <aside className="grid_2">
                    {totalPage === 1 ? `Showing ${recordCount} entries` : `Showing ${startIdx} - ${recordCount < startIdx + pageSize - 1 ? recordCount : startIdx + pageSize - 1} of ${recordCount} entries`}
                </aside>
            </div>
        </>
    )
}
