import $ from 'jquery'

$(document).on("click",".locationDropdown",function(){
    if( $(this).siblings('.popupwrap').hasClass('active')){
        $(this).siblings('.popupwrap').removeClass('active');
        return ;
    }
    $('.locationDropdown').siblings('.popupwrap').removeClass('active')
    $(this).siblings('.popupwrap').addClass('active');
})

$(document).on('click','.tabs_handle',function(){
    $('.tabs .arrowTabs li').removeClass('is-active');
    $(this).addClass('is-active');
})

$(document).on('click','.filterBtnJs',function(){
    $('.filter_dropdown').slideToggle();
    $('.dropdownWrapper_js').removeClass('displayShow');
})


$(document).on('click','.filterTab_handle_js',function(){
    if($(this).siblings('.dropdownWrapper_js').hasClass('displayShow')){
        $(this).siblings('.dropdownWrapper_js').removeClass('displayShow');
        return false;
    }
    $('.dropdownWrapper_js').removeClass('displayShow');
    $(this).siblings('.dropdownWrapper_js').addClass('displayShow');
});

$(document).on('click','.other_action',function(){
    $(this).siblings('.other_action_list_js').toggle();
});

$(document).on('click','.print_action',function(){
    $(this).closest('a.print_label').siblings('.print_action_list_js').toggle();
});

$(document).on('click',function(e){
    if ($(e.target).closest(".dropdownWrapper_js").length === 0 && $(e.target).closest(".filterTab_handle_js").length === 0) {
        $('.dropdownWrapper_js').removeClass('displayShow');
    }

    if ($(e.target).closest(".other_action_list_js").length === 0 && $(e.target).closest(".other_action").length === 0) {
        $('.other_action_list_js').hide();
    }
 
     if ($(e.target).closest(".pickup_option_list_js").length === 0 && $(e.target).closest(".pickup_option").length === 0) {
        $('.pickup_option_list_js').hide();
    }

    if ($(e.target).closest(".print_action_list_js").length === 0 && $(e.target).closest(".print_action").length === 0) {
        $('.print_action_list_js').hide();
    }

    if ($(e.target).closest(".shipping_btn_list_js").length === 0 && $(e.target).closest(".ship-btn-action").length === 0) {
        $('.shipping_btn_list_js').hide();
    }

    if ($(e.target).closest(".popupwrap").length === 0 && $(e.target).closest(".locationDropdown").length === 0) {
        $('.popupwrap').removeClass('active');
    }
    if ($(e.target).closest(".dropdownPackage").length === 0 && $(e.target).closest(".multiple_package").length === 0) {
        $('.dropdownPackage').removeClass('active');
    }
    if ($(e.target).closest(".dropdownRates").length === 0 && $(e.target).closest(".multiple_rate").length === 0) {
        $('.dropdownRates').removeClass('active');
    }
    if ($(e.target).closest(".list-actions").length === 0) {
        $('.list-actions').find('.droplink').removeClass('active');
    }
    if ($(e.target).closest(".sku-floating-js").length === 0) {
        $('.sku-floating-js').find('label').removeClass('floating-label');
    }
    if ($(e.target).closest(".info-section").length === 0) {
        $('.info-section').removeClass('show');
        $('.info-section').find('.user-dropdown-toggle').removeClass('active');
    }
    if ($(e.target).closest(".dimension_input").length === 0) {
        $('.dimension_input').removeClass('active');
    }
})

$(document).on('click','.hamburger-toggle',function(){
    $(this).toggleClass("is-opened");
    $('body').toggleClass("sidebar-is-reduced");
    $('body').toggleClass("sidebar-is-expanded");
    $('.menutrigger img').toggleClass("logo-is-expanded");
    $('.menutrigger img').toggleClass("logo-is-reduced");
    $('.body').removeClass('sidebar--on');
});



$(document).on("click",".multiple_package",function(){
    if( $(this).siblings('.dropdownPackage').hasClass('active')){
        $(this).siblings('.dropdownPackage').removeClass('active');
        return ;
    }
    $('.multiple_package').siblings('.dropdownPackage').removeClass('active')
    $(this).siblings('.dropdownPackage').addClass('active');
})

$(document).on("click",".multiple_rate",function(){
    if( $(this).siblings('.dropdownRates').hasClass('active')){
        $(this).siblings('.dropdownRates').removeClass('active');
        return ;
    }
    $('.multiple_rate').siblings('.dropdownRates').removeClass('active')
    $(this).siblings('.dropdownRates').addClass('active');
})

$(document).on("click",".rateClosebtn",function(){
    $('.dropdownRates').removeClass('active')
})
$(document).on("click",".packageClosebtn",function(){
    $('.dropdownPackage').removeClass('active')
})

$(document).on('click','.list-actions',function(){
    if($(this).find('.droplink').hasClass('active')){
        $(this).find('.droplink').removeClass('active');
        return false;
    }
    $('.list-actions').find('.droplink').removeClass('active');
    $(this).find('.droplink').addClass('active');
});

$(document).on('mouseover','.tooltip-icon',function(){
    $('.tooltip-msg').show();
})
$(document).on('mouseout','.tooltip-icon',function(){
    $('.tooltip-msg').hide();
})

 $(document).on('keypress','.numberWithDots',function (e) {
    var charCode = (e.which) ? e.which : e.keyCode    
    if (String.fromCharCode(charCode).match(/[^0-9.]/g))    
    return false;                        
}); 


$(document).on('keypress','.numberInput',function (e) {
    var charCode = (e.which) ? e.which : e.keyCode    
    if (String.fromCharCode(charCode).match(/[^0-9]/g))    
    return false;                        
}); 

$(document).on('click','.sku-floating-js',function (e) {
    $('.sku-floating-js').find('label').removeClass('floating-label');
    $(this).find('label').addClass('floating-label');
});
$(document).on('click','.sku-form-floating',function (e) {
    $(this).find('.floating-input').focus()
});

$(document).on('click','.info-section',function(){
    $(this).toggleClass('show');
    $(this).find('.user-dropdown-toggle').toggleClass('active');
})

$(document).on('click','.dimension_input',function(){
    $('.dimension_input').removeClass('active');
    $(this).addClass('active');
});

$(document).on('click','.pickup_option',function(){
    $(this).siblings('.pickup_option_list_js').toggle();
});
 
$(document).on('click','.selectAllLabel-js',function(e){
    const obj = e.target;
    var parentForm = $(obj).closest('form').attr('id');
    $("#" + parentForm + " .selectLabelItem--js").each(function() {
        if ($(obj).prop("checked") === false) {
            $(this).prop("checked", false);
            $(".labelIdBased").addClass("disabled");
        } else {
            $(this).prop("checked", true);
            $(".labelIdBased").removeClass("disabled");
        }
    })
 })

$(document).on("click", ".selectLabelItem--js", function() {
    var parentForm = $(this.form).attr("id");
    if ($(this).prop("checked") === false) {
        $("#" + parentForm + " .selectAllLabel-js").prop("checked", false);
    }
    
    if ($("#" + parentForm + " .selectLabelItem--js").length === $("#" + parentForm + " .selectLabelItem--js:checked").length) {
        $("#" + parentForm + " .selectAllLabel-js").prop("checked", true);
    } 
    
    if($("#" + parentForm + " .selectLabelItem--js:checked").length > 0){
        $(".labelIdBased").removeClass("disabled");
    }else{
          $(".labelIdBased").addClass("disabled");
    }
});
$(document).on('click','.ship-btn-action',function(){
    $(this).closest('.createWithPrintBtn').siblings('.shipping_btn_list_js').toggle();
});


$(document).on('click','.selectAll-js',function(e){
    const obj = e.target;
    var parentForm = $(obj).closest('form').attr('id');
    $("#" + parentForm + " .selectItem--js").each(function() {
        if ($(obj).prop("checked") === false) {
            $(this).prop("checked", false);
            $(".orderIdBased").addClass("disabled");
        } else {
            $(this).prop("checked", true);
            $(".orderIdBased").removeClass("disabled");
        }
    })
 })

$(document).on("click", ".selectItem--js", function() {
    var parentForm = $(this.form).attr("id");
    if ($(this).prop("checked") === false) {
        $("#" + parentForm + " .selectAll-js").prop("checked", false);
    }
    
    if ($("#" + parentForm + " .selectItem--js").length === $("#" + parentForm + " .selectItem--js:checked").length) {
        $("#" + parentForm + " .selectAll-js").prop("checked", true);
    } 
    
    if($("#" + parentForm + " .selectItem--js:checked").length > 0){
        $(".orderIdBased").removeClass("disabled");
    }else{
        $(".orderIdBased").addClass("disabled");
    }
});