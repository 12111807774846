import React, { useState, useContext  } from 'react'
import Modal from 'react-bootstrap/Modal'
import Combination from './Combination'
import CommonContext from '../../../../context/CommonContext'
import ShippingInfo from './ShippingInfo'
import {dateConverter, removeSuffix, addProcessingLoader, removeProcessingLoader} from '../../../../helpers/commonHelpers'
import { validationSchema } from "../../../../schemas/packageSchema";
import { FaAngleDown } from "react-icons/fa6";
import { getLabel as getPackageLabel } from '../../../../services/orders';
export default function Search(props) {
    const {formData,setFormData,shippingCompanies,packageData,order_id,location_id,handleClose,filters} = props
    const {getRates,shipmentRates,setShipmentRates,createShipment,orderSearch,constants,shippingMethodObject,getLabel, getOrderPendingCount, setPrintLable, setPrintLabelOrderId} = useContext(CommonContext);
    const [showTrackingField,setShowTrackingField] = useState({})
    const [isCombination,setIscombination] = useState(false);
    const [errors, setErrors] = useState({});
    const [removeCombinationDiv,setRemoveCombinationDiv] = useState({})

    const handleInputChange = (fieldName, fieldValue, index) => {
        const updatedItem = { ...formData.packages[index], [fieldName]: fieldValue };
        const updatedPackages = [...formData.packages];
        updatedPackages[index] = updatedItem;
        setFormData({
        ...formData,
        packages: updatedPackages,
        });

        if(fieldName === 'shiping_company_id'){
            setShowTrackingField(prevState => ({
                ...prevState, 
                [index]: parseInt(fieldValue) === constants.CANPAR_SHIPPING_COMPANY ? false:true,
            }));
        }
    };

    const handleTrackingNumberChange = (e, packageIndex, trackingIndex) => {
        const { value } = e.target;
        const updatedPackages = [...formData.packages];
        updatedPackages[packageIndex].tracking_number[trackingIndex] = value;
        setFormData({
          ...formData,
          packages: updatedPackages,
        });
    };

    const addTrackingNumber = (index) => {
        const updatedPackages = [...formData.packages];
        updatedPackages[index].tracking_number.push(""); 
        setFormData({
            ...formData,
            packages: updatedPackages,
        });
    };

    const removeTrackingNumber = (packageIndex, trackingIndex) => {
        const updatedPackages = [...formData.packages];
        const packageToUpdate = updatedPackages[packageIndex];
      
        if (trackingIndex >= 0 && trackingIndex < packageToUpdate.tracking_number.length) {
          packageToUpdate.tracking_number.splice(trackingIndex, 1);
      
          setFormData({
            ...formData,
            packages: updatedPackages,
          });
        }
    };

    const handleShipPackage = async(printLabel) => {
        addProcessingLoader();
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            try {
                const res = await createShipment(removeSuffix(formData));
                if(res === true){
                    getOrderPendingCount();
                    handleClose();
                    orderSearch(filters);
                    if(printLabel === true && order_id !== ''){
                        generateLable(order_id);
                    }
                }
            } catch (error) {
                // Silently handle error
            }
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                const path = error.path.split('.');
                const fieldName = path[1]; 
                const packageIndex = path[0]; 
                errorsObj[`${fieldName}_${packageIndex}`] = error.message;
            });
            setErrors(errorsObj);
        }
        removeProcessingLoader();
    };
    const handleShippingRate = async() => {
        addProcessingLoader();
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            try {
                await getRates(removeSuffix(formData))
            }catch (e){
                // Silently handle error
            }
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                const path = error.path.split('.');
                const fieldName = path[1]; 
                const packageIndex = path[0]; 
                errorsObj[`${fieldName}_${packageIndex}`] = error.message;
            });
            setErrors(errorsObj);
        }
        removeProcessingLoader();
    };
 
    const handleCombination = () => {
        setIscombination(true);
        setShipmentRates([])
    }

    const handleAddPackage = () => {
        const newPackage = {
            weight: '', 
            width: '',
            length: '', 
            height: '', 
            shiping_company_id: constants.CANPAR_SHIPPING_COMPANY, 
            shiping_method: 1,
            tracking_number: [''], 
            op_ids: [formData.packages[0].op_ids[0]], 
            additional_package: 1,
            reference:'',
            additional_op_ids: [...new Set(formData.packages.flatMap(pack => pack.op_ids))], 
        };
      
        setFormData((prevFormData) => ({
            ...prevFormData,
            packages: [...prevFormData.packages, newPackage],
        }));
    }

    const handleRemoveCombinationDiv = (index) => {
        setRemoveCombinationDiv(prevState => ({
            ...prevState, 
            [index]: true,
        }))
    }

    const keepCombination = (index) => {
        setRemoveCombinationDiv(prevState => ({
            ...prevState, 
            [index]: false,
        }))
    }
    
    const removeCombination = (index) => {
        if (index === 0 || index >= formData.packages.length) {
        return;
        }

        const updatedPackages = [...formData.packages];
        updatedPackages.splice(index, 1);
        setFormData((prevFormData) => ({
        ...prevFormData,
        packages: updatedPackages,
        }));
    
        setRemoveCombinationDiv(prevState => ({
        ...prevState, 
        [index]: false,
        }))
        setErrors({});
        setShipmentRates([])
    };

    const generateLable = async(order_Id) => {
        const data = {
            'order_ids': [order_Id]
        }
        try {
            const msgRes = await getPackageLabel(data);
            if(msgRes.status === true){
                setPrintLable(msgRes.data);
                setPrintLabelOrderId(order_Id);
            }
        } catch (error) {
            // Silently handle error
        }
    };
  return (
    <>
    {!isCombination ? 
        <>
            <Modal.Header closeButton className="mb-3 closeBtnCss">
                <h1 className="modal-title modal-title-heading" id="exampleModalLabel">
                    <span className='constatnt-width'>
                        {getLabel('LBL_Packages')}
                    </span>
                    <span className="info tooltip-icon">
                        <img className="img-fluid package-top-img" src="/images/info.svg" alt='icon' />
                        <span className='tooltip-msg'>{getLabel('LBL_Unclick_the_products_you_want_to_hold_for_shipping_later')}</span>
                    </span>
                </h1>
            </Modal.Header>
            <Modal.Body>
                <ShippingInfo />
                {formData.packages.map((item,index)=>(
                    <div className="row package-row" key={index}>
                        <span className="loction mb-4 pack-head-location">
                            <div className='left-head'>
                                <img className="img-fluid" src="/images/package.svg" alt='icon'/>
                                <h4>{getLabel('LBL_Package')} {index+1}</h4>
                            </div>
                            {item.additional_package === 1 
                                ?  
                                (
                                    <span className='right-head'>
                                        <img className="img-fluid " src="/images/remove.svg" alt="icon" onClick={() => handleRemoveCombinationDiv(index)} />
                                    </span>
                                ) 
                                : null
                            }
                        </span>
                        {removeCombinationDiv[index] 
                            ? 
                            <div className='col-lg-12 remove_combination'>
                                <div>
                                    <h2>{getLabel('LBL_Remove_package')}</h2>
                                    <p>{getLabel('LBL_Are_you_sure_you_want_to_remove_package')} {index+1}?</p>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-6 col-sm-6">
                                        <button type="button" className="btn btn-outline-secondary text-danger" onClick={() => removeCombination(index)} >{getLabel('LBL_Remove')}</button>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => keepCombination(index)} >{getLabel('LBL_Keep')}</button>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className="col-lg-12">
                                    <div className="border-buton">
                                        <ul>
                                            <li className={'dimension_input'}>
                                                <label className='dimension'>{getLabel('LBL_Length')} (L)</label>
                                                <input type="text" className="dimension_box numberWithDots" name="length" value={item.length}  onChange={(e) => handleInputChange(e.target.name, e.target.value, index)} />
                                                <span>in</span>
                                                {errors[`length_packages[${index}]`] && (
                                                    <div className="list_error error">{errors[`length_packages[${index}]`]}</div>
                                                )}
                                            </li>
                                            

                                            <li className='crossIcon'><img src="/images/close.svg" alt='icon'/></li>

                                            <li className={'dimension_input'}>
                                                <label className='dimension'>{getLabel('LBL_Width')} (W)</label>
                                                <input type="text" className="dimension_box numberWithDots" name="width" value={item.width} onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}    />
                                                <span>in</span>
                                                {errors[`width_packages[${index}]`] && (
                                                    <div className="list_error error">{errors[`width_packages[${index}]`]}</div>
                                                )}
                                            </li>
                                            

                                            <li className='crossIcon'><img src="/images/close.svg"  alt='icon'/></li>

                                            <li className={'dimension_input'}>
                                                <label className='dimension'>{getLabel('LBL_Height')} (H)</label>
                                                <input type="text" className="dimension_box numberWithDots" name="height" value={item.height} onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}  />
                                                <span>in</span>
                                                {errors[`height_packages[${index}]`] && (
                                                    <div className="list_error error">{errors[`height_packages[${index}]`]}</div>
                                                )}
                                            </li>
                                            
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <li className='dimension_input'>
                                                <label className='dimension weight'>{getLabel('LBL_Weight')}</label>
                                                <input type="text" className="dimension_box numberWithDots" name="weight" value={item.weight} onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}  />
                                                <span>lbs</span>
                                                {errors[`weight_packages[${index}]`] && (
                                                <div className="list_error error">{errors[`weight_packages[${index}]`]}</div>
                                                )}
                                            </li>
                                            

                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-12 combination_sku">
                                    {
                                        item.additional_package === 1 
                                        ?
                                            <div className='row'>

                                                <div className="col-lg-6">
                                                    <input type="text" className='reference-form-control' placeholder='Reference' autoComplete='off' value={item.reference} name="reference" onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}/>
                                                </div>
                                            </div>
                                        :
                                            <button type="button" className="btn btn-primary sku-btn">{item.sku}</button>
                                    }
                                </div>

                                <div className="row ground">
                                    <div className="col col-lg-6 col-sm-6">
                                        <select className="form-select" aria-label="Default select example" name='shiping_company_id' value={item.shiping_company_id} onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}>
                                            <option value={0}>{getLabel('LBL_Select_Shipping')}</option>
                                            {shippingCompanies && shippingCompanies.map((shippingCompany,index) => (
                                                <option value={parseInt(shippingCompany.scompany_id)} key={index}>{shippingCompany.company_name}</option>
                                            ))}
                                        </select>
                                        {errors[`shiping_company_id_packages[${index}]`] && (
                                            <div className="company_error error">{errors[`shiping_company_id_packages[${index}]`]}</div>
                                        )}
                                        {shipmentRates[index] && (showTrackingField[index]=== false || showTrackingField[index] === undefined) ?
                                        <label className="est">{getLabel('LBL_Est._delivery:')} {dateConverter(shipmentRates[index].estDlyDate,'monthDay')}</label> 
                                        :""}
                                    </div>

                                    {(showTrackingField[index]=== false || showTrackingField[index] === undefined)  && 
                                    
                                        <div className="col col-lg-6 col-sm-6">
                                            <select className="form-select" aria-label="Default select example" name='shiping_method' value={item.shiping_method} onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}>
                                                {Object.entries(shippingMethodObject).map(([key, value]) => (
                                                    <option key={key} value={key}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={`rate ${shipmentRates[index] && (showTrackingField[index]=== false || showTrackingField[index] === undefined) ? 'canpar_rate':''}`}>{getLabel('LBL_Rate:')} {shipmentRates[index] && (showTrackingField[index]=== false || showTrackingField[index] === undefined) ? '$'+(shipmentRates[index].price).toFixed(2)  : '-' }</label>
                                        </div>
                                    }
                                </div>

                                <div className="row tracking_field ground">
                                    
                                    { (showTrackingField[index]) && item.tracking_number.map((trackingNumber, trackingIndex) => (
                                        <React.Fragment  key={trackingIndex}>
                                            <div className="col col-lg-6 col-sm-6">
                                                <label className="tracking">{getLabel('LBL_Tracking_Numbers')}</label>
                                                <input type="text" className="tracking-input" name={`tracking_number_${index}_${trackingIndex}`} value={trackingNumber} onChange={(e) => handleTrackingNumberChange(e, index, trackingIndex)}/>
                                            </div>
                                            <div className="col col-lg-6 col-sm-6">
                                                {trackingIndex === 0 ? 
                                                    <img className="img-fluid plus" src="/images/plus.svg" alt="plus-icon" onClick={() => addTrackingNumber(index)}/>
                                                :
                                                    <img className="img-fluid plus" src="/images/remove.svg" alt="icon" onClick={() => removeTrackingNumber(index,trackingIndex)}/>
                                                }
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                ))}

                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <button type="button" className="btn btn-outline-secondary" onClick={handleCombination}>{getLabel('LBL_Combine')}</button>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <button type="button" className="btn btn-outline-secondary" onClick={handleAddPackage}>{getLabel('LBL_Add_bundle')}</button>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <button type="button" className="btn btn-outline-secondary margin-bottom-16" onClick={handleShippingRate}>{getLabel('LBL_Get_Rates')}</button>
                    </div>
                    <div className="col-lg-3 col-sm-6 ship-btn-section">
                        <button type="button" className="btn btn-primary ship createWithPrintBtn">
                            <span className="ship-btn" onClick={() => handleShipPackage(true)}>
                                {getLabel('LBL_Create_+_Print_label')}
                            </span>
                            <span className="ship-btn-action"><FaAngleDown /></span>
                        </button>
                        <ul className="shipping_btn_list_js shipping_btn_action_list">
                            <li className='shipping_btn_action_item' onClick={() => handleShipPackage(false)}>{getLabel('LBL_Create_shipping_label')}</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </>
        :
            <Combination data={packageData} shippingCompanies={shippingCompanies} order_id={order_id} location_id={location_id} filters={filters} handleClose={handleClose} generateLable={generateLable}/>
        }
    </>
  )
}
