import React, { useState, useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../context/CommonContext';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { exportData } from '../../../services/orders/index'
import { dateConverter } from '../../../helpers/commonHelpers'
export default function ExportModal(props) {
    const { show, setShow, channelId, isPickup, isCourierRateCall, setExportCourierRate, merchants, orderIds } = props
    const { getLabel, constants } = useContext(CommonContext);
    const EXPORT_TYPE_ALL_ORDERS = 1;
    const EXPORT_TYPE_SELECTED_ORDERS = 2;
    const EXPORT_TYPE_CUSTOM_ORDERS = 3;
    const [customDate, setCustomDate] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [startNewDate, setStartNewDate] = useState(new Date());
    const [endNewDate, setEndNewDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const selectionRange = { startDate: startDate, endDate: endDate, key: 'selection' }
    const [formData, setFormData] = useState({});
    const [selectedType, setSelectedType] = useState(EXPORT_TYPE_ALL_ORDERS);
    const [isAnyTrue, setIsAnyTrue] = useState(false);
    const initialField = {
        'date': 0,
        'status': 0,
        'merchant': 0,
    }
    const [toggleField, setToggleField] = useState(initialField);
    const initialData = {
        'page': '',
        'page_to': '',
        'channels': channelId !== undefined ? [channelId] : [],
        'date_from': '',
        'date_to': '',
        'status': '',
        'is_pickup_order': isPickup,
        'seller_id':  selectedType === EXPORT_TYPE_CUSTOM_ORDERS ? merchants.length > 0 && merchants[0].user_id :'',
        'order_ids': selectedType === EXPORT_TYPE_SELECTED_ORDERS ? orderIds : [],
    };
    useEffect(() => {
        setFormData(initialData);
        // eslint-disable-next-line
    }, [channelId, show, isPickup, selectedType, orderIds])

    useEffect(() => {
        setIsAnyTrue(Object.values(toggleField).some(value => value === 1));
    }, [toggleField, selectedType]);

    const handleClose = () => {
        setShow(false);
        setCustomDate(false);
        setExportCourierRate(false);
        setSelectedType(EXPORT_TYPE_ALL_ORDERS);
        setToggleField(initialField);
        setIsAnyTrue(false);
    }

    const handleSelectChange = (event, field) => {
        const value = event.target.value ? parseInt(event.target.value) : '';
        const updatedData = {
            ...formData,
            [field]: field === 'status' ? (value ? value : '') : value,
        };
        setFormData(updatedData);

        const newvalue = parseInt(value) === 2 ? true : false;
        if (field === 'date_selection') {
            setCustomDate(newvalue)
            if (newvalue === false) {
                const updatedData = {
                    ...formData,
                    'date_from': '',
                    'date_to': ''
                };
                setFormData(updatedData);
            } else {
                const updatedData = {
                    ...formData,
                    'date_from': startDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', }),
                    'date_to': endDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', })
                };
                setFormData(updatedData);
            }
        }
    };

    const handleSelect = (date) => {
        setstartDate(date.selection.startDate)
        setendDate(date.selection.endDate)
        const start = (date.selection.startDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', }))
        const end = (date.selection.endDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', }))
        setStartNewDate(start)
        setEndNewDate(end)
    };

    const handleSubmit = async () => {
        const newFormData = {
            ...formData,
            'date_from': toggleField.date === 1 ? formData.date_from : '',
            'date_to': toggleField.date === 1 ? formData.date_to : '',
            'status': toggleField.status === 1 ? formData.status : '',
            'seller_id': toggleField.merchant === 1 ? formData.seller_id : '',
        };
        await exportData(newFormData, isCourierRateCall);
        handleClose();
    }

    const handleOpenPicker = () => {
        setIsOpen(true)
    }

    const handleClosePicker = () => {
        setIsOpen(false)
    }

    const handleApplyDatePicker = () => {
        const updatedData = {
            ...formData,
            'date_from': startNewDate,
            'date_to': endNewDate,
        };
        setFormData(updatedData)
        setIsOpen(false)
    }

    const handleRadioChange = (type) => {
        setSelectedType(parseInt(type));
        if (type !== EXPORT_TYPE_CUSTOM_ORDERS) {
            setFormData(initialData);
        }
        setToggleField(initialField);
    }

    const handleFieldToggle = (field, value) => {
        setToggleField({
            ...toggleField,
            [field]: parseInt(value) === 1 ? 0 : 1,
        });

        if (field === 'date') {
            setIsOpen(false)
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered className='location third-party export export-modal'>
                <Modal.Header closeButton >
                    <Modal.Title >{isCourierRateCall === true ? getLabel('LBL_Export_rates') : getLabel('LBL_Export_orders')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-0'>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="field-set">
                                <input type="radio" name="export_type" value={EXPORT_TYPE_ALL_ORDERS} checked={((selectedType === EXPORT_TYPE_ALL_ORDERS) ? true : false)} onChange={(e) => handleRadioChange(e.target.value)} />
                                <span>{getLabel('LBL_All_orders')}</span>
                            </div>
                            <div className="field-set">
                                <input type="radio" name="export_type" value={EXPORT_TYPE_SELECTED_ORDERS} checked={((selectedType === EXPORT_TYPE_SELECTED_ORDERS) ? true : false)} onChange={(e) => handleRadioChange(e.target.value)} />
                                <span>{`${getLabel('LBL_Selected:_%d_orders').replace('%d', orderIds.length)}`}</span>
                            </div>
                            <div className="field-set">
                                <input type="radio" name="export_type" value={EXPORT_TYPE_CUSTOM_ORDERS} checked={((selectedType === EXPORT_TYPE_CUSTOM_ORDERS) ? true : false)} onChange={(e) => handleRadioChange(e.target.value)} />
                                <span>{getLabel('LBL_Custom')}</span>
                            </div>
                        </div>
                    </div>

                    {
                        selectedType === EXPORT_TYPE_CUSTOM_ORDERS &&
                        <>
                            {isCourierRateCall === false
                                ?
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div className="field-set">
                                            <div className="caption-wraper">
                                                <input type="checkbox" id="toggleStatus" onClick={() => handleFieldToggle('status', toggleField.status)} />
                                                <label htmlFor="toggleStatus" className='toggleLabel field_label'>{getLabel('LBL_Order_status')}</label>
                                            </div>
                                            <div className={`field-wraper ${toggleField.status === 0 ? 'disabled' : ''}`}>
                                                <div className="field_cover">
                                                    <select name="status" onChange={(event) => handleSelectChange(event, event.target.name)}>
                                                        <option value="">{getLabel('LBL_All_statuses')}</option>
                                                        {parseInt(isPickup) === 1
                                                            ?
                                                            <>
                                                                <option value={constants.ORDER_PICKUP_PENDING}>{getLabel('LBL_PICKUP_PENDING')}</option>
                                                                <option value={constants.ORDER_PICKUP_READY}>{getLabel('LBL_PICKUP_READY')}</option>
                                                                <option value={constants.ORDER_PICKED_UP}>{getLabel('LBL_PICKED_UP')}</option>
                                                            </>
                                                            :
                                                            <>
                                                                <option value={constants.ORDER_UNSHIPPED}>{getLabel('LBL_Unshipped')}</option>
                                                                <option value={constants.ORDER_FLAGGED}>{getLabel('LBL_Flagged')}</option>
                                                                <option value={constants.ORDER_SHIPPED}>{getLabel('LBL_Shipped')}</option>
                                                                <option value={constants.ORDER_SHIPMENT_IS_CANCELLED}>{getLabel('LBL_Cancelled')}</option>
                                                            </>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                            }

                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="field-set">
                                        <div className="caption-wraper">
                                            <input
                                                type="checkbox"
                                                id="toggleMerchant"
                                                onClick={() => handleFieldToggle('merchant', toggleField.merchant)}
                                            />
                                            <label htmlFor="toggleMerchant" className='toggleLabel field_label'>{getLabel('LBL_Merchant')}</label>
                                        </div>
                                        <div className={`field-wraper ${toggleField.merchant === 0 ? 'disabled' : ''}`}>
                                            <div className="field_cover">
                                                <select name="seller_id" onChange={(event) => handleSelectChange(event, event.target.name)} >
                                                    {merchants && merchants.map((merchant) => (
                                                        <option value={merchant.user_id} key={merchant.user_id}>{merchant.user_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="field-set">
                                        <div className="caption-wraper">
                                            <input
                                                type="checkbox"
                                                id="toggleDate"
                                                onClick={() => handleFieldToggle('date', toggleField.date)}
                                            />
                                            <label htmlFor="toggleDate" className='toggleLabel field_label'>{getLabel('LBL_Date')}</label>
                                        </div>
                                        <div className={`field-wraper ${toggleField.date === 0 ? 'disabled' : ''}`}>
                                            <div className="field_cover">
                                                <select name="date_selection" onChange={(event) => handleSelectChange(event, event.target.name)} >
                                                    <option value="1">{getLabel('LBL_All_dates')}</option>
                                                    <option value="2">{getLabel('LBL_Custom')}</option>
                                                </select>
                                            </div>
                                            {
                                                customDate
                                                &&
                                                <div className="field_cover mt-2">
                                                    <input type="text" name='date' className='date_field date_icon_css' readOnly placeholder={`${dateConverter(startNewDate, 'datePicker')} - ${dateConverter(endNewDate, 'datePicker')}`} onClick={isOpen ? handleClosePicker : handleOpenPicker} />
                                                    <div className='filter_dropdown'>
                                                        {
                                                            isOpen &&
                                                            <>
                                                                <DateRangePicker maxDate={new Date()} preventSnapRefocus={true} moveRangeOnFirstSelection={false} calendarFocus="backwards" ranges={[selectionRange]} months={2} direction="horizontal" onChange={handleSelect} showDateDisplay={false} inputRanges={[]} rangeColors={['#D31334']} weekdayDisplayFormat={'EEEEE'} />
                                                                <div className='cancelApplyButton'>
                                                                    <span>{`${dateConverter(startNewDate, 'datePicker')} - ${dateConverter(endNewDate, 'datePicker')}`}</span>
                                                                    <button className='btn btn-cancel border-0' onClick={handleClosePicker}>{getLabel('LBL_Cancel')}</button>
                                                                    <button className='btn btn-save' onClick={handleApplyDatePicker}>{getLabel('LBL_Apply')}</button>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer className='pt-0'>
                    <button className='btn btn-cancel' onClick={handleClose}>{getLabel('LBL_Cancel')}</button>
                    <button className={`btn btn-save ${(selectedType === EXPORT_TYPE_CUSTOM_ORDERS && isAnyTrue === false) || (selectedType === EXPORT_TYPE_SELECTED_ORDERS &&  orderIds.length < 1 ) ? 'disabled' : ''}`} onClick={handleSubmit}>{getLabel('LBL_Export')}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
