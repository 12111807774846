import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { thirdPartySchema } from '../../../../schemas/thirdPartySchema';
import { useFormik } from "formik";
import { addWoocommerceCredential, updateWoocommerceCredential } from '../../../../services/shops/index'
import { toast } from 'react-toastify';
import CommonContext from '../../../../context/CommonContext'
import { addProcessingLoader, removeProcessingLoader } from '../../../../helpers/commonHelpers';
export default function Form(props) {
    const { show, setShow, data, getThirdPartyWebsites } = props
    const { getLabel } = useContext(CommonContext);
    const handleClose = () => setShow(false);
    let res;
    const [internationalShop, setInternationalShop] = useState(data.is_international_shop)
    const initialValues = data

    const { values, handleChange, handleSubmit, errors, touched } = useFormik(
        {
            initialValues,
            validationSchema: thirdPartySchema,
            validateOnChange: false,

            onSubmit: async (values, { resetForm }) => {
                addProcessingLoader();
                try {
                    if (values.scredential_id) {
                        res = await updateWoocommerceCredential(values);
                    } else {
                        res = await addWoocommerceCredential(values);
                    }
                    toast.success(res.message);
                    resetForm();
                    handleClose();
                    getThirdPartyWebsites();
                } catch (error) {
                    toast.error(error);
                }
                removeProcessingLoader();
            },
        }
    );

    const handleToggleShop = (is_international) => {
        const isInternationalShop = is_international === 1 ? 0 : 1;
        setInternationalShop(isInternationalShop);
        values.is_international_shop = isInternationalShop;
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered
                className='custom-modal third-party'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getLabel('LBL_WooCommerce_Credential')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label">{getLabel('LBL_Shop_URL')}</label>
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <input type="text" name="shop_url" value={values.shop_url} onChange={handleChange} className={`form-control ${touched.shop_url && errors.shop_url ? ("border-error") : null}`} />
                                            {touched.shop_url && errors.shop_url ? (<span className='error'>{errors.shop_url}</span>) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label">{getLabel('LBL_Consumer_Key')}</label>
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <input type="text" name="consumer_key" value={values.consumer_key} onChange={handleChange} className={`form-control ${touched.consumer_key && errors.consumer_key ? ("border-error") : null}`} />
                                            {touched.consumer_key && errors.consumer_key ? (<span className='error'>{errors.consumer_key}</span>) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label">{getLabel('LBL_Consumer_Secret_Key')}</label>
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <input type="text" name="consumer_secret" value={values.consumer_secret} onChange={handleChange} className={`form-control ${touched.consumer_secret && errors.consumer_secret ? ("border-error") : null}`} />
                                            {touched.consumer_secret && errors.consumer_secret ? (<span className='error'>{errors.consumer_secret}</span>) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label">{getLabel('LBL_International_orders')}</label>
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <label className="statustab">
                                                <input checked={internationalShop === 1} onChange={() => handleToggleShop(internationalShop)} type="checkbox" name='is_international_shop' className="switch-labels"  />
                                                <i className="switch-handles"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="field-set">
                                    <div className="caption-wraper">
                                        <label className="field_label"></label>
                                    </div>
                                    <div className="field-wraper">
                                        <div className="field_cover">
                                            <input type="submit" name="btn_submit" value={getLabel('LBL_Save')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
