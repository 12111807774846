import React,{ useContext, useState } from 'react'
import ShopLoader from '../../Skeleton/Loader'
import Pagination from '../../Common/views/Pagination'
import CommonContext from '../../../context/CommonContext'
import NoRecordsFound from '../../Common/views/NoRecord'
import {dateConverter} from '../../../helpers/commonHelpers'
import { Link } from 'react-router-dom'
import SortIcon from '../../../assets/images/sort-icon.svg'
import SortAscIcon from '../../../assets/images/sort-asc.svg'
import SortDescIcon from '../../../assets/images/sort-desc.svg'
export default function Listing(props) {
    const {goToSearchPage} = props
    const {shopData,isLoading,getLabel} = useContext(CommonContext);
    const condition = shopData.data && shopData.data.rows && shopData.data.rows.length > 0 ;
    const [sortCriteria, setSortCriteria] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const sortItems = (criteria) => {
        const newSortDirection = sortCriteria === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortCriteria(criteria);
        setSortDirection(newSortDirection);
        const sortOrder = [criteria,newSortDirection]
        goToSearchPage('sortOrder',sortOrder,true)
    };
  return (
    <>
          <form id="shopListing_form">

                <table width="100%" className="table table-responsive table--hovered">
                    <thead>
                        <tr>
                            <th width="20%" onClick={() => sortItems('shop_name')}>{getLabel('LBL_Shop_Name')} {sortCriteria === 'shop_name' ? (sortDirection === 'asc' ?<img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' /> }</th>
                            <th width="20%" onClick={() => sortItems('credential_email')}>{getLabel('LBL_Email')} {sortCriteria === 'credential_email' ? (sortDirection === 'asc' ?<img src={SortAscIcon} alt='sort' /> : <img src={SortDescIcon} alt='sort' />) : <img src={SortIcon} alt='sort' /> }</th>
                            <th width="10%">{getLabel('LBL_Products')}</th>
                            <th width="15%">{getLabel('LBL_Created_On')}</th>
                            <th width="15%">{getLabel('LBL_Connected_Account')}</th>
                            <th width="15%">{getLabel('LBL_Status')}</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading 
                        ? 
                            <ShopLoader columns={7} /> 
                        :
                        condition ? shopData.data.rows.map((data,index) => (
                                <tr key={index}>
                                    <td>{data.shop_name}</td>
                                    <td>{data.credential_email}</td>
                                    <td>{data.totalProducts}</td>
                                    <td>{dateConverter(data.shop_created_on,'dateYMD')}</td>
                                    <td>{data.totalAccounts}</td>
                                    <td>
                                        <label className="statustab">
                                            <input checked={data.shop_active === 1 ? 'checked':''}  type="checkbox"  disabled className="switch-labels"/>
                                            <i className="switch-handles"></i>
                                        </label>
                                    </td>
                                    <td>
                                        <ul className="list-actions actions--centered">
                                            <li className="droplink">
                                                <Link  className="button">
                                                    <i className="fa fa-ellipsis-h icon"></i>
                                                </Link>
                                                <div className="dropwrap">
                                                    <ul className="linksvertical">
                                                        <li>
                                                            <Link to={`${process.env.REACT_APP_BASE_URL}/shops/third-party/${data.shop_name}/${data.shop_id}`}>{getLabel('LBL_Third_Party_Websites')}</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            )) :  <NoRecordsFound column={7}/>
                        }
                    </tbody>
                </table>
       
          </form>
          { condition ?<Pagination goToSearchPage={goToSearchPage} orderSearchData={shopData}/>:null}
    </>
  )
}
