import React, { useState, useContext, useEffect } from 'react'
import CommonContext from '../../../context/CommonContext'
import { validationSchema } from '../../../schemas/shipmentDeclaredValueSchema';
import { addProcessingLoader, removeProcessingLoader, displayMoneyFormat } from '../../../helpers/commonHelpers';
export default function Index() {
    // const [editAccount, setEditAccount] = useState(false)
    const [editDeclaredValue, setEditDeclaredValue] = useState(false)
    const { getLabel, canparShipmentSettingSearch, canparShipmentSettingData, saveShipmentSetting } = useContext(CommonContext);
    const initialFormData = {
        'value': '',
        'skus': []
    }
    const [formData, setFormData] = useState([initialFormData])

    const [skuValue, setSkuValue] = useState([]);
    const [errors, setErrors] = useState([])
    const handleSkuChange = (value, index) => {
        const updatedFormData = [...skuValue];
        updatedFormData[index] = {
            ...updatedFormData[index],
            sku: value
        };
        setSkuValue(updatedFormData);
    }

    useEffect(() => {
        shipmentSettingSearch()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (canparShipmentSettingData.setting && canparShipmentSettingData.setting.length > 0) {
            setFormData(canparShipmentSettingData.setting)
        }
    }, [canparShipmentSettingData, editDeclaredValue])

    const shipmentSettingSearch = async () => {
        addProcessingLoader()
        try {
            await canparShipmentSettingSearch()
        } catch (error) {
            console.warn(error)
        }
        removeProcessingLoader();
    } 

    const addSkus = (e, index) => {

        if (e.keyCode === 13 && skuValue[index] && skuValue[index].sku !== '' && index !== '') {
            const updatedSkus = [...formData[index].skus];
            updatedSkus.push(skuValue[index].sku);
            const updatedFormData = [...formData];
            updatedFormData[index] = {
                ...updatedFormData[index],
                skus: updatedSkus
            };
            setFormData(updatedFormData);
            setSkuValue([]);
        }
    }

    const removeSku = (mainId, childId) => {
        const updatedSkus = [...formData[mainId].skus];
        updatedSkus.splice(childId, 1)
        const updatedFormData = [...formData];
        updatedFormData[mainId] = {
            ...updatedFormData[mainId],
            skus: updatedSkus
        };
        setFormData(updatedFormData);
    }

    const handleInputChange = (name, value, index) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value
        };
        setFormData(updatedFormData);
    }

    const addMoreForm = () => {
        const updatedForm = [...formData];
        updatedForm.push(initialFormData);
        setFormData(updatedForm);
    }

    const handleCloseDeclaredValue = () => {
        setEditDeclaredValue(false)
        setFormData([initialFormData])
        setSkuValue([]);
    }

    const handleSubmit = async () => {
        const data = {
            'setting': formData
        }
        addProcessingLoader();
        try {
            await validationSchema.validate(data, { abortEarly: false });
            setErrors({});
            try {
                const res = await saveShipmentSetting(data)
                if (res === true) {
                    shipmentSettingSearch()
                    setEditDeclaredValue(false)
                }
            } catch (e) {
                console.log(e)
            }
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                const path = error.path;
                const matches = path.match(/\d+/);
                if (matches) {
                    const index = parseInt(matches[0]);
                    errorsObj[`value_${index}`] = error.message;
                }
            });
            setErrors(errorsObj);
        }
        removeProcessingLoader();
    }
    return (
        <>
            <div id="body">
                <div className="page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 space">
                                <div className="page__title">
                                    <div className="row">
                                        <div className="col--first col-md-12">
                                            <h5>{getLabel('LBL_Settings')}</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* Section Courier accounts */}
                                {/* <div className='listingContent setting_css'>
                                    <section className="section searchform_filter">
                                        <div className="sectionbody space">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <span className='section-head'>{getLabel('LBL_Courier_accounts')}</span>
                                                    <small>{getLabel('LBL_Assign_your_courier_accounts_to_each_of_your_fulfillment_locations._Shipments_will_be_created_from_the_respective_accounts.')}</small>
                                                </div>
                                                {editAccount === false ?
                                                    <div className='col-md-2 text-right'>
                                                        <span className='edit_link' onClick={e => setEditAccount(true)}>{getLabel('LBL_Edit')}</span>
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>
                                    </section>

                                    <section className="section">
                                        <div className="sectionbody body-space">
                                            {
                                                editAccount === false
                                                    ?
                                                    <>
                                                        <div className="row mt-3">
                                                            <div className="col-md-12">
                                                                <span className='section-img'><img src={`/images/canpar.svg`} alt="canpar" className='margin-right' /> {getLabel('LBL_Canpar_Express')}</span>
                                                            </div>
                                                            <div className="tablewrap setting_listing mt-2">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>SAP Fulfillment ON</td>
                                                                            <td>D57600130</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>SAP Fulfillment AB</td>
                                                                            <td>D4330009</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        <div className="row mt-3">
                                                            <div className="col-md-12">
                                                                <span className='section-img margin-right'><img src={`/images/canpar.svg`} alt="canpar" className='margin-right' /> {getLabel('LBL_Canpar_Express')}</span>
                                                            </div>
                                                            <div className="tablewrap setting_listing mt-2">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>SAP Fulfillment ON</td>
                                                                            <td>
                                                                                <input type="text" placeholder={getLabel('LBL_Account_Number')}/>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>SAP Fulfillment AB</td>
                                                                            <td>
                                                                                <input type="text" placeholder={getLabel('LBL_Account_Number')}/>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className='row foot-buttons'>
                                                            <div className="col-md-12">
                                                                <button className='btn btn-cancel' onClick={e => setEditAccount(false)}>
                                                                    {getLabel('LBL_Cancel')}
                                                                </button>
                                                                <button className='btn btn-save' onClick={e => setEditAccount(false)}>
                                                                    {getLabel('LBL_Save')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </section>
                                </div> */}

                                {/* Section Shipment Declared Value */}
                                <div className='listingContent setting_css mt-4'>
                                    <section className="section searchform_filter">
                                        <div className="sectionbody space">
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <span className='section-head'>{getLabel('LBL_Shipment_declared_value')}</span>
                                                    <small>{getLabel('LBL_Insure_your_products_when_shipping_with_the_courier')} </small>
                                                </div>
                                                {
                                                    editDeclaredValue === false
                                                        ?
                                                        <div className='col-md-2 text-right'>
                                                            <span className='edit_link' onClick={e => setEditDeclaredValue(true)}>{getLabel('LBL_Edit')}</span>
                                                        </div>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </div>
                                    </section>
                                    <section className="section">
                                        <div className="sectionbody body-space">
                                            {
                                                editDeclaredValue === false
                                                    ?
                                                    <>
                                                        <div className="row mt-3">
                                                            <div className="col-md-12">
                                                                <span className='section-img'><img src={`/images/canpar.svg`} alt="canpar" className='margin-right' /> {getLabel('LBL_Canpar_Express')}</span>
                                                            </div>
                                                            <div className="tablewrap setting_listing mt-2">
                                                                <table>
                                                                    <tbody>
                                                                        {
                                                                            canparShipmentSettingData.setting && canparShipmentSettingData.setting.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{displayMoneyFormat(item.value, false)}</td>
                                                                                    <td>{(item.skus.join(', '))}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="row mt-3">
                                                            <div className="col-md-12">
                                                                <span className='section-img margin-right'><img src={`/images/canpar.svg`} alt="canpar" className='margin-right' /> {getLabel('LBL_Canpar_Express')}</span>
                                                            </div>
                                                            <div className="tablewrap setting_listing  setting-form-css mt-2">
                                                                <table className='table'>
                                                                    <tbody>
                                                                        {(formData).map((data, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <tr >
                                                                                    <td width="20%">
                                                                                        <div className="form-floating">
                                                                                            <input type="text" className={`form-control numberWithDots ${errors[`value_${index}`] ? 'border-error' : ''} `} id={`floatingValueInput-${index}`} placeholder="Value" name='value' value={data.value ? data.value : ''} onChange={e => handleInputChange(e.target.name, e.target.value, index)} data-role="tagsinput" />
                                                                                            <label htmlFor={`floatingValueInput-${index}`}>{getLabel('LBL_Value')}</label>
                                                                                        </div>
                                                                                        {errors[`value_${index}`] && (
                                                                                            <div className="list_error error">{errors[`value_${index}`]}</div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td width="50%">
                                                                                        <div className={`form-floating sku-form-floating ${data.skus && data.skus.length > 0 ? '' : 'sku-floating-js'}`}>

                                                                                            <label htmlFor={`floatingSkuInput-${index}`} className={data.skus && data.skus.length > 0 ? 'floating-label' : ''}>{getLabel('LBL_SKU')}</label>
                                                                                            <div className='sku-input-wrap'>
                                                                                                <ul>
                                                                                                    {data.skus && data.skus.map((sku, skuIndex) => (
                                                                                                        <li key={skuIndex} className='sku-tabs'><span>{sku}</span> <img className='remove-btn' src='/images/gray-close.svg' alt="remove" onClick={e => removeSku(index, skuIndex)} /></li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                                <input type='text' id={`floatingSkuInput-${index}`} className="form-control floating-input" placeholder="SKU" onChange={e => handleSkuChange(e.target.value.trim(), index)} value={skuValue[index] ? skuValue[index].sku : ''} onKeyDown={(e) => addSkus(e, index)} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="30%" className='plus_icon_wrap'>
                                                                                        {
                                                                                            formData.length === index + 1
                                                                                                ?
                                                                                                <img className="img-fluid plus" src="/images/plus.svg" alt="plus-icon" onClick={() => addMoreForm(index)} />
                                                                                                :
                                                                                                ''
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className='row foot-buttons'>
                                                            <div className="col-md-12">
                                                                <button className='btn btn-cancel' onClick={e => handleCloseDeclaredValue()}>
                                                                    {getLabel('LBL_Cancel')}
                                                                </button>
                                                                <button className='btn btn-save' onClick={handleSubmit}>
                                                                    {getLabel('LBL_Save')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
