import {  useState } from "react";
import CommonContext from "./CommonContext";
import {myAxios,headers} from '../helpers/commonHelpers';
import { toast } from 'react-toastify';

const AppStates = (props) => {
    const [labels, setLabels] = useState({});
    const [saveLabel, setSaveLabel] = useState(false);
    const [settingData, setSettingData] = useState({});
    const [vacationMode, setVacationMode] = useState('');
    const [filterData, setFilterData] = useState({});
    const [listingFilter, setListingFilter] = useState(false);
    const [printLable,setPrintLable] = useState([]);
    const [printLabelOrderId,setPrintLabelOrderId] = useState(0);
    const constants = {
        ORDER_PAYMENT_PENDING:1,  
        ORDER_UNSHIPPED:2,
        ORDER_CANCELLED: 8,
        ORDER_SHIPPED:4,
        ORDER_RETURN_REQUEST:6, 
        ORDER_REFUNDED:9,
        ORDER_RETURN_REQUEST_APPROVED:17,
        ORDER_REQUEST_DENIED:18,
        ORDER_PAYMENT_FAILED:24,
        ORDER_CANCELLATION_PENDING:21,
        ORDER_PARTIALLY_RETURNED:25,
        ORDER_PARTIALLY_REFUNDED:26,
        ORDER_PARTIALLY_SHIPPED:27, 
        ORDER_FLAGGED:28,
        ORDER_PLACED:29,
        ORDER_NOTES:30,
        ORDER_PICKUP_PENDING:31,
        ORDER_PICKUP_READY:32,
        ORDER_PICKED_UP:33,
        ORDER_BACK_ORDER: 34,
        ORDER_PRE_ORDER: 35,
        ORDER_SHIPMENT_IS_CANCELLED:-1,

        CANPAR_SHIPPING_COMPANY:4,

        DAY_SUNDAY : 1,
        DAY_MONDAY : 2,
        DAY_TUESDAY : 3,
        DAY_WEDNESDAY : 4,
        DAY_THURSDAY : 5,
        DAY_FRIDAY : 6,
        DAY_SATURDAY : 7,

        CHANNEL_WOOCOMMERCE : 1,
        CHANNEL_SAP : 0,
        CHANNEL_SHOPIFY : 2,
        CHANNEL_AMAZON : 3,
        SHIPPING_ADDRESS_TYPE: 2,

        YES: 1,
        NO: 0,

        CANADA: "0",
        INTERNATIONAL: "1",
    };

    const allowToSearchPackageArray = [
        constants.ORDER_UNSHIPPED,
        constants.ORDER_FLAGGED,
        constants.ORDER_PARTIALLY_SHIPPED
    ];
    const allowToPrepareOrderArray = [
        constants.ORDER_PICKUP_PENDING
    ];
    const allowToPickedUpOrderArray = [
        constants.ORDER_PICKUP_READY,
        constants.ORDER_PICKUP_PENDING
    ];
    const shippedStatusArray = [
        constants.ORDER_SHIPPED,
        constants.ORDER_PARTIALLY_SHIPPED,
        constants.ORDER_PARTIALLY_REFUNDED,
        constants.ORDER_PARTIALLY_RETURNED,
        constants.ORDER_RETURN_REQUEST,
        constants.ORDER_REFUNDED,
        constants.ORDER_REQUEST_DENIED,
        constants.ORDER_RETURN_REQUEST_APPROVED,
    ];
    const unshippedStatusArray = [
        constants.ORDER_UNSHIPPED,
        constants.ORDER_FLAGGED,
        constants.ORDER_PAYMENT_PENDING,
    ];
    const shippingMethodObject = {
        1:'Ground',
        "E":'Express'
    };
    

    const dayObject = {
       [constants.DAY_SUNDAY] :'Sunday',
       [constants.DAY_MONDAY] :'Monday',
       [constants.DAY_TUESDAY] :'Tuesday',
       [constants.DAY_WEDNESDAY] :'Wednesday',
       [constants.DAY_THURSDAY] :'Thursday',
       [constants.DAY_FRIDAY] :'Friday',
       [constants.DAY_SATURDAY] :'Saturday',
    };

    const shippingCompanyObject = {
        1:'FedEx',
        2:'UPS',
        3:'DHL',
        4:'Canpar',
        5:'Canada Post',
        6:'Purolator',
        8:'ATS Courier',
        9:'Picked up at SAP',
        10:'Intelcom',
        11:'Loomis',
        12:'Day & Ross',
        13:'GTA GSM',
        14:'USPS',
        15:'Overland West',
        16:'ICS Courier',
        17:'Kindersley',
        18:'Postnl',
    };

    const channelObject = {
        0:"SAP Fulfillment",
        1:"WooCommerce",
        2:"Shopify",
        3:"Amazon"
    }

    const receivedObject = {
        0:"Strictlyautoparts.ca",
        1:"API",
        2:"Imported",
    }

    const shippingDestination = {
        0 : 'Canada',
        1 : 'International',
    }

    const [orderSearchData,setorderSearchData] = useState([]) 
    const [orderSearchArray,setorderSearchArray] = useState([]) 
    const [orderListingType, setOrderListingType] = useState(false) 
    
    const [pickupOrderSearchData,setPickupOrderSearch] = useState([]) 
    const [pickupOrderSearchArray,setPickuporderSearchArray] = useState([]) 
    
    const [merchants,setmerchants] = useState([]) 
    const [isLoading,setIsLoading] = useState(true) 
    const [packageData,setPackageData] = useState([]) 
    const [orderAddressInfo,setOrderAddressInfo] = useState({}) 
    const [shippingCompanies,setShippingCompanies] = useState([]) 
    const [shipmentRates,setShipmentRates] = useState([]) 
    const [shopData,setShopData] = useState([]) 
    const [importedLabelData,setImportedLabel] = useState([]) 
    const [canparShipmentSettingData,setCanparShipmentSettingData] = useState([]);
    const [states,setStates] = useState([]) 
    const [unprintLabelCount,setUnprintLabelCount] = useState(0); 
    const [pickupPendingCount,setPickupPendingCount] = useState(0); 
    const [orderPendingCount,setOrderPendingCount] = useState(0); 

    /* Get All Order Search Date */ 
    const orderSearch = (data=null) => {
        setIsLoading(true)
        myAxios.post('order/seller-search',data,{headers: headers()}).then((res)=>{
            setorderSearchData(res.data);
            setorderSearchArray(res.data.data.rows);
            setOrderListingType(false);
            setIsLoading(false);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK' ){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        });
        
    };
    const pickupOrderSearch = (data=null) => {
        setIsLoading(true);
        myAxios.post('pickup-order/search',data,{headers: headers()}).then((res)=>{
            setPickupOrderSearch(res.data);
            setPickuporderSearchArray(res.data.data.rows);
            setIsLoading(false);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK' ){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        });
        
    };

    const getSeller = (data=null) => {
        myAxios.post('order/sellerFilter',data,{headers: headers()}).then((res)=>{
            setmerchants(res.data.data)
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                console.log(process.env.REACT_APP_COMMON_ERROR);
            }else{
                console.log(error.response.data.message);
            }
        })
    }

    const updateLocation = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('order/update-location',data,{headers: headers()}).then((res)=>{
                if(res.data.status === true){
                    toast.success(res.data.message);
                    resolve(true);
                }else{
                    toast.error(res.data.message);
                    resolve(false);
                }
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK' ){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(error);
            })
        });
    }

    const getPackage = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('package/search',data,{headers: headers()}).then((res)=>{
                if(res.data.status === true && res.data.data.products.length > 0){
                    resolve(true)
                    setPackageData(res.data.data.products);
                    setOrderAddressInfo(res.data.data);
                    return;
                }
                toast.error(res.data.message)
                resolve(false)
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK'){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(true);
            })
        });
    }

    const addressSearch = (data) => {
        myAxios.post('package/address-search', data, {headers: headers()}).then((res)=>{
            if(Object.keys(res.data.data.address).length > 0){
                setOrderAddressInfo(res.data.data);
            }
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        })
    };

    const getShippingCompanies = () => {
        myAxios.get('package/shipping-companies',{headers: headers()}).then((res)=>{
            setShippingCompanies(res.data.data)
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                console.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        })
       
    }

    const getRates = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('package/get-shipment-rates',data,{headers: headers()}).then((res)=>{
                if(res.data.status === false){
                    if (Array.isArray(res.data.message) ) {
                        const messages = res.data.message;
                        const combinedMessage = messages.join('. \n');
                        toast.error(combinedMessage);
                        resolve(false)
                        return;
                    }
                    toast.error(res.data.message)
                    resolve(false)
                }
                resolve(true)
                setShipmentRates(res.data.data)
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK'){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(true)
            })
        })
    }

    const createShipment = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('package/create',data,{headers: headers()}).then((res)=>{
                if(res.data.status === false){
                    toast.error(res.data.message);
                    resolve(false);
                    return;
                }
                toast.success(res.data.message);
                resolve(true);
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK'){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(true)
            })
        })
    }

    const searchShops = (data = null) => {
        setIsLoading(true)
        myAxios.post('shop/search',data,{headers: headers()}).then((res)=>{
            setShopData(res.data)
            setIsLoading(false)
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK' ){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        })
    }

    const fetchLabels = async () => {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbl9lbWFpbCI6ImluZm9Ac3RyaWN0bHlhdXRvcGFydHMuY29tIiwiYWRtaW5faWQiOjEsImlhdCI6MTY5NTIwMjMzNX0.7nytbanFAKkAOik1XFSWnCBSmJs9IsZAXOIVxCr1mOs';
        const header = {
            'Authorization': 'Bearer '+token,
        }
        myAxios.get('label/search',{headers: header}).then((res)=>{
            setLabels(res.data.data);
            setSaveLabel(true);
        }).catch((error)=>{
            console.error('Error fetching labels:', error);
        })
    };

    const getLabel = (key) => {

        if (labels[key.toUpperCase()]) {
            return labels[key.toUpperCase()];
        } else {
            const value = key
                .replace('LBL_', '')
                .replace(/_/g, ' ');
                if(saveLabel){
                    saveLabels(key, value);
                }
            return value;
        }
    };

    const saveLabels = async(key) => {
        const data = {
            'label_key':key
        }
        myAxios.post('label/save-lang-label',data,{headers: headers()}).then((res)=>{
        
        }).catch((error)=>{
            console.log(error)
        })
    };

    const fetchLabelSetting = async () => {
        myAxios.get('/print-label-setting/search',{headers: headers()}).then((res)=>{
            setSettingData(res.data.data.setting)
            setVacationMode(parseInt(res.data.data.vacation))
        }).catch((error)=>{
            console.error('Error fetching labels:', error);
        })
    };

    const updateSetting = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('print-label-setting/save',data,{headers: headers()}).then((res)=>{
                toast.success(res.data.message);
                resolve(true)
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK'){
                    reject(process.env.REACT_APP_COMMON_ERROR)
                }
                else{
                    reject(error.response.data.message)
                }
            })
        })
    }

    const updateOrderProductLocation = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('order/update-multiple-location',data,{headers: headers()}).then((res)=>{
                if(res.data.status === true){
                    toast.success(res.data.message);
                    resolve(true);
                }else{
                    toast.error(res.data.message);
                    resolve(false);
                }
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK' ){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(error);
            })
        });
    }

    const canparShipmentSettingSearch = () => {
        return new Promise((resolve, reject) => {
            myAxios.get('canpar-setting/search',{headers: headers()}).then((res)=>{
                setCanparShipmentSettingData(res.data.data)
                resolve(true)
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK' ){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(error);
            })
        });
    }

    const saveShipmentSetting = (data) => {
        return new Promise((resolve, reject) => {
            myAxios.post('canpar-setting/save',data,{headers: headers()}).then((res)=>{
                if(res.data.status === true){
                    toast.success(res.data.message);
                    resolve(true);
                }else{
                    toast.error(res.data.message);
                    resolve(false);
                }
            }).catch((error)=>{
                if(error.code ==='ERR_NETWORK' ){
                    toast.error(process.env.REACT_APP_COMMON_ERROR);
                }
                else{
                    toast.error(error.response.data.message);
                }
                reject(error);
            })
        });
    }

    const getStates = (data) => {
        myAxios.post('order-address/get-state',data,{headers: headers()}).then((res)=>{
            setStates(res.data.data)
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK'){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        })
       
    }

    const searchImportLabels = (data = null) => {
        setIsLoading(true)
        myAxios.post('label/search-import-label',data,{headers: headers()}).then((res)=>{
            setImportedLabel(res.data)
            setIsLoading(false)
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK' ){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        })
    }

    const getImportLabelsCount = () => {
        myAxios.post('/label/not-printed-label-count',null,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                setUnprintLabelCount(res.data.data);
            }
        }).catch((error)=>{
           // Silently handle error
        })
    }

    const getPickupPendingCount = () => {
        myAxios.post('/pickup-order/pending-count',null,{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                setPickupPendingCount(res.data.data);
            }
        }).catch((error)=>{
           // Silently handle error
        })
    }

    const getOrderPendingCount = () => {
        myAxios.get('/order/order-count/',{headers: headers()}).then((res)=>{
            if(res.data.status === true){
                setOrderPendingCount(res.data.data.pendingShipCount);
            }
        }).catch((error)=>{
          // Silently handle error
        })
    }

    const preAndBackOrderSearch = (data=null) => {
        setIsLoading(true)
        myAxios.post('pre-order/search',data,{headers: headers()}).then((res)=>{
            setorderSearchData(res.data);
            setOrderListingType(res.data.data.preOrders);
            setorderSearchArray(res.data.data.rows);
            setIsLoading(false);
        }).catch((error)=>{
            if(error.code ==='ERR_NETWORK' ){
                toast.error(process.env.REACT_APP_COMMON_ERROR);
            }
            else{
                toast.error(error.response.data.message);
            }
        });
        
    };

    const preAndBackOrderObject = {
        [constants.ORDER_BACK_ORDER] : getLabel('LBL_Back_orders'),
        [constants.ORDER_PRE_ORDER] : getLabel('LBL_Pre_orders'),
    }
    
    return (
        <CommonContext.Provider 
            value={{
                orderSearchData,
                orderSearch,
                orderSearchArray,
                merchants,
                getSeller,
                isLoading,
                updateLocation,
                constants,
                getPackage,
                packageData,
                setPackageData,
                getShippingCompanies,
                shippingCompanies,
                getRates,
                shipmentRates,
                setShipmentRates,
                createShipment,
                allowToSearchPackageArray,
                shippedStatusArray,
                shippingMethodObject,
                shippingCompanyObject,
                unshippedStatusArray,
                searchShops,
                shopData,
                channelObject,
                getLabel,
                fetchLabels,
                fetchLabelSetting,
                settingData,
                dayObject,
                vacationMode,
                updateSetting,
                updateOrderProductLocation,
                canparShipmentSettingData,
                canparShipmentSettingSearch,
                saveShipmentSetting,
                filterData, 
                setFilterData, 
                listingFilter, 
                setListingFilter, 
                states, 
                setStates, 
                getStates,
                pickupOrderSearch,
                pickupOrderSearchData,
                pickupOrderSearchArray, 
                allowToPrepareOrderArray, 
                allowToPickedUpOrderArray, 
                shippingDestination, 
                orderAddressInfo, 
                addressSearch, 
                receivedObject, 
                importedLabelData, 
                searchImportLabels,
                getImportLabelsCount, 
                unprintLabelCount,
                getPickupPendingCount, 
                pickupPendingCount, 
                getOrderPendingCount, 
                orderPendingCount,
                printLable, 
                setPrintLable, 
                printLabelOrderId, 
                setPrintLabelOrderId,
                preAndBackOrderSearch, 
                orderListingType, 
                preAndBackOrderObject
            }}>
            {props.children}
        </CommonContext.Provider>
    )
}

export default AppStates;