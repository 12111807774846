import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
export default function Loader(props) {
  return (
    <>
        {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index}>
                {Array.from({ length: props.columns }).map((_, index) => (
                    <td key={index}><Skeleton height={40}/></td>
                ))}
            </tr>
        ))}
    </>
  )
}
