import React from 'react'

export default function PrintLabels(props) {
    const {data} = props
  return (
        <>
            {data.map((imageUrl, innerIndex) => (
                <div className='print-label-item' key={innerIndex}>
                    <img src={imageUrl} alt={`Img ${innerIndex}`} />
                </div>
            ))}
        </>
  )
}
