import React from 'react'
import Modal from 'react-bootstrap/Modal';
import {addressFormatter} from '../../../../helpers/commonHelpers'
export default function Location(props) {
    const {handleSelectLocation, locations,getLabel} = props
  return (
    <>
       <Modal.Header closeButton >
            <Modal.Title >{getLabel('LBL_Select_which_location_you\'d_like_to_ship_from.')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {locations.map((location)=>( 
                <div key={location.loc_id}>
                    <input type="radio" value={location.loc_id} name="location_id"  onChange={(e)=>handleSelectLocation(e.target.value)} />
                    <span>{location.loc_name}</span>
                    <p className='loc_address'>{addressFormatter(location)}</p>
                </div>
            ))}
        </Modal.Body>
    </>
  )
}
