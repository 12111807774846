import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import LeftArrow from '../../../assets/images/leftArrow.svg'
import { viewPreOrder } from '../../../services/orders/index'
import { toast } from 'react-toastify';
import CommonContext from '../../../context/CommonContext'
import { getFilters } from '../../../services/orders/index'
import { displayMoneyFormat, addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers'
import History from './History';
export default function PreOrderView() {
    const { id } = useParams();
    const [data, setData] = useState({})
    const { constants, getLabel, setListingFilter, filterData, shippingMethodObject } = useContext(CommonContext);
    const [status, setStatus] = useState([]);
    const [uniqueStatuses, setUniqueStatuses] = useState([]);
    const [loadHistory, setLoadHistory] = useState(false);
    let subtotalAmount = 0;
    let totalShipping = 0;
    let totalTax = 0;
    useEffect(() => {
        getOrderDetails()
        getOrderStatus()
        // eslint-disable-next-line
    }, [])

    const getOrderDetails = async () => {
        addProcessingLoader();
        try {
            const res = await viewPreOrder(id);
            setData(res.data)
            const orderStatuses = res.data.products.map((product) => (product.op_is_ship_completed === constants.ORDER_SHIPMENT_IS_CANCELLED ? constants.ORDER_CANCELLED : product.op_status_id));
            const uniqueStatuses = [...new Set(orderStatuses)];
            setUniqueStatuses(uniqueStatuses);
        } catch (error) {
            toast.error(error);
            window.history.back();
        }
        removeProcessingLoader();
    }

    const getOrderStatus = async () => {
        try {
            const data = await getFilters();
            setStatus(data.orderStatus);
        } catch (error) {
            setStatus([]);
        }
    }
    const backPage = () => {
        if (Object.keys(filterData).length > 0) {
            setListingFilter(true);
        }
        window.history.back();
    }

    return (
        <>
            {Object.keys(data).length <= 0 ? '' :
                <>
                    <div id="body" className='no-print'>
                        <div className="page space">
                            <div className="container">
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <div className='orderIdCss no-print'>
                                            <div onClick={backPage} className='no-print back-btn'>
                                                <img src={LeftArrow} alt="left arrow" className='no-print' />
                                            </div>
                                            <p>
                                                {getLabel('LBL_Order_ID:')} <img src={`/images/channels/${data.order_channel}.svg`} alt="channel-img" className="channelImgCss" /><span>{`${data.order_id} ${data.order_channel_oid ? '(' + data.order_channel_oid + ')' : ''}`}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-9'>
                                        <div className="cards mb-5">
                                            <div className="orderInfo">
                                                <div className="topStatus">
                                                    <ul>
                                                        {uniqueStatuses.map((statusId) => {
                                                            return <li key={statusId}><img src={`/images/status/${statusId}.svg`} className="statusImg" alt={statusId} /> {status.map((Status) => (Status.orderstatus_id === statusId ? Status.status : null))}</li>
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className='table-responsive'>
                                                    <table className="table detail_page">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th width="25%">{getLabel('LBL_Product')}</th>
                                                                <th>{getLabel('LBL_SKU')}</th>
                                                                <th>{getLabel('LBL_Qty')}</th>
                                                                <th>{getLabel('LBL_Unit_Price')}</th>
                                                                <th>{getLabel('LBL_Subtotal')}</th>
                                                                <th>{getLabel('LBL_Tax')}</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.products.map((product, index) => {
                                                                subtotalAmount += product.op_qty * product.op_unit_price;
                                                                totalShipping += product.op_actual_shipping_charges;
                                                                totalTax += parseFloat(product.op_total_tax);
                                                                return <React.Fragment key={index}>
                                                                    <tr className='product_row'>
                                                                        <td>
                                                                            <div className='item__pic'>
                                                                                <img src={product.images ? process.env.REACT_APP_IMAGE_BASE_URL + product.images[0].afile_physical_path : '/images/no_image.jpg'} alt="productImage" />
                                                                            </div>
                                                                        </td>
                                                                        <td className='item__description'>
                                                                            <div className='product_name'>{`${product.oProLang && product.oProLang.op_brand_name} ${product.oProLang && product.oProLang.op_product_name}`}</div>
                                                                            <div>{product.op_selprod_compatibility_text && product.op_selprod_compatibility_text}</div>
                                                                            <span className='variant_css'>{product.op_selprod_code && product.op_selprod_code}</span>
                                                                        </td>
                                                                        <td>{product.op_selprod_sku}</td>
                                                                        <td>
                                                                            <div className={`vertical-space ${product.op_refund_qty ? 'line-through' : ''}`}>{product.op_qty}</div>
                                                                            {product.op_refund_qty ? <div className="vertical-space refund-qty"><img src="/images/refund.svg" alt="refund" className='refund-img' />-{product.op_refund_qty}</div> : ''}
                                                                        </td>
                                                                        <td>{displayMoneyFormat(product.op_unit_price)}</td>
                                                                        <td>{displayMoneyFormat(product.op_qty * product.op_unit_price)}</td>
                                                                        <td>{displayMoneyFormat(product.op_total_tax)}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr className="package_detail">
                                                                        <td colSpan={7} className={`${product.op_refund_qty ? 'statusMsgSpace' : ''}`}></td>
                                                                        {product.op_refund_qty
                                                                            ?
                                                                            <td>
                                                                                {product.op_refund_qty
                                                                                    ?
                                                                                    <span className={`statusMsg`}>
                                                                                        <img src={`/images/warning.svg`} alt='warning' />
                                                                                        {`${product.op_refund_qty} x ${product.op_selprod_sku} was refunded. Please ship out ${product.op_qty - product.op_refund_qty}`}
                                                                                    </span>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </td>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </tr>
                                                                </React.Fragment>
                                                            })}

                                                            <tr className="subtotal price">
                                                                <td colSpan={6}></td>
                                                                <td>{getLabel('LBL_Subtotal')}</td>
                                                                <td >{displayMoneyFormat(subtotalAmount)}</td>
                                                            </tr>
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td> {getLabel('LBL_Shipping')}</td>
                                                                <td >{displayMoneyFormat(totalShipping)}</td>
                                                            </tr>

                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td>{getLabel('LBL_Tax')}</td>
                                                                <td>{displayMoneyFormat(totalTax)}</td>
                                                            </tr>
                                                            <tr className="price">
                                                                <td colSpan={6}></td>
                                                                <td> {getLabel('LBL_Total')}</td>
                                                                <td>{displayMoneyFormat(subtotalAmount + totalShipping + totalTax)} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row no-print'>
                                            <div className='col-md-12'>
                                                <div className="cards">
                                                    <div className="orderInfo">
                                                        <div className='auditHistory'>
                                                            <History id={id} constants={constants} shippingMethodObject={shippingMethodObject} getLabel={getLabel} loadData={loadHistory} setLoadData={setLoadHistory} isPreOrder={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="cards">
                                            <div className="orderInfo">
                                                <div className='customer_section_css'>
                                                    <div className='right_heading'>
                                                        {getLabel('LBL_Customer')}
                                                    </div>
                                                    <span className='user_name_css'>{`${data.buyer.obuyer_fname ? data.buyer.obuyer_fname : ''} ${data.buyer.obuyer_lname ? data.buyer.obuyer_lname : ''}`}</span>
                                                </div>
                                                <hr />
                                                <div className='shipping_section_css'>
                                                    <div className='shipping-side-info'>
                                                        <div className='right_heading'>
                                                            {getLabel('LBL_Shipping_address')}
                                                        </div>
                                                    </div>
                                                    <span>
                                                        {data.address.oua_name ?
                                                            (
                                                                <>
                                                                    {data.address.oua_name} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_company_name ?
                                                            (
                                                                <>
                                                                    {data.address.oua_company_name} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_address1 ?
                                                            (
                                                                <>
                                                                    {data.address.oua_address1} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_address2 ?
                                                            (
                                                                <>
                                                                    {data.address.oua_address2} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_city ?
                                                            (
                                                                <>
                                                                    {data.address.oua_city},&nbsp;
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_state ?
                                                            (
                                                                <>
                                                                    {data.address.oua_state}&nbsp;
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_zip ?
                                                            (
                                                                <>
                                                                    {data.address.oua_zip} <br />
                                                                </>
                                                            ) : ''
                                                        }

                                                        {data.address.oua_country ?
                                                            (
                                                                <>
                                                                    {data.address.oua_country} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                        {data.address.oua_phone ?
                                                            (
                                                                <>
                                                                    {data.address.oua_phone} <br />
                                                                </>
                                                            ) : ''
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
