import React,{ useContext, useEffect, useState } from 'react'
import SearchForm from './SearchForm'
import Listing from "./Listing"
import CommonContext from '../../../context/CommonContext'
export default function Index() {
    const {searchShops,getLabel} = useContext(CommonContext);
    const initialFilters = {
        page: 1,
        keyword: '',
        sortOrder:[]
    };

    const [filters, setFilters] = useState(initialFilters);
    useEffect(()=>{
        goToSearchPage()
        // eslint-disable-next-line
    },[])

    const goToSearchPage = (type='page',value = 1,resetPage = false) => {
        const updatedFilters = {
            ...filters,
            [type]: value,
            page: resetPage ? 1 : value
        };
        setFilters(updatedFilters);
        searchShops(updatedFilters);
    }
  return (
      <>
          <div id="body">
              <div className="page">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-12 space">
                              <div className="page__title">
                                  <div className="row">
                                      <div className="col--first col-md-12">
                                          <h5>{getLabel('LBL_Shops')}</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className='listingContent'>
                                  <SearchForm goToSearchPage={goToSearchPage} getLabel={getLabel} />

                                  <section className="section">
                                      <div className="sectionbody">
                                          <div className="tablewrap">
                                              <div id="shopListing">
                                                  <Listing goToSearchPage={goToSearchPage} />
                                              </div>
                                          </div>
                                      </div>
                                  </section>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </> 
  )
}
